/* eslint-disable no-shadow */
const namespaced = true;

export const state = () => ({
  flags: {},
  flagsByLayer: null,
});

export const getters = {
  activeFlags: ({ flags }) =>
    Object.entries(flags)
      // eslint-disable-next-line no-unused-vars
      .filter(([_, val]) => val === true)
      .map(([key]) => key),
  isFeatureEnabled: (state) => (flagName) =>
    flagName in state.flags && state.flags[flagName] === true,
};

export default {
  getters,
  namespaced,
  state,
};

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import ThemedButton from '@/components/base/ThemedButton.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useCallback } from '@/composables/useCallback';
import { useCart } from '@/composables/useCart';
import { useCheckout } from '@/composables/useCheckout';
import { useNotifications } from '@/stores/notifications';
import { NutsRedirect } from '@/utils/exceptions';

const props = defineProps<{ email?: string }>();

const store = useStore();

const { setCustomerEmail } = useCart(store);
const { determineEntryLocation, prepareCartForCheckout } = useCheckout(store);
const { navigateTo, route } = useRouteChange(useRouter());
const { addNotifications } = useNotifications();

const continueAsGuest = useCallback(async () => {
  try {
    if (props.email) await setCustomerEmail(props.email);
    await prepareCartForCheckout(props.email);
    const entryLocation = determineEntryLocation(true);

    return navigateTo(entryLocation);
  } catch (error) {
    if (error instanceof NutsRedirect) {
      if (route?.path !== error.url) {
        await navigateTo(error.url);
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      useCart(store).loadCart(true);
      const { errors } = error;
      return addNotifications({ errors });
    }
    console.error(error);
    return undefined;
  }
});
</script>

<template>
  <ThemedButton
    class="h-11 sm:h-12"
    fullWidth
    :isLoading="continueAsGuest.isPending"
    theme="white"
    type="button"
    @click="continueAsGuest.execute"
    data-test="continue-as-guest-button"
  >
    Continue as Guest
  </ThemedButton>
</template>

<script setup lang="ts">
import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';
import {
  breakpointsTailwind,
  useBreakpoints,
  useElementBounding,
  useIntersectionObserver,
} from '@vueuse/core';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { RouteLocationNormalized, useRoute, useRouter } from 'vue-router';
import { Store, useStore } from 'vuex';

import AddCircularIcon from '@/components/base/assets/AddCircularIcon.vue';
import CloseIcon from '@/components/base/assets/CloseIcon.vue';
import RemoveCircularIcon from '@/components/base/assets/RemoveCircularIcon.vue';
import SearchIcon from '@/components/base/assets/SearchIcon.vue';
import Collapse from '@/components/base/layout/Collapse.vue';
import Container from '@/components/base/layout/Container.vue';
import TheOverlay from '@/components/base/layout/TheOverlay.vue';
import RouteLink from '@/components/base/RouteLink.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import CartAddedModal from '@/components/cart/CartAddedModal.vue';
import CmsContent from '@/components/cms/CmsContent.vue';
import ActionItemsButton from '@/components/layout/header/ActionItemsButton.vue';
import ActionItemsText from '@/components/layout/header/ActionItemsText.vue';
import AdminCartLookupForm from '@/components/layout/header/AdminCartLookupForm.vue';
import HiddenLink from '@/components/layout/header/HiddenLink.vue';
import MainNav from '@/components/layout/header/MainNav.vue';
import MobileMenuNav from '@/components/layout/header/MobileMenuNav.vue';
import SearchBar from '@/components/layout/header/search/SearchBar.vue';
import SearchModal from '@/components/layout/header/search/SearchModal.vue';
import StaticSearchSuggestions from '@/components/layout/header/search/StaticSearchSuggestions.vue';
import SearchContainer from '@/components/layout/header/SearchContainer.vue';
import SecondaryNav from '@/components/layout/header/SecondaryNav.vue';
import TopShelf from '@/components/layout/header/TopShelf.vue';
import LoginModal from '@/components/login/LoginModal.vue';
import DetailShippingContent from '@/components/shipping/DetailShippingContent.vue';
import { useDyChooseResults } from '@/composables/dynamic-yield/experiences';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useAuth } from '@/composables/useAuth';
import { useCart } from '@/composables/useCart';
import { useCms } from '@/composables/useCms';
import { useDelivery } from '@/composables/useDelivery';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { useLoginModal } from '@/composables/useLoginModal';
import { useState } from '@/composables/useState';
import { staticAccountLinks } from '@/data/nav/accountLinks';
import { staticMegaMenu } from '@/data/nav/megaMenu';
import { staticMobileMenu } from '@/data/nav/mobileNav';
import { SecondaryNavLink } from '@/data/nav/secondaryNav';
import { JsonChoice, JsonVariation } from '@/lib/personalization/dynamicYield';
import { useCriticalData } from '@/stores/criticalData';
import { DataModel } from '@/utils/cms';
import { MegaMenu, MobileNav, SiteNavigation, SiteNavigationCategories } from '@/utils/navMenu';
import { adaptMultipleQueryValues } from '@/utils/search/createAlgoliaRouter';
import { waitABit } from '@/utils/waitABit';

const freeShippingDots = nutshell['img/free-shipping-dots.svg'];

const route = useRoute();
const store = useStore();

const { permissions } = useAuth(store);
const {
  addedLineItem,
  addedAddOns,
  isCartAddedOpen,
  loadCart,
  loadLineItemExpansions,
  preCheckoutLineItems: lineItems,
  removeGreetingCards,
} = useCart(store);
const { content: promotionBanner } = useCms('promotional-messaging-bar', 'setup');
const { content: sitewideBanner } = useCms('sitewide-banner', 'setup');
const { content: desktopMenu } = useCms<DataModel<MegaMenu>>('mega-menu', 'setup');
const { content: mobileMenu } = useCms<DataModel<MobileNav>>('mobile-menu', 'setup');
const { freeShippingThreshold } = useDelivery(store);
const { flags } = useFeatureFlags(store);
const { handleClose, state: signIn } = useLoginModal();
const { navigateTo } = useRouteChange(useRouter());

const isSearchPage = computed(() => route?.name === 'Search');
const { query } = useState('synchronizedSearchBar', () => ({ query: '' }));
if (isSearchPage.value) {
  query.value = route.query.query ? adaptMultipleQueryValues(route.query) : '';
}

const showMobileMenu = ref(false);
const showMobileSearch = ref(false);
const showShippingDetails = ref(false);

const desktopSiteNavigation = computed<SiteNavigation>(() => ({
  accountLinks: staticAccountLinks,
  categories: SiteNavigationCategories.fromMegaMenu(
    desktopMenu.value.result?.data ?? staticMegaMenu,
  ),
}));

const breakpoints = useBreakpoints({ ...breakpointsTailwind, 'ws-md': 892 });
const isMobile = breakpoints.smaller('lg');
const mobileHeader = ref<HTMLElement>();
const { height: mobileHeaderHeight } = useElementBounding(mobileHeader);
const mobileMenuOffset = computed(() => {
  if (!mobileHeaderHeight.value) return '5.75rem';
  return `${mobileHeaderHeight.value}px`;
});
const mobileSiteNavigation = computed<SiteNavigation>(() => ({
  accountLinks: staticAccountLinks,
  categories: SiteNavigationCategories.fromMobileNav(
    mobileMenu.value.result?.data ?? staticMobileMenu,
  ),
}));

const resetState = () => {
  showShippingDetails.value = false;
  showMobileMenu.value = false;
  showMobileSearch.value = false;
};

const onCartClick = () => {
  resetState();
  navigateTo('/cart');
};

const onMenuClick = () => {
  const current = showMobileMenu.value;
  resetState();
  showMobileMenu.value = !current;
};

const displaySearchSuggestions = ref(false);
const onSearchClick = async () => {
  if (isMobile.value && isSearchPage.value) {
    const selector = breakpoints.isGreaterOrEqual('ws-md')
      ? '#ais-search-box-desktop input'
      : '#search-input-mobile input';
    const inputField = document.querySelector(selector);
    if (inputField instanceof HTMLInputElement) {
      inputField.focus();
      inputField.selectionStart = query.value.length;
      inputField.selectionEnd = query.value.length;
      return;
    }
  }

  if (flags.headerSearchChangesV1 && isMobile.value && !isSearchPage.value) {
    displaySearchSuggestions.value = true;
  } else {
    const current = showMobileSearch.value;
    resetState();
    showMobileSearch.value = !current;
  }
};

const onShippingDetailsClick = async () => {
  const current = showShippingDetails.value;
  resetState();
  showShippingDetails.value = !current;

  if (isMobile.value && showShippingDetails.value) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

type SecondaryNavVariation = JsonVariation<{ items: SecondaryNavLink[] }>;
interface CriticalData {
  choicesByName?: Record<string, JsonChoice<SecondaryNavVariation> | undefined>;
}
const critical = useCriticalData<CriticalData>('BaseHeader');
const secondaryNavSelector = '[NAV] Secondary Nav';
const { choicesByName, loadExperiences } = useDyChooseResults<JsonChoice<SecondaryNavVariation>>(
  secondaryNavSelector,
  route,
  {
    preloadedChoices: critical.data.choicesByName,
  },
);
const dynamicSecondaryNavLinks = computed(
  () => choicesByName[secondaryNavSelector]?.variations[0]?.payload.data.items,
);

onMounted(() => {
  waitABit().then(async () => {
    await loadCart();
    await Promise.all([loadLineItemExpansions(), removeGreetingCards()]);
  });

  watch(isMobile, (val) => {
    displaySearchSuggestions.value = false;

    if (!val) {
      showMobileMenu.value = false;
      showMobileSearch.value = false;
    }
  });

  loadExperiences({ skipLoadedExperiences: true });
});

watch(
  () => route?.path,
  () => {
    showMobileMenu.value = false;
    showMobileSearch.value = false;
  },
);

defineOptions({
  async criticalData(forwardedStore: Store<any>, to: RouteLocationNormalized) {
    const promises: Promise<any>[] = [
      useCms('sitewide-banner', 'criticalData').loadCmsContent(to.path, to.query),
      useCms('promotional-messaging-bar', 'criticalData').loadCmsContent(to.path, to.query),
    ];
    if (typeof window === 'undefined') {
      const criticalDY =
        useDyChooseResults<JsonChoice<SecondaryNavVariation>>('[NAV] Secondary Nav');
      promises.push(
        useCms('mega-menu', 'criticalData').loadCmsContent(to.path, to.query),
        useCms('mobile-menu', 'criticalData').loadCmsContent(to.path, to.query),
        criticalDY.loadExperiences().then(() => {
          useCriticalData<CriticalData>('BaseHeader').data.choicesByName = criticalDY.choicesByName;
        }),
      );
    }

    await Promise.all(promises);
  },
});

onBeforeUnmount(() => {
  handleClose();
  isCartAddedOpen.value = false;
});

const getDataPromo = (name: string) => ({
  'data-promo': '1',
  'data-promo-creative': 'Header Links',
  'data-promo-name': name,
});

const cmsContent = ref(null);
const hasShadow = ref(false);

useIntersectionObserver(
  cmsContent,
  ([{ isIntersecting, intersectionRatio }]) => {
    hasShadow.value = !isIntersecting || intersectionRatio < 1;
  },
  {
    threshold: [1],
  },
);
</script>

<template>
  <div id="appHeader" class="sticky top-0 z-20 bg-white lg:relative lg:shadow lg:mt-10">
    <HiddenLink to="#skiptocontent">Skip to main content</HiddenLink>
    <HiddenLink to="https://nuts.com/accessibility.html">Accessibility policy page</HiddenLink>

    <AdminCartLookupForm v-if="permissions.checkOutAsCustomer" />
    <CmsContent
      class="hidden lg:visible lg:block"
      :content="promotionBanner"
      model="promotional-messaging-bar"
      ref="cmsContent"
    />
    <TopShelf class="hidden lg:visible lg:block" :class="{ shadow: hasShadow }" />
    <Container class="relative lg:pb-0" noMobilePadding data-test="masthead">
      <div
        class="relative left-0 right-0 z-30 flex flex-col grid-cols-12 bg-white shadow lg:shadow-none lg:static lg:h-24 lg:gap-4 lg:py-4 lg:grid grow-0"
        :class="{ 'lg:pt-3': flags.layoutRebrandingChangesV1 }"
        data-test="mobile-header"
        ref="mobileHeader"
      >
        <CmsContent
          class="lg:hidden"
          :content="promotionBanner"
          model="promotional-messaging-bar"
        />
        <div
          class="flex justify-between order-2 col-span-3 px-1 py-2 lg:p-0 gap-x-3 lg:order-none"
          :class="{ 'xl:p-1': flags.layoutRebrandingChangesV1 }"
        >
          <div class="flex lg:hidden">
            <ActionItemsButton @click="onMenuClick">
              <div
                class="flex flex-col items-center justify-center gap-1"
                data-test="hamburger-menu"
              >
                <img
                  v-if="!showMobileMenu"
                  alt=""
                  class="object-contain w-6 h-6"
                  src="@/assets/menu-burger.svg"
                />
                <CloseIcon
                  v-else
                  data-promo="1"
                  data-promo-creative="Mobile Menu Nav Links"
                  data-promo-name="Close"
                  data-test="close-hamburger-menu"
                  :size="24"
                />
                <ActionItemsText>{{ showMobileMenu ? 'Close' : 'Shop' }}</ActionItemsText>
              </div>
            </ActionItemsButton>
            <div class="w-12 lg:hidden" />
          </div>
          <RouteLink
            v-bind="getDataPromo('Site logo')"
            class="inline-flex self-center gap-x-2"
            to="/"
          >
            <picture v-if="flags.layoutRebrandingChangesV1" class="object-contain">
              <source
                media="(min-width: 1280px)"
                alt="Nuts.com"
                height="80"
                data-test="nuts-logo"
                itemprop="logo"
                srcset="@/assets/layout-rebrand-v1/nuts-logo-desktop.svg"
              />
              <source
                media="(min-width: 1024px)"
                alt="Nuts.com"
                height="64"
                data-test="nuts-logo"
                itemprop="logo"
                srcset="@/assets/layout-rebrand-v1/nuts-logo-desktop.svg"
              />
              <img
                alt="Nuts.com"
                data-test="nuts-logo-mobile"
                height="48"
                itemprop="logo"
                src="@/assets/layout-rebrand-v1/nuts-logo.svg"
              />
            </picture>
            <template v-else>
              <span class="shrink">
                <img
                  alt="Nuts.com"
                  data-test="nuts-logo"
                  class="hidden object-contain h-16 lg:visible lg:block"
                  itemprop="logo"
                  src="@/assets/nuts-logo-text-only.svg"
                />
                <img
                  alt="Nuts.com"
                  data-test="nuts-logo-mobile"
                  class="visible block object-contain h-10 lg:hidden"
                  itemprop="logo"
                  src="@/assets/nuts-logo-kenny-and-jeff.svg"
                />
              </span>
              <span class="hidden shrink lg:visible lg:block" data-test="family-logo">
                <img alt="" class="object-contain h-16" src="@/assets/nuts-family.png" />
              </span>
            </template>
          </RouteLink>
          <div class="flex lg:hidden">
            <ActionItemsButton
              v-bind="getDataPromo('Search Icon')"
              data-test="search"
              @click="onSearchClick"
            >
              <div class="flex flex-col items-center justify-center gap-1">
                <SearchIcon :size="24" />
                <ActionItemsText>Search</ActionItemsText>
              </div>
            </ActionItemsButton>
            <ActionItemsButton v-bind="getDataPromo('Cart')" data-test="cart" @click="onCartClick">
              <div class="flex flex-col items-center justify-center gap-1 lg:hidden">
                <div class="relative flex">
                  <img alt="" class="object-contain w-6 h-6" src="@/assets/shopping-cart.svg" />
                  <div
                    v-show="lineItems.length"
                    aria-hidden="true"
                    class="absolute flex items-center justify-center w-4 h-4 rounded-full -top-1 -right-1 shrink-0 bg-nuts-amber-400"
                  >
                    <span class="text-xs font-semibold leading-tight text-center text-black">
                      {{ lineItems.length }}
                    </span>
                  </div>
                </div>
                <ActionItemsText>
                  <span class="sr-only">{{ lineItems.length }} items in your</span>Cart
                </ActionItemsText>
              </div>
            </ActionItemsButton>
          </div>
        </div>
        <div class="self-center hidden col-span-6 lg:mx-10 lg:visible lg:block">
          <SearchBar
            v-model:isOpen="displaySearchSuggestions"
            v-model:query="query"
            v-bind="getDataPromo('Search bar')"
          />
          <StaticSearchSuggestions class="mt-1 text-center" />
        </div>
        <div
          class="flex flex-col items-center justify-center order-1 col-span-3 lg:items-end lg:order-none lg:bg-white bg-nuts-amber-400"
        >
          <img
            v-if="flags.layoutRebrandingChangesV1"
            alt=""
            class="hidden object-contain lg:visible lg:block"
            data-test="free-shipping-dots"
            height="14"
            src="@/assets/layout-rebrand-v1/free-shipping-dots.svg"
            width="85"
          />
          <img
            v-else
            alt=""
            class="hidden object-contain h-5 lg:visible lg:block"
            :src="freeShippingDots"
            data-test="free-shipping-dots"
          />
          <UnstyledButton
            v-bind="getDataPromo('Free Shipping message banner')"
            data-test="free-shipping-banner"
            aria-controls="free-shipping-details"
            :aria-expanded="showShippingDetails"
            aria-label="shipping details"
            class="inline-flex items-center gap-x-1"
            @click="onShippingDetailsClick"
          >
            <SmallBodyText
              class="py-1 font-semibold leading-5 lg:font-normal xl:hidden whitespace-nowrap"
            >
              FREE shipping on orders over ${{ dollars(freeShippingThreshold) }}!
            </SmallBodyText>
            <BaseBodyText class="hidden xl:visible xl:block">
              FREE shipping on orders over ${{ dollars(freeShippingThreshold) }}!
            </BaseBodyText>
            <RemoveCircularIcon v-if="showShippingDetails" :size="16" />
            <AddCircularIcon v-else :size="16" />
          </UnstyledButton>
        </div>
        <transition
          name="bounce"
          enter-active-class="bounceInDown"
          leave-active-class="bounceOutUp"
        >
          <SearchContainer
            v-if="showMobileSearch"
            autofocus
            class="absolute left-0 right-0 z-10 order-3 px-3 pb-2 bg-white shadow animated lg:hidden mobile-header-offset"
          />
        </transition>
      </div>
    </Container>
    <hr
      v-if="!flags.layoutRebrandingChangesV1"
      class="hidden h-px m-0 opacity-50 lg:visible lg:block bg-neutral-300"
    />
    <Collapse :expanded="showShippingDetails">
      <div class="bg-nuts-amber-200" id="free-shipping-details">
        <Container>
          <DetailShippingContent
            class="flex items-center justify-between py-2 text-sm text-center"
            :freeShippingThreshold="freeShippingThreshold"
          />
        </Container>
      </div>
    </Collapse>
    <template v-if="flags.layoutRebrandingChangesV1">
      <SecondaryNav
        class="hidden lg:visible lg:block"
        :dynamicNavLinks="dynamicSecondaryNavLinks"
      />
      <MainNav class="hidden lg:visible lg:block" :siteNavigation="desktopSiteNavigation" />
    </template>
    <template v-else>
      <MainNav class="hidden lg:visible lg:block" :siteNavigation="desktopSiteNavigation" />
      <SecondaryNav
        class="hidden lg:visible lg:block"
        :dynamicNavLinks="dynamicSecondaryNavLinks"
      />
    </template>
    <transition name="slide-from-left">
      <MobileMenuNav
        v-if="showMobileMenu"
        class="z-20 mobile-header-offset lg:hidden"
        :siteNavigation="mobileSiteNavigation"
        @handle-close="showMobileMenu = false"
      />
    </transition>
  </div>
  <Teleport v-if="signIn.isOpen" to="body">
    <LoginModal
      :callback="signIn.callback"
      :destination="signIn.destination"
      :isCheckout="signIn.isCheckout"
      :initialStep="signIn.initialStep"
      :selectBusiness="signIn.selectBusiness"
      :isOpen="signIn.isOpen"
      @handle-close="handleClose"
    />
  </Teleport>
  <Teleport v-if="isCartAddedOpen && addedLineItem" to="body">
    <CartAddedModal
      :addOns="addedAddOns"
      :isOpen="isCartAddedOpen"
      :lineItem="addedLineItem"
      @handle-close="isCartAddedOpen = false"
    />
  </Teleport>
  <transition v-if="flags.headerSearchChangesV1" name="fade" :duration="200">
    <SearchModal v-model:isOpen="displaySearchSuggestions" v-model:query="query" />
  </transition>
  <span id="skiptocontent" class="sr-only">Main Content</span>
  <CmsContent :content="sitewideBanner" model="sitewide-banner" />
  <TheOverlay class="z-10" />
</template>

<style lang="scss" scoped>
.mobile-header-offset {
  top: v-bind('mobileMenuOffset');
}
.slide-from-left-enter-active,
.slide-from-left-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-from-left-enter-from,
.slide-from-left-leave-to {
  @apply -translate-x-full md:-translate-x-96;
}
</style>

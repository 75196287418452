/* eslint-disable no-shadow */
import { ActionTree, MutationTree } from 'vuex';

import { RootState } from '@/store/createStore';
import { NutsAddress } from '@/utils/address';

const namespaced = true;

export const state = () => ({
  shippingAddress: {} as NutsAddress,
  email: {},
  thankYouNote: null,
  trayContents: [null, null, null, null, null],
});

export type DigitalGiftingState = ReturnType<typeof state>;

/** ********** */
// MUTATIONS //
/** ********** */
export const mutations: MutationTree<DigitalGiftingState> = {
  SET_SHIPPING_ADDRESS(state, address) {
    state.shippingAddress = address;
  },
  SET_EMAIL(state, email) {
    state.email = email;
  },
  SET_THANK_YOU_NOTE(state, note) {
    state.thankYouNote = note;
  },
  SET_TRAY_CONTENT(state, content) {
    state.trayContents = content;
  },
};

/** ******** */
// ACTIONS //
/** ******** */
export const actions: ActionTree<DigitalGiftingState, RootState> = {
  setAddressInfo({ commit }, { shippingAddress, email }) {
    commit('SET_SHIPPING_ADDRESS', shippingAddress);
    commit('SET_EMAIL', email);
  },
};

export default {
  actions,
  namespaced,
  state,
  mutations,
};

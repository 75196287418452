<script setup lang="ts">
import { useElementBounding } from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { computed, markRaw, nextTick, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import LargeMenu from '@/components/layout/header/desktop/LargeMenu.vue';
import NavHeader from '@/components/layout/header/desktop/NavHeader.vue';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { useOverlay } from '@/stores/overlay';
import { desktopAttribution, MenuByType, SiteNavigation } from '@/utils/navMenu';

const props = defineProps<{ siteNavigation: SiteNavigation }>();

const store = useStore();
const { flags } = useFeatureFlags(store);

const navList = ref<HTMLElement>();
const { height } = useElementBounding(navList);
const subMenuOffset = computed(() => `${height.value ? height.value : 0}px`);

const mainNav = computed<MenuByType[]>(() =>
  props.siteNavigation.categories.map(({ menu }) => ({ ...menu, type: 'LargeMenu' })),
);

const activeMenu = ref<MenuByType>();

let showSubMenuTimerId: ReturnType<typeof setTimeout> | undefined;

const largeMenuList = ref<HTMLElement>();
const { activate, deactivate } = useFocusTrap(largeMenuList, {
  clickOutsideDeactivates: true,
  onDeactivate: () => {
    clearTimeout(showSubMenuTimerId);
    activeMenu.value = undefined;
  },
});

const hideSubMenu = () => {
  clearTimeout(showSubMenuTimerId);
  activeMenu.value = undefined;
  deactivate();
};

const selectSubMenu = (navEntry: MenuByType, skipDelay = true) => {
  clearTimeout(showSubMenuTimerId);

  const rawNavEntry = markRaw(navEntry);

  // if going directly from one NavHeader to another, show the new menu immediately
  if (skipDelay) {
    activeMenu.value = rawNavEntry;
  } else {
    showSubMenuTimerId = setTimeout(() => {
      activeMenu.value = rawNavEntry;
    }, 300);
  }
};

const focusIntoMenu = async (navEntry: MenuByType) => {
  selectSubMenu(navEntry);
  await nextTick();
  activate();
};

const cancelButtonClick = (navEntry: MenuByType, e: Event) => {
  if (navEntry.header.url) return;
  e.preventDefault();
  e.stopPropagation();
};

const overlay = useOverlay();
watch(activeMenu, () => (activeMenu.value ? overlay.show(true) : overlay.hide()));

useRouterLinks(navList, useRouter());
</script>

<template>
  <div :class="flags.layoutRebrandingChangesV1 ? 'bg-nuts-neutral-900 ' : 'bg-white'">
    <nav aria-label="main" class="relative px-4 mx-auto" data-test="mega-menu">
      <ul class="flex justify-center" ref="navList" @mouseleave="hideSubMenu" role="menubar">
        <li v-for="navEntry in mainNav" :key="navEntry.header.url" @click="hideSubMenu" role="none">
          <a
            v-bind="desktopAttribution(navEntry)"
            aria-controls="large-menu"
            :aria-expanded="activeMenu === navEntry"
            aria-haspopup="true"
            class="block p-3 hover:no-underline focus:no-underline"
            :class="{
              'custom-underline-hover h-11': flags.layoutRebrandingChangesV1,
            }"
            data-test="mega-menu-link"
            :href="navEntry.header.url"
            role="menuitem"
            @click="cancelButtonClick(navEntry, $event)"
            @keydown.prevent.down="focusIntoMenu(navEntry)"
            @mouseover="selectSubMenu(navEntry, !!activeMenu)"
          >
            <div class="flex items-center">
              <NavHeader
                :activeState="activeMenu && (activeMenu === navEntry ? 'active' : 'suppressed')"
                class="gap-x-2.5"
                :header="navEntry.header"
              />
            </div>
          </a>
          <div ref="largeMenuList">
            <LargeMenu
              v-if="navEntry.type === 'LargeMenu'"
              v-show="activeMenu === navEntry"
              class="absolute inset-x-0 w-full pt-5 pb-8 border-b border-solid shadow-md sub-menu-offset border-nuts-neutral-200 shadow-black/10"
              :id="`${navEntry.header.text || navEntry.header.url}-large-menu`"
              :menu="navEntry"
              @close="hideSubMenu"
            />
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
.sub-menu-offset {
  top: v-bind('subMenuOffset');
}
.custom-underline-hover::after {
  content: '';
  transition: width 0.3s ease;
  @apply bg-nuts-amber-400 block h-1 w-0 mx-auto my-0;
}
.custom-underline-hover:hover::after {
  @apply w-1/2;
}
</style>

<script setup lang="ts">
import axios from 'axios';
import dayjs from 'dayjs';
import { Form } from 'vee-validate';
import { ref } from 'vue';

import { webstore } from '@/api';
import Notification from '@/components/base/Notification.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import Header5 from '@/components/base/typography/Header5.vue';
import { useCallback } from '@/composables/useCallback';
import { useForm } from '@/composables/useForm';
import { DateString } from '@/utils/dateTime';

interface Props {
  buttonText?: string;
  buttonTheme?: 'green' | 'gray' | 'red' | 'white';
  endpoint: string;
  fileUrl?: string;
  fileName?: string;
}

const props = withDefaults(defineProps<Props>(), {
  buttonText: 'Submit',
  buttonTheme: 'gray',
});

const { validatorFailed } = useForm();
const formSubmitted = ref(false);

const handleSubmitForm = useCallback(async (formValues: any) => {
  if (props.fileUrl) {
    window.open(props.fileUrl, '_blank');
  }

  const { endpoint } = props;

  if (endpoint.includes('http')) {
    const today = dayjs().format('YYYY-MM-DD') as DateString;
    const data = { ...formValues, date: today };
    const dataForRequest = new URLSearchParams(data).toString();
    await axios.post(`${endpoint}?${dataForRequest}`);
  } else {
    await webstore.post(`${endpoint}`, formValues);
  }
  formSubmitted.value = true;
});
</script>

<script lang="ts">
// eslint-disable-next-line import/first
import { BuilderComponent } from '@/utils/cms';

// eslint-disable-next-line import/prefer-default-export
export const FormWrapperRegistration: BuilderComponent = {
  name: 'Form',
  canHaveChildren: true,
  inputs: [
    {
      name: 'endpoint',
      type: 'string',
      required: true,
      helperText: 'Endpoint to post data when form is submitted',
    },
    {
      name: 'buttonText',
      type: 'string',
      defaultValue: 'Submit',
    },
    {
      name: 'buttonTheme',
      type: 'string',
      defaultValue: 'gray',
      enum: ['green', 'gray', 'red', 'white'],
    },
    {
      name: 'fileUrl',
      type: 'file',
      helperText: 'PDF file to download upon form submission',
      allowedFileTypes: ['pdf'],
    },
    { name: 'fileName', type: 'string', helperText: 'Name of the file to download' },
  ],
};
</script>

<template>
  <Form v-if="!formSubmitted" v-slot="{ meta: formMeta, values }">
    <slot />
    <Notification v-if="handleSubmitForm.error" class="my-6" theme="error">
      <div class="flex flex-col items-start">
        <div class="font-medium">
          Something happened on our end, please contact Customer Experience at 800-558-6887.
        </div>
      </div>
    </Notification>
    <ThemedButton
      class="mt-6"
      type="submit"
      @click.prevent="handleSubmitForm.execute(values)"
      :theme="buttonTheme"
      :disabled="validatorFailed(formMeta)"
      :loading="handleSubmitForm.isPending"
    >
      {{ buttonText }}
    </ThemedButton>
  </Form>
  <div v-else class="flex flex-col items-center gap-2 p-8 text-center">
    <img alt="check-circle" class="w-10" src="@/assets/check-in-white-circle.svg" />
    <Header5>Thank you for contacting us! We will reach out within 1 business&nbsp;day</Header5>
  </div>
</template>

import omitBy from 'lodash/omitBy';

import { ExperimentViewed, experimentViewed } from '@/rudder-typer';
import { gtag } from '@/utils/analytics';

type ExperimentProvider = 'Builder.io' | 'Dynamic Yield' | 'Algolia';

// eslint-disable-next-line import/prefer-default-export
export function sendExperimentViewedEvent(payload: ExperimentViewed, cms: ExperimentProvider) {
  const gtagPayload = {
    cms,
    content_id: payload.campaign_id,
    content_name: payload.campaign_name,
    experience_id: payload.experiment_id,
    experience_name: payload.experiment_name,
    test_variation_id: payload.variation_id,
    test_variation_name: payload.variation_name,
  };
  const filteredPayload = omitBy(gtagPayload, (value) => value === undefined);
  gtag('event', 'content_decision', filteredPayload);

  if (typeof window !== 'undefined') experimentViewed(payload);
}

<script setup lang="ts">
import {
  breakpointsTailwind,
  useBreakpoints,
  useElementBounding,
  useStorage,
  useVModel,
} from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import CloseIcon from '@/components/base/assets/CloseIcon.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import SearchContent from '@/components/layout/header/search/SearchContent.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { useOverlay } from '@/stores/overlay';

const props = defineProps<{
  isOpen: boolean;
  query: string;
}>();
const emit = defineEmits<{
  'update:isOpen': [boolean];
  'update:query': [string];
}>();

const router = useRouter();
const route = useRoute();
const store = useStore();

const fixedHeaderRef = ref<HTMLElement>();
const mainRef = ref<HTMLElement>();
const searchInputRef = ref<HTMLElement>();

const isOpenModel = useVModel(props, 'isOpen', emit);
const queryModel = useVModel(props, 'query', emit);

const recentSearches = useStorage<string[]>('recentSearches', []);

const isMobile = useBreakpoints(breakpointsTailwind).smaller('lg');
const { height: headerHeight } = useElementBounding(fixedHeaderRef);
const { loadDyFlags } = useFeatureFlags(store);
const { activate, deactivate } = useFocusTrap(mainRef, {
  onDeactivate: () => {
    isOpenModel.value = false;
  },
});
const overlay = useOverlay();
const { navigateTo } = useRouteChange(router);

const shouldDisplaySuggestions = computed(() => isOpenModel.value && isMobile.value);

const addRecentSearch = (entry: string) => {
  const existingSearches = recentSearches.value.filter((search) => search !== entry);
  recentSearches.value = [entry, ...existingSearches.slice(0, 4)];
};

const handleClose = () => {
  isOpenModel.value = false;
};

const handleSearch = async (search?: string) => {
  let href = '/search/instant';
  if (search) {
    addRecentSearch(search);
    href += `?query=${search}`;
  }
  // gtag('event', 'Header Search Submission', {
  //   header_type: 'Redesign V1',
  // });
  await loadDyFlags('[A/B Test] Search Page Visual Changes', ['searchPageLayoutChanges']);
  navigateTo(href);
};

const handleClearSearch = () => {
  queryModel.value = '';
  searchInputRef.value?.focus();
};

watch(() => route?.path, handleClose);

onMounted(() => {
  watch(shouldDisplaySuggestions, async (open) => {
    if (open) {
      queryModel.value = '';
      window.Kustomer?.stop();
      overlay.show();
      await nextTick();
      activate();
    } else {
      window.Kustomer?.start();
      overlay.hide();
      deactivate();
    }
  });
});

onBeforeUnmount(() => {
  window.Kustomer?.start();
  overlay.hide();
  deactivate();
});

useRouterLinks(mainRef, router);
</script>

<template>
  <div
    v-show="shouldDisplaySuggestions"
    aria-label="search"
    aria-modal="true"
    class="fixed inset-0 z-50 flex flex-col overflow-y-auto bg-white"
    data-test="header-search-modal"
    role="dialog"
    ref="mainRef"
  >
    <div
      class="fixed inset-x-0 top-0 z-50 flex gap-3 py-2 pl-3 pr-2 bg-white border-b border-solid border-neutral-300"
      ref="fixedHeaderRef"
    >
      <form
        class="relative w-full"
        data-test="header-search-form"
        ref="searchFormRef"
        role="search"
        @submit.prevent="handleSearch(queryModel)"
      >
        <input
          v-model="queryModel"
          aria-label="Search"
          autocapitalize="off"
          autocomplete="off"
          autocorrect="off"
          class="w-full h-10 px-4 text-base border border-solid rounded-full bg-nuts-stone-100 border-neutral-300 focus:border-black focus:outline-none focus:border-2"
          data-test="header-search-input"
          placeholder="Search for a product"
          ref="searchInputRef"
          spellcheck="false"
          type="search"
        />
        <div class="absolute flex items-center gap-1 right-1 top-1 bottom-1">
          <transition name="fade" :duration="100">
            <UnstyledButton
              v-if="query.length"
              aria-label="Clear search"
              class="flex p-1 rounded-full hover:bg-white"
              data-test="clear-search-query-button"
              @click="handleClearSearch"
            >
              <CloseIcon :size="16" />
            </UnstyledButton>
          </transition>
          <UnstyledButton
            class="h-full px-4 rounded-full bg-nuts-amber-400 hover:bg-amber-400"
            data-test="header-search-button"
            type="submit"
          >
            <SmallBodyText class="font-semibold leading-3 text-nuts-neutral-950">
              Search
            </SmallBodyText>
          </UnstyledButton>
        </div>
      </form>
      <UnstyledButton type="button" data-test="header-cancel-button" @click="handleClose">
        <BaseBodyText>Cancel</BaseBodyText>
      </UnstyledButton>
    </div>
    <SearchContent
      v-model:recentSearches="recentSearches"
      class="absolute inset-x-0 header-offset"
      :query="queryModel"
      @search="handleSearch"
    />
  </div>
</template>

<style scoped>
.header-offset {
  top: v-bind('`${headerHeight}px`');
}
</style>

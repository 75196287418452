/* eslint-disable import/prefer-default-export */
import { MaybeElementRef, whenever } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, toValue } from 'vue';
import { Store } from 'vuex';

import { useCustomer } from '@/composables/useCustomer';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { useSession } from '@/stores/session';
import { Extole } from '@/utils/referral';

type Creatives =
  | 'account_page'
  | 'confirmation'
  | 'global_footer'
  | 'global_footer_rebrandv1'
  | 'global_header_rebrandv1'
  | 'global_header_redesignv1'
  | 'global_header'
  | 'landing_page_cta'
  | 'mobile_menu_redesignv1'
  | 'mobile_menu';

type ProgramLabel = 'refer-a-friend' | 'refer-a-business';

export const useReferralElement = (
  store: Store<any>,
  el: MaybeElementRef,
  name: Creatives,
  label: ProgramLabel = 'refer-a-friend',
) => {
  const { customer } = useCustomer(store);
  const { flags } = useFeatureFlags(store);
  const { userFirstName } = storeToRefs(useSession());

  const showReferralElement = computed(() => !flags.disableReferral);

  whenever(
    () => toValue(el),
    (element) => {
      if (flags.disableReferral) return;

      if (!(element instanceof HTMLElement)) return;
      Extole.createZone({
        name,
        element,
        data: {
          email: customer.value?.email,
          first_name: userFirstName.value,
          partner_user_id: customer.value?.id,
          required_labels: label,
        },
      });
    },
    { immediate: true },
  );

  return {
    showReferralElement,
  };
};

/* eslint-disable import/prefer-default-export */

import { Customer, DiscountCode } from '@commercetools/platform-sdk';
import { computed } from 'vue';
import { Store } from 'vuex';

import { CreditAccount, DescribeCustomerResponse, findExistingCustomer } from '@/api/customer';
import { useCart } from '@/composables/useCart';
import { useCustomer as useCustomerStore } from '@/stores/customer';
import { useSession } from '@/stores/session';
import { NutsAddress } from '@/utils/address';
import { getCookie } from '@/utils/isomorphic/cookie';
import mascots from '@/utils/mascots';

export function uuidToInteger(uuid: string) {
  return uuid
    .split('-')
    .map((part) => parseInt(part, 16))
    .reduce((total, part) => total + part, 0);
}

export function useCustomer(store: Store<any>) {
  const customerStore = useCustomerStore();
  const { loadSafcToken } = useCart(store);
  const sessionStore = useSession();

  const carrierOptions = computed(() => store.state.customerModule.carrierOptions);

  const creditAccount = computed<CreditAccount | undefined>(
    () => store.state.customerModule.creditAccount ?? undefined,
  );

  const customer = computed<Customer | undefined>(
    () => store.state.customerModule.customer ?? undefined,
  );
  const customerAddresses = computed<NutsAddress[]>(
    () => store.state.customerModule.addresses ?? [],
  );
  const customerDiscountCodes = computed<DiscountCode[]>(
    () => store.state.customerModule.customerDiscountCodes ?? [],
  );
  const customerMemberSince = computed(() => store.state.customerModule.signedUpAt);
  const customerProfileImage = computed<string | undefined>(
    // When work has been done to support profile images, check should be added here.
    () => (customer.value?.id && mascots[uuidToInteger(customer.value.id) % 17]) || undefined,
  );
  const customerRegionalCarriersAllowed = computed(
    () =>
      (carrierOptions.value?.allowBlueStreak &&
        carrierOptions.value?.allowCdl &&
        carrierOptions.value?.allowGrandHusky &&
        carrierOptions.value?.allowTforce &&
        carrierOptions.value?.allowUds) ??
      true,
  );
  const customerOntracAllowed = computed(() => carrierOptions.value?.allowOntrac ?? true);
  const customerLasershipAllowed = computed(() => carrierOptions.value?.allowLasership ?? true);

  const businessIndustry = computed(() => customerStore.businessIndustry);
  const hasBusinessAccount = computed(() => customerStore.hasBusinessAccount);
  const customerOrdersCount = computed(() => {
    const orderCount = Number(getCookie('_co', false) || '0');
    return Number.isNaN(orderCount) ? 0 : orderCount;
  });

  const offerNewsletter = computed(() => {
    const existingUnsubscribedUser = !!customer.value && !store.state.customerModule.subscribed;
    const existingUnsubscribedContact =
      !customer.value &&
      store.state.customerModule.subscribed !== null &&
      !store.state.customerModule.subscribed;
    return existingUnsubscribedUser || existingUnsubscribedContact;
  });

  const storeCredit = computed<CreditAccount | undefined>(
    () => store.state.customerModule.storeCredit ?? undefined,
  );

  const uniqueAddresses = computed<NutsAddress[]>(
    () => store.getters['customerModule/uniqueAddresses'],
  );

  function sendIdentifyEvent() {
    try {
      if (customerStore.contact && !sessionStore.permissions.checkOutAsCustomer) {
        let company;
        if (customerStore.businessIndustry) {
          company = {
            industry: customerStore.businessIndustry,
            name: customerStore.businessName,
          };
        }
        window.rudderanalytics?.identify(customerStore.contact.id, {
          company,
          contact_id: customerStore.contact.id,
          contact_is_b2b: sessionStore.isB2bContact,
          createdAt: sessionStore.userCreatedAt,
          customer_id: customer.value?.id,
          email: sessionStore.email || customerStore.contact.email,
          firstName: sessionStore.userFirstName || customerStore.contact.firstName,
        });
      } else {
        window.rudderanalytics?.identify();
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    businessIndustry,
    creditAccount,
    customer,
    customerAddresses,
    customerDiscountCodes,
    customerMemberSince,
    customerLasershipAllowed,
    customerOntracAllowed,
    customerOrdersCount,
    customerProfileImage,
    customerRegionalCarriersAllowed,
    hasBusinessAccount,
    offerNewsletter,
    sendIdentifyEvent,
    storeCredit,
    uniqueAddresses,

    async convertToBusinessAccount() {
      await store.dispatch('customerModule/convertToBusinessAccount');
    },

    async getAvailableUniqueAddresses() {
      await store.dispatch('customerModule/getAvailableUniqueAddresses');
    },

    async loadCreditAccounts() {
      await store.dispatch('customerModule/loadCreditAccounts');
    },

    async loadCustomerInfo(email?: string) {
      await store.dispatch('customerModule/loadCustomerInfo', email);
      loadSafcToken(email);
    },

    async loadGuestContactInfo(email: string) {
      await store.dispatch('customerModule/loadCustomerInfo', email);
    },

    setBusinessUnitIndustry(industry: string) {
      customerStore.businessIndustry = industry;
      // TODO: remove once Vuex DY module is removed
      store.commit('customerModule/SET_BUSINESS_UNIT_INDUSTRY', industry);
    },

    setBusinessAccountInfo(businessAccountInfo: {
      hasBusinessAccount: boolean;
      industry: string | null;
    }) {
      customerStore.businessIndustry = businessAccountInfo.industry ?? undefined;
      customerStore.hasBusinessAccount = businessAccountInfo.hasBusinessAccount;
      // TODO: remove once Vuex DY module is removed
      store.commit(
        'customerModule/SET_HAS_BUSINESS_ACCOUNT',
        businessAccountInfo.hasBusinessAccount,
      );
      store.commit('customerModule/SET_BUSINESS_UNIT_INDUSTRY', businessAccountInfo.industry);
    },

    setCustomer(maybeCustomer?: Customer) {
      store.commit('customerModule/SET_CUSTOMER', maybeCustomer ?? null);
    },

    setCustomerInfo(customerDescription?: DescribeCustomerResponse) {
      store.commit('customerModule/SET_CUSTOMER_INFO', customerDescription);
    },

    async findExistingCustomer(email: string, captchaResponse: string) {
      return findExistingCustomer(email, captchaResponse);
    },
  };
}

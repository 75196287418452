<script setup lang="ts">
import brokenPeanut from '@/assets/broken-peanut.png';
import RouteLink from '@/components/base/RouteLink.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import { ProductCardData } from '@/utils/productCard';

defineProps<{
  averageRating?: ProductCardData['averageRating'];
  imageSize?: 'small' | 'medium';
  imageUrl?: ProductCardData['imageUrl'];
  name: ProductCardData['name'];
  path: ProductCardData['path'];
  totalReviews?: ProductCardData['totalReviews'];
}>();

const emit = defineEmits(['click']);
</script>

<template>
  <div class="flex items-center">
    <RouteLink
      :to="path"
      class="flex items-center justify-center no-underline hover:no-underline"
      @click="emit('click')"
    >
      <div
        class="bg-white"
        :class="{
          'w-20 h-14': imageSize === 'small',
          'w-24 h-14 md:h-20 md:w-32': imageSize === 'medium',
        }"
      >
        <img v-if="imageUrl" :alt="name" class="object-contain w-full h-full" :src="imageUrl" />
        <img
          v-else
          alt="placeholder image"
          class="object-contain w-16 h-12 md:w-24 md:h-16 shrink-0"
          :src="brokenPeanut"
        />
      </div>
    </RouteLink>
    <div class="flex flex-col w-full ml-2">
      <RouteLink :to="path" class="no-underline hover:no-underline" @click="emit('click')">
        <BaseBodyText class="font-semibold text-black" data-test="product-title">
          {{ name }}
        </BaseBodyText>
      </RouteLink>
      <div class="flex items-center mt-1">
        <slot name="price" />
        <div v-if="averageRating" class="flex items-center">
          <img
            aria-hidden="true"
            alt="star"
            class="object-contain w-4 h-4 mx-1 shrink-0"
            src="@/assets/pdp/star-full.svg"
          />
          <span class="sr-only" data-test="total-reviews">
            {{ averageRating.toFixed(1) }} out of 5 stars
            {{ totalReviews ? `and ${totalReviews} reviews` : '' }}
          </span>
          <BaseBodyText aria-hidden="true" class="text-neutral-500">
            ({{ averageRating.toFixed(1) }}) {{ totalReviews }}
          </BaseBodyText>
        </div>
      </div>
      <slot name="button" />
    </div>
  </div>
</template>

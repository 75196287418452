/* eslint-disable import/prefer-default-export */
import BusinessIcon from '@/assets/business.svg';
import FavoritesIcon from '@/assets/favorites.svg';
import AccountIcon from '@/assets/icons/account.svg';
import GiftBoxIcon from '@/assets/icons/gift-box.svg';
import OrderHistoryIcon from '@/assets/order-history.svg';
import EasyReorderIcon from '@/assets/reorder.svg';
import { SiteNavigation } from '@/utils/navMenu';

export const staticAccountLinks: SiteNavigation['accountLinks'] = [
  {
    icon: AccountIcon,
    link: {
      text: 'My Account',
      url: '/account',
    },
  },
  {
    icon: BusinessIcon,
    link: {
      text: 'Business',
      url: '/business-signup',
    },
  },
  {
    icon: EasyReorderIcon,
    link: {
      text: 'Easy Reorder',
      url: '/account/easy-reorder',
    },
  },
  {
    icon: FavoritesIcon,
    link: {
      text: 'Favorites',
      url: '/account/favorites',
    },
  },
  {
    icon: OrderHistoryIcon,
    link: {
      text: 'My Orders',
      url: '/account/order-history',
    },
  },
  {
    icon: GiftBoxIcon,
    link: {
      text: 'Gift Cards',
      url: '/gifts/gift-certificate',
    },
  },
];

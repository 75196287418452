<script setup lang="ts">
import { useHead } from '@vueuse/head';
import { computed } from 'vue';

import { ContentChoice } from '@/lib/personalization/dynamicYield';

const props = defineProps<{ choice: ContentChoice }>();
const variation = computed(() => props.choice.variations[0]);
const content = computed(() => variation.value?.payload.data);

const hasHtml = computed(() => !!content.value?.html);
const hasStyle = computed(() => !!content.value?.css);
const backgroundImages = computed(() => {
  if (!hasStyle.value) return [];
  const pattern = /background-image:[^;]*\burl\(['"]?([^'");]+)['"]?\);/g;
  return Array.from(content.value!.css!.matchAll(pattern)).map((m) => m[1]);
});
const variationId = computed(() => `variation-id-${variation.value?.id ?? ''}`);

try {
  useHead(
    computed(() => ({
      link: backgroundImages.value.map((href) => ({ rel: 'preload', as: 'image', href })),
      script: content.value?.script ? [{ children: content.value.script, body: true }] : [],
      style: hasStyle.value ? [{ children: content.value!.css }] : [],
    })),
  );
} catch {
  // do nothing; we are in a surrogate component -- useHead() throws an error if the plugin isn't
  // registered via `app.use()`, which only SPA entry points do
}
</script>

<template>
  <div data-test="dy-server-side-variation" :data-variation-id="variationId">
    <div v-if="hasHtml" v-html="content?.html" data-test="variation-html" />
  </div>
</template>

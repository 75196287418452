<script setup lang="ts">
import { dollars, from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { computed } from 'vue';

import AddToCart from '@/components/base/add-to-cart/AddToCart.vue';
import RouteLink from '@/components/base/RouteLink.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import { Size, Theme } from '@/composables/useButtonStyles';
import { flattenCategoryMetadata, ListMetadata } from '@/utils/analytics';
import { GtmAddToCartPayload } from '@/utils/analytics/productAddedEvent';
import { Money } from '@/utils/money';

const props = withDefaults(
  defineProps<{
    cost?: string;
    coupon?: string;
    disabled?: boolean;
    discount?: Money;
    indexName?: string;
    listMetadata?: ListMetadata;
    listName?: string;
    merchandisingCategory?: string;
    name?: string;
    outOfStock?: boolean;
    path?: string;
    position?: number;
    productKey?: string;
    reportingCategory?: string;
    requiresCustomization?: boolean;
    searchQueryID?: string;
    singlePiecePrice?: number;
    size?: Size;
    skipCartAdded?: boolean;
    theme?: Theme;
    trackingEventLocation?: string;
    variantName?: string;
    variantSku?: string;
    weight?: string;
  }>(),
  {
    outOfStock: false,
    size: 'small',
  },
);
const emit = defineEmits(['added']);

const trackingEventObj = computed<GtmAddToCartPayload>(() => ({
  ecommerce: {
    currencyCode: 'USD',
    add: {
      actionField: { list: props.listName ?? '' },
      products: [
        {
          id: `0${props.variantSku ?? ''}`,
          itemExternalId: props.productKey ?? '',
          name: props.name ?? '',
          price: props.singlePiecePrice ?? '',
          quantity: 1,
        },
      ],
    },
  },
}));

const googleTrackingEventObj = computed(() => ({
  currency: 'USD',
  value: props.singlePiecePrice,
  items: [
    {
      autodelivery_interval: undefined,
      cost: props.cost,
      coupon: props.coupon,
      discount: dollars(props.discount || from(0)),
      index: props.position,
      ...(props.indexName && { indexName: props.indexName }),
      item_id: props.productKey,
      item_list_name: props.listName,
      item_name: props.name ?? null,
      item_variant: props.variantSku,
      item_variant_name: props.variantName,
      merchandising_category: props.merchandisingCategory,
      price: props.singlePiecePrice,
      price_before_discount: props.singlePiecePrice,
      quantity: 1,
      reporting_category: props.reportingCategory,
      searchQueryID: props.searchQueryID,
      weight: props.weight,
      ...flattenCategoryMetadata(props.listMetadata?.category, 'listMetadata_category'),
    },
  ],
}));
</script>

<template>
  <RouteLink
    v-if="outOfStock"
    :aria-disabled="true"
    class="block hover:no-underline"
    :data-index="position"
    role="button"
    :to="path"
  >
    <ThemedButton :disabled="true" fullWidth :size="size"> Sold Out </ThemedButton>
  </RouteLink>
  <RouteLink
    v-else-if="requiresCustomization"
    :to="path"
    :data-index="position"
    class="block hover:no-underline"
  >
    <ThemedButton fullWidth :size="size" :theme="theme" @click="emit('added')">
      Customize
    </ThemedButton>
  </RouteLink>
  <AddToCart
    v-else
    :disabled="disabled"
    :eventObj="trackingEventObj"
    :googleEventObj="googleTrackingEventObj"
    fullWidth
    :listMetadata="listMetadata"
    :location="trackingEventLocation"
    :quantity="1"
    :size="size"
    :skipCartAdded="skipCartAdded"
    :sku="variantSku"
    :theme="theme"
    @added="emit('added')"
  >
    <slot name="add-to-cart-text" />
  </AddToCart>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import Container from '@/components/base/layout/Container.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { useCart } from '@/composables/useCart';
import { useChat } from '@/composables/useChat';
import { useCheckout } from '@/composables/useCheckout';
import { useCustomer } from '@/composables/useCustomer';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { useLoginModal } from '@/composables/useLoginModal';
import { useReferralElement } from '@/composables/useReferralElement';
import { useSession } from '@/stores/session';
import { guardClickEvent } from '@/utils/browser';

const router = useRouter();
const store = useStore();

const { preCheckoutLineItems: lineItems } = useCart(store);
const { displayChatInfoText, initChat, isChatStatusPending, openChat } = useChat();
const { cxMode } = useCheckout(store);
const { customer } = useCustomer(store);
const { flags } = useFeatureFlags(store);
const { handleOpen } = useLoginModal();
const { navigateTo } = useRouteChange(router);
const { userFirstName } = storeToRefs(useSession());

const referralLink = ref<HTMLElement>();
const topShelfLinks = ref<HTMLElement>();

const requireUser = (event: MouseEvent) => {
  if (!guardClickEvent(event)) return;
  const destination = event.currentTarget?.getAttribute('href') || undefined;

  if (customer.value?.id || cxMode.value) {
    if (!destination) return;
    navigateTo(destination);
  } else {
    handleOpen({ destination });
  }
};

const getDataPromo = (name: string) => ({
  'data-promo': '1',
  'data-promo-creative': 'Header Links',
  'data-promo-name': `Utility Links: ${name}`,
});

onMounted(initChat);

const { showReferralElement } = useReferralElement(
  store,
  referralLink,
  flags.layoutRebrandingChangesV1 ? 'global_header_rebrandv1' : 'global_header_redesignv1',
);

const cartIcon = nutshell['img/sprite-cart-white.svg'];
const easyReorderIcon = nutshell['img/rasterized/sprite-easy-reorder-icon@1x.png'];
const accountIcon = nutshell['img/sprite-tiny-peanut-white.svg'];

useRouterLinks(topShelfLinks, router);
</script>

<template>
  <div
    class="fixed inset-x-0 top-0 z-20"
    :class="flags.layoutRebrandingChangesV1 ? 'bg-nuts-amber-400' : 'bg-nuts-neutral-950'"
    data-test="top-shelf"
  >
    <Container tag="nav" aria-label="Top shelf">
      <div class="flex items-center justify-between">
        <transition name="fade">
          <template v-if="!isChatStatusPending">
            <div v-if="displayChatInfoText" class="inline-flex" data-test="chat-text">
              <SmallBodyText :class="flags.layoutRebrandingChangesV1 ? 'text-black' : 'text-white'">
                Order online or via
              </SmallBodyText>
              <UnstyledButton class="ml-1" data-test="open-live-chat" @click="openChat">
                <SmallBodyText
                  :class="flags.layoutRebrandingChangesV1 ? 'text-black' : 'text-white'"
                  underline
                >
                  live chat
                </SmallBodyText>
              </UnstyledButton>
            </div>
          </template>
        </transition>

        <ul
          class="inline-flex items-center ml-auto gap-x-6"
          data-test="persistent-nav"
          ref="topShelfLinks"
        >
          <li v-if="showReferralElement" data-test="referral-link">
            <a
              v-once
              href="/refer"
              class="py-0.5"
              ref="referralLink"
              v-bind="getDataPromo('Refer & Earn')"
            >
              <SmallBodyText
                class="hover:underline"
                :class="
                  flags.layoutRebrandingChangesV1
                    ? 'text-black hover:font-bold'
                    : 'text-nuts-cyan-400'
                "
              >
                Refer &amp; Earn
              </SmallBodyText>
            </a>
          </li>

          <li data-test="nuts-for-business">
            <a href="/business-signup" class="py-0.5" v-bind="getDataPromo('Nuts for Business')">
              <SmallBodyText
                class="hover:underline"
                :class="
                  flags.layoutRebrandingChangesV1
                    ? 'text-black hover:font-bold'
                    : 'text-nuts-cyan-400'
                "
              >
                Nuts For Business
              </SmallBodyText>
            </a>
          </li>

          <li
            class="border-r border-solid border-nuts-neutral-700"
            :class="flags.layoutRebrandingChangesV1 ? 'pr-3' : 'pr-6'"
            data-test="help"
          >
            <a href="https://help.nuts.com" class="py-0.5" v-bind="getDataPromo('Need Help?')">
              <SmallBodyText
                class="hover:underline"
                :class="
                  flags.layoutRebrandingChangesV1
                    ? 'text-black hover:font-bold'
                    : 'text-nuts-cyan-400'
                "
              >
                Help
              </SmallBodyText>
            </a>
          </li>

          <li class="inline-flex items-center justify-center gap-x-1 py-0.5" data-test="user">
            <img
              v-if="flags.layoutRebrandingChangesV1"
              alt=""
              height="24"
              src="@/assets/layout-rebrand-v1/account.svg"
              width="24"
            />
            <img
              v-else
              alt=""
              class="object-contain w-5 h-5"
              data-test="peanut-image"
              :src="accountIcon"
            />
            <template v-if="userFirstName">
              <SmallBodyText :class="flags.layoutRebrandingChangesV1 ? 'text-black' : 'text-white'">
                Hi
              </SmallBodyText>
              <a href="/account">
                <SmallBodyText
                  class="hover:underline"
                  :class="
                    flags.layoutRebrandingChangesV1
                      ? 'text-black hover:font-bold'
                      : 'text-nuts-cyan-400'
                  "
                >
                  {{ userFirstName }}
                </SmallBodyText>
              </a>
              <SmallBodyText
                aria-hidden="true"
                :class="flags.layoutRebrandingChangesV1 ? 'text-black' : 'text-white'"
              >
                •
              </SmallBodyText>
              <a href="/sign/out" class="g_id_signout">
                <SmallBodyText
                  class="hover:underline"
                  :class="
                    flags.layoutRebrandingChangesV1
                      ? 'text-black hover:font-bold'
                      : 'text-nuts-cyan-400'
                  "
                  data-test="sign-out"
                >
                  Sign out
                </SmallBodyText>
              </a>
            </template>
            <a
              v-else
              href=""
              data-test="sign-in-link"
              @click.prevent="requireUser"
              v-bind="getDataPromo('Sign in to your account')"
            >
              <SmallBodyText
                class="hover:underline"
                :class="
                  flags.layoutRebrandingChangesV1
                    ? 'text-black hover:font-bold'
                    : 'text-nuts-cyan-400 '
                "
              >
                Sign in to your account
              </SmallBodyText>
            </a>
          </li>

          <li class="flex" data-test="easy-order">
            <a
              href="/account/easy-reorder"
              class="inline-flex items-center justify-center py-0.5"
              @click.prevent="requireUser"
              v-bind="getDataPromo('Easy Reorder')"
            >
              <img
                v-if="flags.layoutRebrandingChangesV1"
                alt=""
                class="object-contain"
                height="24"
                width="24"
                src="@/assets/reorder.svg"
              />
              <img
                v-else
                alt=""
                class="object-contain w-5 h-5"
                data-test="easy-reorder-image"
                :src="easyReorderIcon"
              />
              <SmallBodyText
                class="ml-1 hover:underline"
                :class="
                  flags.layoutRebrandingChangesV1
                    ? 'text-black hover:font-bold'
                    : 'text-nuts-cyan-400 '
                "
              >
                Easy Reorder
              </SmallBodyText>
            </a>
          </li>

          <li class="relative flex" id="cart-utility-link">
            <UnstyledButton
              class="inline-flex items-center justify-center py-2.5"
              :class="{ 'h-10': flags.layoutRebrandingChangesV1 }"
              data-test="cart"
              @click="navigateTo('/cart')"
              v-bind="getDataPromo('Your Cart')"
            >
              <img
                v-if="flags.layoutRebrandingChangesV1"
                alt=""
                class="object-contain w-6 h-6"
                data-test="cart-image"
                src="@/assets/shopping-cart.svg"
              />
              <img
                v-else
                alt=""
                class="object-contain w-5 h-5"
                data-test="cart-image"
                :src="cartIcon"
              />
              <span
                v-if="lineItems.length"
                class="px-1.5 rounded-xl text-tiny -ml-2 min-w-[1.25rem]"
                :class="
                  flags.layoutRebrandingChangesV1
                    ? 'text-black bg-white relative -top-2 -left-1'
                    : 'text-white bg-red-600 py-0.5'
                "
              >
                <span aria-hidden="true" class="text-xs font-semibold leading-tight text-center">
                  {{ lineItems.length }}
                </span>
              </span>
              <SmallBodyText
                class="ml-1 hover:underline"
                :class="
                  flags.layoutRebrandingChangesV1
                    ? 'text-black hover:font-bold'
                    : 'text-nuts-cyan-400'
                "
              >
                <span class="sr-only">{{ lineItems.length }} items in</span>Your Cart
              </SmallBodyText>
            </UnstyledButton>
          </li>
        </ul>
      </div>
    </Container>
  </div>
</template>

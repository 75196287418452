<script lang="ts" setup>
import { computed } from 'vue';

import { IconDetails, IconSize } from '@/utils/svg';

interface Props {
  rating: number;
  size?: IconSize | [IconSize, IconSize];
  starExtraClasses?: string;
}

const props = defineProps<Props>();

const size = computed<[IconSize, IconSize] | undefined>(() => {
  if (!props.size) return undefined;
  return typeof props.size === 'number' ? [props.size, props.size] : props.size;
});

const starSizeClasses = computed(() => {
  if (!size.value) {
    return 'w-3.5 h-3.5 md:w-4 md:h-4';
  }

  const [mobile, desktop] = size.value;

  const mobileDimensions = IconDetails[mobile].dimensions.default;
  let classes = mobileDimensions;

  if (mobile !== desktop) {
    const desktopDimensions = IconDetails[desktop].dimensions.md;
    classes += ` ${desktopDimensions}`;
  }

  return classes;
});

const fullStars = computed(() => Math.trunc(props.rating));
const halfStar = computed(() => props.rating % 1 >= 0.5);
const emptyStars = computed(() => 5 - fullStars.value - (halfStar.value ? 1 : 0));
</script>

<template>
  <div class="flex gap-0.5" data-test="rating-stars">
    <span class="sr-only">{{ rating }} out of 5 stars</span>
    <img
      v-for="star in fullStars"
      :key="star"
      aria-hidden="true"
      alt="star"
      :class="['shrink-0 object-contain', starSizeClasses, starExtraClasses]"
      src="@/assets/pdp/star-full.svg"
    />
    <img
      v-if="halfStar"
      aria-hidden="true"
      alt="star"
      :class="['shrink-0 object-contain', starSizeClasses, starExtraClasses]"
      src="@/assets/pdp/star-half.svg"
    />
    <img
      v-for="star in emptyStars"
      :key="star"
      aria-hidden="true"
      alt="star"
      :class="['shrink-0 object-contain', starSizeClasses, starExtraClasses]"
      src="@/assets/pdp/star-empty.svg"
    />
  </div>
</template>

import { DataModel, ExpandableReference } from '@/utils/cms';

export interface NavLink {
  text: string;
  url: string;
}

export interface NavLinkGroup {
  group: {
    headerText?: string;
    headerUrl?: string;
    links: {
      emphasized?: boolean;
      link: NavLink;
    }[];
  };
}

interface NavLinkWithIcon {
  icon: string;
  link: NavLink;
}

interface AccountLinks {
  links: NavLinkWithIcon[];
}

interface PromoZone {
  image: string;
  text: string;
  url: string;
}

export interface MegaMenuGroup {
  columns: {
    linkGroups: NavLinkGroup[];
  }[];
  header: NavLink;
  headerHtml?: string;
  promoZone: {
    bottom: PromoZone & { cssClass: string };
    top: PromoZone & { cssClass: string };
  };
}

export interface MegaMenu {
  menuGroups: MegaMenuGroup[];
}

export interface MobileMenuGroup {
  linkGroups?: NavLinkGroup[];
  headerText: string;
  headerUrl?: string;
}

export interface MobileNav {
  menuGroups: MobileMenuGroup[];
  utilityMenu?: {
    dotColor?: string;
    featureFlags?: string;
    icon?: string;
    link: NavLink;
  }[];
}

interface Emphasis {
  color?: string;
  icon?: string;
}

interface MenuBase {
  header: Emphasis & {
    text: string;
    url?: string;
  };
}

export interface LargeMenu extends MenuBase {
  promoZones?: [PromoZone] | [PromoZone, PromoZone];
  sections: {
    linkGroups: NavLinkGroup[];
  }[];
}

export interface LargeMenuGroup {
  menus: { menu: LargeMenu }[];
}

export interface LargeMenuGroupAsField {
  // unfortunate CMS nesting
  menus: LargeMenuGroup;
}

export interface SmallMenu extends MenuBase {
  linkGroups: NavLinkGroup[];
}

export type MenuByType = (LargeMenu & { type: 'LargeMenu' }) | (SmallMenu & { type: 'SmallMenu' });

export interface SiteNavigation {
  accountLinks: AccountLinks['links'];
  categories: LargeMenuGroup['menus'];
  featuredPage?: Emphasis &
    NavLink & {
      placement: 'first' | 'last';
    };
  topMenus?: MenuByType[];
}

export interface SiteNavigationResult {
  accountLinks: ExpandableReference<DataModel<AccountLinks>>;
  categories: ExpandableReference<DataModel<LargeMenuGroupAsField>>;
  featuredPage?: Emphasis &
    NavLink & {
      placement: 'first' | 'last';
    };
  topMenus?: (
    | { largeMenu: ExpandableReference<DataModel<LargeMenu>> }
    | { smallMenu: ExpandableReference<DataModel<SmallMenu>> }
  )[];
}

interface DataPromoAttributes {
  'data-promo': '1';
  'data-promo-creative': string;
  'data-promo-name': string;
}
type MenuRoot = NonNullable<SiteNavigation['featuredPage']> | LargeMenu | SmallMenu;
type TaxonomyFragments = (string | undefined)[];
const getDataPromoAttributes = (
  promoName: string,
  menu: MenuRoot,
  ...pieces: TaxonomyFragments
): DataPromoAttributes => {
  let creative: string;
  if ('linkGroups' in menu || 'sections' in menu) {
    creative = [menu.header.text]
      .concat(pieces.filter((s: string | undefined): s is string => !!s))
      .join(' > ');
  } else {
    creative = `Featured Page: ${menu.text}`;
  }
  return {
    'data-promo': '1',
    // normally alphabetized, but this makes visually reading the DOM clearer
    'data-promo-name': promoName,
    'data-promo-creative': creative,
  };
};
export const desktopAttribution = (
  menu: MenuRoot,
  ...pieces: TaxonomyFragments
): DataPromoAttributes => getDataPromoAttributes('Megamenu Nav Links', menu, ...pieces);
export const mobileAttribution = (
  menu: MenuRoot,
  ...pieces: TaxonomyFragments
): DataPromoAttributes => getDataPromoAttributes('Mobile Menu Nav Links', menu, ...pieces);

export const SiteNavigationCategories = {
  fromMegaMenu(megaMenu: MegaMenu): SiteNavigation['categories'] {
    const { menuGroups } = megaMenu;

    const menus = menuGroups.map((menuGroup) => {
      const promoZones: [PromoZone, PromoZone] = [
        {
          image: menuGroup.promoZone.top.image,
          text: menuGroup.promoZone.top.text,
          url: menuGroup.promoZone.top.url,
        },
        {
          image: menuGroup.promoZone.bottom.image,
          text: menuGroup.promoZone.bottom.text,
          url: menuGroup.promoZone.bottom.url,
        },
      ];
      return {
        menu: {
          header: {
            text: menuGroup.header.text,
            url: menuGroup.header.url,
          },
          sections: menuGroup.columns,
          promoZones,
        },
      };
    });

    return menus;
  },
  fromMobileNav(mobileNav: MobileNav): SiteNavigation['categories'] {
    const { menuGroups } = mobileNav;

    const sections = menuGroups.map((menuGroup) => ({
      linkGroups: menuGroup.linkGroups ?? [],
    }));

    const header = menuGroups.map((menuGroup) => ({
      text: menuGroup.headerText,
      url: menuGroup.headerUrl,
    }));

    const menus = sections.map((section, index) => ({
      menu: {
        header: header[index],
        sections: [section],
      },
    }));

    return menus;
  },
};

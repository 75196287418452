import { MaybeComputedRef, MaybeElementRef, unrefElement, useEventListener } from '@vueuse/core';
import { ref } from 'vue';
import { RouteLocationRaw, Router, useLink } from 'vue-router';

import { guardClickEvent } from '@/utils/browser';

/**
 * Reactively handle click events on anchor elements within a container element. Useful for
 * large groups where `<RouterLink>` previously had performance issues such as the header.
 * Though untested, should also be capable of detecting nested `v-html` elements, unlocking
 * an upgrade from the older `@/mixins/routerLinkDelegation` / `delegateLinksToRouter()` pattern.
 *
 * @param container template ref to the container element
 * @param router confirms SPA support before binding event listener
 *
 * @todo test `v-html` support
 */
// eslint-disable-next-line import/prefer-default-export
export const useRouterLinks = (container: MaybeElementRef, router?: Router) => {
  if (!router) {
    return;
  }

  const to = ref<RouteLocationRaw>('');
  const { navigate } = useLink({ to });

  async function navigateIfValid(event: PointerEvent) {
    if (!guardClickEvent(event)) {
      console.error('click target is not an element?! %o', event);
      return;
    }

    let link: HTMLAnchorElement | null;
    if (event.target instanceof HTMLAnchorElement) {
      link = event.target;
    } else {
      link = event.target.closest(`a:not([href*='://']), a[href*='://nuts.com']`);
    }

    if (!link || !unrefElement(container)?.contains(link)) {
      return;
    }

    if (
      link.href.includes('mailto:') ||
      link.href.includes('tel:') ||
      // eslint-disable-next-line no-script-url
      link.href.startsWith('javascript:')
    ) {
      return;
    }

    const url = new URL(link.href);
    if (url.pathname.includes('campaign-landing')) {
      return;
    }

    const destination = url.pathname + url.search;
    if (destination === window.location.pathname + window.location.search) {
      return;
    }

    to.value = destination;
    await navigate(event);

    to.value = '';
  }

  // TODO: update type (if even necessary) when @vueuse/core is updated to v10
  type ElementAsEventTarget = MaybeComputedRef<EventTarget | null | undefined>;
  useEventListener<PointerEvent>(container as ElementAsEventTarget, 'click', navigateIfValid);
};

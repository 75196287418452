<script setup lang="ts">
import { ref } from 'vue';

import DyHtmlCssVariation from '@/components/dynamic-yield/DyHtmlCssVariation.vue';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { ContentChoice } from '@/lib/personalization/dynamicYield';

defineProps<{ choice: ContentChoice }>();

const templateRoot = ref<HTMLElement>();
useRouterLinks(templateRoot);
</script>

<template>
  <div
    class="dynamic-content"
    data-test="dy-server-side-renderer"
    :data-decision-id="choice.decisionId"
    ref="templateRoot"
  >
    <div data-test="variation-container">
      <DyHtmlCssVariation :choice="choice" />
    </div>
  </div>
</template>

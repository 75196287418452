<script setup lang="ts">
import { email, required } from '@vee-validate/rules';
import { Form } from 'vee-validate';
import { ref } from 'vue';
import { useStore } from 'vuex';

import { subscribe } from '@/api/contacts';
import FormInput from '@/components/base/form/FormInput.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import ToastNotification from '@/components/base/ToastNotification.vue';
import { useCallback } from '@/composables/useCallback';
import { useCustomer } from '@/composables/useCustomer';
import { FlexibleMessageValidator, useForm } from '@/composables/useForm';

const store = useStore();
const { customer } = useCustomer(store);

const subscribed = ref(false);
const notificationMessage = ref<string>();
const emailInput = ref(customer.value?.email ?? '');
const firstNameInput = ref(customer.value?.firstName ?? '');
const { errorMessages, validatorFailed } = useForm();

const rules: Record<'email', FlexibleMessageValidator<string>> = {
  email: (value) => {
    if (!required(value)) return errorMessages.email.required;
    if (!email(value)) return errorMessages.email.email;
    return true;
  },
};

const onSubmit = useCallback(async () => {
  try {
    await subscribe(emailInput.value);
    notificationMessage.value =
      'Thank you for subscribing! Stay tuned for exciting updates and exclusive content!';
  } catch (error) {
    notificationMessage.value = 'Oops... Something went wrong. Please try again.';
  }
});
</script>

<template>
  <Form
    v-if="!subscribed"
    v-slot="{ meta: formEmailMeta }"
    @submit="onSubmit.execute()"
    class="grid gap-5"
  >
    <FormInput
      v-model="emailInput"
      aria-label="email"
      class="lg:w-full"
      :inputAttributes="{
        autocomplete: 'email',
        name: 'email',
        placeholder: 'Your Email',
        type: 'email',
      }"
      showLabel
      showPlaceholder
      theme="dark"
      :validator="rules.email"
    />
    <FormInput
      v-model="firstNameInput"
      aria-label="firt name"
      class="lg:w-full"
      :inputAttributes="{
        autocomplete: 'name',
        name: 'name',
        placeholder: 'Your Name (optional)',
        type: 'text',
      }"
      showLabel
      showPlaceholder
    />
    <ThemedButton
      class="w-full h-12"
      :disabled="(formEmailMeta.touched && validatorFailed(formEmailMeta)) ?? false"
      :isLoading="onSubmit.isPending"
      size="default"
      theme="dark-yellow"
      type="submit"
    >
      Subscribe
    </ThemedButton>
    <ToastNotification :show="!!notificationMessage" @hide="notificationMessage = ''">
      <SmallBodyText>{{ notificationMessage }}</SmallBodyText>
    </ToastNotification>
  </Form>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import ThemedButton from '@/components/base/ThemedButton.vue';
import { buttonStyleProps } from '@/composables/useButtonStyles';
import { useCart } from '@/composables/useCart';
import { BYOB_PRODUCT_SKU } from '@/lib/customizers/boxes';
import { productAdded } from '@/rudder-typer';
import analytics, { gtag, ListMetadata } from '@/utils/analytics';
import { GtmAddToCartPayload } from '@/utils/analytics/productAddedEvent';
import { buildProductAddedPayload } from '@/utils/analytics/rudderstack';
import { getCartId } from '@/utils/cart';
import { reportError } from '@/utils/reportError';

const emit = defineEmits(['added']);
const props = defineProps({
  autoDeliveryIntervalWeeks: { required: false, type: Number },
  disabled: { required: false, type: Boolean, default: false },
  distributionChannelKey: { required: false, type: String },
  dynamicBundleSkus: { required: false, type: Array as PropType<string[]> },
  eventObj: { required: false, type: Object as PropType<GtmAddToCartPayload> },
  fullWidth: { required: false, type: Boolean, default: false },
  googleEventObj: { required: false, type: Object },
  inputType: { required: false, type: String, default: 'button' },
  isParentPending: { required: false, type: Boolean, default: false },
  listMetadata: { type: Object as PropType<ListMetadata> },
  location: { required: true, type: String },
  overrideAddToCart: { required: false, type: Function },
  quantity: { required: true, type: Number },
  size: buttonStyleProps.size,
  skipCartAdded: { required: false, type: Boolean },
  sku: { required: true, type: String },
  theme: buttonStyleProps.theme,
});

const store = useStore();
const router = useRouter();

const { addToCart, cart } = useCart(store);

const isPending = ref(false);

const buttonDisabled = computed(() => isPending.value || props.isParentPending || props.disabled);
const buttonLoading = computed(() => isPending.value || props.isParentPending);

const addSkuToCart = async () => {
  analytics.sendEvent('addToCart', props.location, props.eventObj);
  gtag('event', 'add_to_cart', props.googleEventObj ?? {});

  try {
    const route = useRoute();
    const eventProperties = buildProductAddedPayload(props.googleEventObj ?? {}, {
      cartId: getCartId(cart.value),
      imageUrl: '',
      url: route?.fullPath,
    });
    if (eventProperties) productAdded(eventProperties);
  } catch (e) {
    reportError(e);
  }

  if (props.inputType !== 'submit') {
    isPending.value = true;

    if (props.overrideAddToCart) {
      await props.overrideAddToCart();
    } else {
      const isProductBYOB = props.sku === BYOB_PRODUCT_SKU;

      const postAddToCartCallback = !isProductBYOB || props.skipCartAdded ? () => null : undefined;

      await addToCart(
        {
          cart_sku: {
            auto_delivery_interval_weeks: props.autoDeliveryIntervalWeeks,
            auto_delivery_offer_type: props.autoDeliveryIntervalWeeks ? 'Standard' : null,
            auto_delivery_offer_location: props.autoDeliveryIntervalWeeks ? props.location : null,
            distribution_channel_key: props.distributionChannelKey,
            dynamic_bundle_skus: props.dynamicBundleSkus,
            list_metadata: JSON.stringify(props.listMetadata),
            quantity: props.quantity,
            sku_external_id: props.sku,
          },
        },
        {
          postAddToCartCallback,
          router,
          skipCartAdded: isProductBYOB || props.skipCartAdded,
        },
      );
    }
    isPending.value = false;
    emit('added');
  }
};
</script>

<template>
  <div class="add-to-cart-button-box">
    <slot name="price-and-quantity-section" />
    <div class="w-full button-container">
      <ThemedButton
        class="add-to-cart-button"
        data-test="add-to-cart-button"
        :disabled="buttonDisabled"
        :type="inputType"
        :fullWidth="fullWidth"
        :isLoading="buttonLoading"
        :size="size"
        :theme="theme"
        @click="addSkuToCart"
      >
        <slot>Add to Cart</slot>
      </ThemedButton>
    </div>
  </div>
</template>

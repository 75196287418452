<script>
import { mapActions, mapState } from 'vuex';

import Header6 from '@/components/base/typography/Header6.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';

export default {
  name: 'CategoryFilterControlPanel',
  props: {
    catalogCategoryTree: { required: true, type: Array, default: () => [] },
  },
  computed: {
    ...mapState('customTraysModule', ['activeFilters']),
    categoryTree() {
      return this.catalogCategoryTree.map((category) => {
        const childrenWithActive = category.children.map((child) => ({
          ...child,
          active: this.activeFilters.includes(child.filter),
        }));
        return {
          ...category,
          active:
            this.activeFilters.includes(category.filter) ||
            childrenWithActive.find((child) => child.active),
          children: childrenWithActive,
        };
      });
    },
  },
  methods: {
    ...mapActions('customTraysModule', ['toggleActiveCategoryFilter']),
    toggleFilter(filter) {
      this.toggleActiveCategoryFilter(filter);
      this.$emit('filters:changed');
    },
  },
  components: { Header6, UnstyledButton },
};
</script>

<template>
  <div class="flex flex-col items-start" data-test="category-filter-control-panel">
    <Header6 headerTag="h2" class="mb-10 visible-xs visible-sm"> Choose a Category </Header6>
    <div class="mt-2 mobile-scroll-snap-container">
      <div
        v-for="category in categoryTree"
        :key="category.filter"
        class="inline-block text-sm font-proxima-nova font-normal bg-nuts-amber-400 rounded-full mr-1.5 mb-1.5 py-2 px-4 cursor-pointer relative lg:flex lg:flex-col lg:border-none lg:bg-transparent lg:mx-0 lg:mt-0 lg:p-0 border border-solid"
        :class="category.active ? 'border-black' : 'border-transparent'"
      >
        <UnstyledButton
          class="flex"
          @click.prevent="toggleFilter(category.filter)"
          @keydown.enter.prevent="toggleFilter(category.filter)"
          :class="{ active: category.active }"
          data-test="category-filter-control"
        >
          <label
            class="mb-0 lg:mt-0 lg:mb-1.5 text-sm lg:text-lg lg:font-semibold font-normal text-black cursor-pointer font-proxima-nova hover:underline"
            :class="{ underline: category.active }"
            :data-test="category.name"
          >
            {{ category.name }}
            <input type="checkbox" :id="`category-filter-${category.filter}`" class="sr-only" />
          </label>
        </UnstyledButton>
        <div
          v-for="subcategory in category.children"
          :key="subcategory.filter"
          v-show="category.active"
          class="hidden lg:inline-block"
          :class="{ active: subcategory.active }"
          data-test="subcategory-filter-control"
        >
          <UnstyledButton
            class="flex"
            @click.prevent="toggleFilter(subcategory.filter)"
            @keydown.enter.prevent="toggleFilter(subcategory.filter)"
          >
            <label
              class="mb-0 text-sm text-black cursor-pointer lg:text-base font-proxima-nova hover:underline"
              :class="subcategory.active ? 'font-bold' : 'font-extralight'"
            >
              {{ subcategory.name }}
              <input
                type="checkbox"
                :id="`subcategory-filter-${subcategory.filter}`"
                class="sr-only"
              />
            </label>
          </UnstyledButton>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
h3 {
  font-family: $font-family-proxima;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.33;
}
h4 {
  font-family: $font-family-proxima;
  font-size: 18px;
  line-height: 1.22;
}

.hidden {
  visibility: visible;
}
</style>

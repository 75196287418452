<script setup lang="ts">
import { useScroll } from '@vueuse/core';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import { AlgoliaVariant } from '@/api/algolia';
import ChevronIcon from '@/components/base/assets/ChevronIcon.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { isHTMLElement } from '@/utils/browser';

const props = defineProps<{ products: AlgoliaVariant[] }>();
const emit = defineEmits<{ click: [string] }>();

const rootContainer = ref<HTMLElement>();
const scrollContainer = ref<HTMLElement>();

const { arrivedState } = useScroll(scrollContainer);

const suggestionsOverflowing = computed(() => {
  if (!scrollContainer.value) return false;

  const { childElementCount, firstElementChild, offsetWidth } = scrollContainer.value;
  if (childElementCount && firstElementChild && isHTMLElement(firstElementChild)) {
    return childElementCount * (firstElementChild.offsetWidth + 16) > offsetWidth;
  }

  return false;
});

const scrollByDirection = (direction: 'next' | 'prev') => {
  if (!scrollContainer.value) return;
  const scrollFactor = direction === 'prev' ? -1 : 1;
  scrollContainer.value.scrollBy({
    left: scrollContainer.value.offsetWidth * scrollFactor,
    behavior: 'smooth',
  });
};

watch(
  () => props.products,
  (products) => {
    if (products.length && scrollContainer.value?.offsetWidth) {
      scrollContainer.value?.scrollBy({
        left: -1 * scrollContainer.value.scrollWidth,
        behavior: 'instant',
      });
    }
  },
);

useRouterLinks(rootContainer, useRouter());
</script>

<template>
  <div class="flex flex-col gap-2" ref="rootContainer">
    <SmallBodyText class="px-4 text-neutral-500">Featured Products</SmallBodyText>
    <div class="relative">
      <transition name="fade" :duration="100">
        <UnstyledButton
          v-show="!arrivedState.left && suggestionsOverflowing"
          aria-label="previous slide"
          class="absolute hidden overflow-hidden -translate-y-1/2 bg-white rounded-full shadow-md lg:visible lg:block active:bg-neutral-200 hover:bg-neutral-100 left-4 top-1/2"
          data-test="previous-products-slide-button"
          @click="scrollByDirection('prev')"
        >
          <span class="flex p-3 border border-solid rounded-full border-neutral-300">
            <ChevronIcon class="text-neutral-500" direction="left" />
          </span>
        </UnstyledButton>
      </transition>
      <ul
        class="grid gap-4 px-4 lg:px-0 lg:flex grid-cols-[repeat(auto-fill,minmax(8rem,1fr))] overflow-x-auto scrollbar-hide"
        ref="scrollContainer"
      >
        <li
          v-for="product in products"
          class="flex-1 p-2 border border-solid rounded-lg first:lg:ml-4 last:lg:mr-4 lg:flex-none border-neutral-300"
          :key="product.objectID"
        >
          <a
            class="flex flex-col items-center link-text-black lg:w-36"
            :href="product.Product.path"
          >
            <img
              alt=""
              class="flex object-contain w-32 h-24"
              loading="lazy"
              :src="product.Product.listingImageUrl"
            />
            <BaseBodyText class="self-center text-center line-clamp-2">
              {{ product.Product.name }}
            </BaseBodyText>
          </a>
        </li>
      </ul>
      <transition name="fade" :duration="100">
        <UnstyledButton
          aria-label="next slide"
          v-show="!arrivedState.right && suggestionsOverflowing"
          class="absolute hidden overflow-hidden -translate-y-1/2 bg-white rounded-full shadow-md top-1/2 lg:visible lg:block active:bg-neutral-200 hover:bg-neutral-100 right-4"
          data-test="next-products-slide-button"
          @click="scrollByDirection('next')"
        >
          <span class="flex p-3 border border-solid rounded-full border-neutral-300">
            <ChevronIcon class="text-neutral-500" direction="right" />
          </span>
        </UnstyledButton>
      </transition>
    </div>
  </div>
</template>

<style scoped>
.link-text-black {
  @apply text-black;
}
</style>

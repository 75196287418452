import { GAEvent } from '@/utils/analytics';

/**
 * The original DY cookie; we switched to {@link DY_USER_ID_COOKIE} to mitigate ITP from browsers
 * to retain user association for longer periods of time.
 *
 * @see https://support.dynamicyield.com/hc/en-us/articles/360007211797-Implementing-First-Party-Cookies-to-Resolve-Third-party-Cookie-Browser-Restrictions
 *
 * Webstore continues to use this cookie to set the `newSession` personalization attribute.
 */
export const DY_3RD_PARTY_USER_ID_COOKIE = '_dyid';
export const DY_SESSION_ID_COOKIE = '_dyjsession';
export const DY_USER_ID_COOKIE = '_dyid_server';

export type DyPageType = 'CART' | 'CATEGORY' | 'HOMEPAGE' | 'OTHER' | 'PRODUCT';

export interface AnalyticsMetadata {
  campaignId: number;
  campaignName: string;
  experienceId: number;
  experienceName: string;
  variationId: number;
  variationName: string;
}

/**
 * Result of merchandiser's ranked targeting criteria in DY for a given experience.
 */
export interface BaseChoice {
  decisionId: string;
  groups: unknown[];
  id: number;
  name: string;
}

export interface BaseVariation {
  analyticsMetadata: AnalyticsMetadata;
  id: number;
}

export interface JsonVariation<T extends object = {}> extends BaseVariation {
  payload: {
    data: T;
    type: 'CUSTOM_JSON';
  };
}

export type ContentVariation = JsonVariation<{
  css?: string;
  html?: string;
  script?: string;
}>;

/** This serves as a hopeful spec... We can't actually control what
 * gets entered in the DY UI.
 *
 * `flags` is a comma-separated list of flag names, prefixed with `-` to indicate
 * a falsy value.
 */
export type FeatureFlagsVariation = JsonVariation<{
  dyEvent?: GAEvent;
  flags?: string;
  gaEvent?: GAEvent;
}>;

export interface JsonChoice<T = JsonVariation> extends BaseChoice {
  type: 'DECISION';
  variations: [T] | [];
}

export type ContentChoice = JsonChoice<ContentVariation>;
export type FeatureFlagsChoice = JsonChoice<FeatureFlagsVariation>;

type BooleanString = 'true' | 'false';
export interface RawRecommendation {
  all_variants_out_of_stock: BooleanString;
  auto_delivery_eligible: BooleanString;
  average_rating: string;
  bulk: BooleanString;
  cost: string;
  /** comparison against the primary variant */
  display_comparison_price?: string;
  /** comparison against the primary variant */
  display_discount_percent?: string;
  /** @deprecated, prefer consuming {@link prices_json} */
  display_price: string;
  has_siblings: BooleanString;
  image_url: string;
  in_stock: boolean;
  keywords: string[];
  name: string;
  path_name: string;
  /** @deprecated, prefer consuming {@link prices_json} */
  price: number;
  /** serialized CT structure; includes channel prices */
  prices_json: string;
  primary_category_key: string;
  primary_merchandising_category?: string;
  product_key: string;
  reporting_category?: string;
  requires_customization: BooleanString;
  short_unit_name: string;
  total_ratings: string;
  total_reviews: string;
  unit_name: string;
  wholesale: BooleanString;
}

export interface RecommendationsVariation extends BaseVariation {
  payload: {
    data: {
      custom?: {
        title?: string;
      };
      slots: {
        productData: RawRecommendation;
        /** 0-prefixed variant sku; e.g. `'04003-01'` */
        sku: string;
        slotId?: string;
      }[];
    };
    type: 'RECS';
  };
}
export type RecommendationsSlot = RecommendationsVariation['payload']['data']['slots'][number];

export interface RecommendationsChoice extends BaseChoice {
  type: 'RECS_DECISION';
  variations: [RecommendationsVariation] | [];
}

export interface BaseRecommendationsReference {
  /** may be an empty string */
  analyticsList?: string;
  containerId: string;
  enableAddToCart: boolean;
  /** may be an empty string */
  link: string;
  /** may be an empty string */
  title: string;
}

export interface DyRecommendationsReference extends BaseRecommendationsReference {
  /** may be an empty string */
  dySelector: string;
}

/**
 * Represents some garbage data set up in DY. It seems like the structure that David Lerner used to
 * copy/paste was adapted to consume named variables. However, some groups appear to be dead. We
 * should follow-up and remove such entries, but for now, we'll just ignore them when expanding the
 * response results. Defining this type is intended to remind us that we cannot always trust the
 * self-serve structure of DY.
 */
export interface EmptyConfigTemplateEntry
  extends Omit<BaseRecommendationsReference, 'enableAddToCart'> {
  analyticsList?: '';
  /** may be an empty string */
  containerId: string;
  dySelector: '';
  enableAddToCart: '' | true;
  isCampaign: '' | false;
  link: '';
  title: '';
}

export interface WebstoreRecommendationsReference extends BaseRecommendationsReference {
  recommendationsEndpoint: {
    path: string;
    params: Record<string, any>;
  };
}

export type RecommendationsReferenceVariation = JsonVariation<{
  config: (
    | DyRecommendationsReference
    | EmptyConfigTemplateEntry
    | WebstoreRecommendationsReference
  )[];
}>;
export interface RecommendationsConfigChoice extends BaseChoice {
  type: 'DECISION';
  variations: [RecommendationsReferenceVariation] | [];
}

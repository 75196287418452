import { Customer, DiscountCode } from '@commercetools/platform-sdk';
import truncate from 'lodash/truncate';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { fromNutsJson, NutsJson } from '@/api';
import business from '@/api/account/business';
import {
  addAddress,
  CarrierOptions,
  CreditAccount,
  describeCustomer,
  DescribeCustomerResponse,
  describeMe,
  getAddresses,
  getCreditAccounts,
  Issue,
  updateAddress,
} from '@/api/customer';
import { NutsAddress } from '@/utils/address';
import { reportError } from '@/utils/reportError';

const namespaced = true;

export interface CustomerState {
  addresses: NutsAddress[];
  adminUrl: string | null;
  allowSplitShipments: boolean | null;
  businessIndustry: string | null;
  carrierOptions: CarrierOptions | null;
  creditAccount: CreditAccount | null;
  customer: Customer | null;
  customerDiscountCodes: DiscountCode[];
  hasBusinessAccount: boolean;
  issues: Issue[];
  notes: string | null;
  signedUpAt: string | null;
  storeCredit: CreditAccount | null;
  subscribed: boolean | null;
}

function getDefaultState(): CustomerState {
  return {
    addresses: [],
    adminUrl: null,
    allowSplitShipments: null,
    businessIndustry: null,
    carrierOptions: null,
    creditAccount: null,
    customer: null,
    customerDiscountCodes: [],
    hasBusinessAccount: false,
    issues: [],
    notes: null,
    signedUpAt: null,
    storeCredit: null,
    subscribed: null,
  };
}

/** ********** */
// GETTERS //
/** ********** */

export const getters: GetterTree<CustomerState, any> = {
  uniqueAddresses: (state) =>
    state.addresses.map((addressObj) => ({
      ...addressObj,
      get locationName() {
        const { name = '', street1 = '', street2 = '' } = this as any;
        return truncate(
          [[name, street1].filter(Boolean).join(' - '), street2].filter(Boolean).join(' '),
          { length: 40 },
        );
      },
    })),
};

/** ********** */
// MUTATIONS //
/** ********** */
export const mutations: MutationTree<CustomerState> = {
  SET_ADDRESSES(state, addresses) {
    state.addresses = addresses;
  },
  SET_BUSINESS_UNIT_INDUSTRY(state, industry) {
    state.businessIndustry = industry;
  },
  SET_CUSTOMER(state, customer) {
    state.customer = customer;
  },
  SET_CUSTOMER_INFO(state, info?: DescribeCustomerResponse) {
    if (!info) {
      Object.assign(state, getDefaultState());
      return;
    }

    state.addresses = info.addresses;
    state.adminUrl = info.adminUrl;
    state.allowSplitShipments = info.allowSplitShipments;
    state.carrierOptions = info.carrierOptions;
    state.creditAccount = info.creditAccounts?.creditAccount;
    state.customer = info.customer;
    state.customerDiscountCodes = info.discountCodes;
    state.issues = info.issues;
    state.hasBusinessAccount = info.hasBusinessAccount;
    state.notes = info.notes ?? null;
    state.signedUpAt = info.signedUpAt;
    state.storeCredit = info.creditAccounts?.storeCreditAccount;
    state.subscribed = info.subscribed;
  },
  SET_CREDIT_ACCOUNT(state, creditAccount: CreditAccount | null) {
    state.creditAccount = creditAccount;
  },
  SET_HAS_BUSINESS_ACCOUNT(state, hasBusinessAccount) {
    state.hasBusinessAccount = hasBusinessAccount;
  },
  SET_STORE_CREDIT(state, storeCredit: CreditAccount | null) {
    state.storeCredit = storeCredit;
  },
};

/** ******** */
// ACTIONS //
/** ******** */
const handleNJ = <T>(promise: Promise<NutsJson<T>>) =>
  fromNutsJson(promise, {
    onMessages: 'default',
  });

export const actions: ActionTree<CustomerState, any> = {
  async addAddress({ commit }, address: NutsAddress) {
    const { address: savedAddress, addresses } = await handleNJ(addAddress(address));
    commit('SET_ADDRESSES', addresses);
    return savedAddress;
  },
  async convertToBusinessAccount({ commit }) {
    await business.createBusinessUnit({ source: 'User Converted' });
    commit('SET_HAS_BUSINESS_ACCOUNT', true);
  },
  async getAvailableUniqueAddresses({ commit }) {
    const { addresses = [] } = await handleNJ(getAddresses());
    commit('SET_ADDRESSES', addresses);
  },
  async updateAddress({ commit }, address: NutsAddress) {
    const { address: savedAddress, addresses } = await handleNJ(updateAddress(address));
    commit('SET_ADDRESSES', addresses);
    return savedAddress;
  },
  async loadCustomerInfo({ commit }, email?: string) {
    try {
      const info = await handleNJ(email ? describeCustomer(email) : describeMe());
      commit('SET_CUSTOMER_INFO', info);
    } catch (error) {
      reportError(error);
      commit('SET_CUSTOMER_INFO', undefined);
      throw error;
    }
  },
  async loadCreditAccounts({ commit, state: { customer } }) {
    let creditAccount: CreditAccount | null = null;
    let storeCreditAccount: CreditAccount | null = null;
    if (customer) {
      try {
        ({ creditAccount, storeCreditAccount } = await handleNJ(getCreditAccounts(customer.id)));
      } catch (error) {
        reportError(error);
      }
    }
    commit('SET_CREDIT_ACCOUNT', creditAccount);
    commit('SET_STORE_CREDIT', storeCreditAccount);
  },
};

export const state = () => getDefaultState();

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

import ThemedButton from '@/components/base/ThemedButton.vue';
import { CmsRegisteredComponent, TWColorsBackground, TWColorsText } from '@/utils/cms';

const HeroSectionWithVideo = defineComponent({
  name: 'HeroSectionWithVideo',
  props: {
    backgroundColor: { required: false, type: String, default: 'background (secondary)' },
    buttonLink: { required: false, type: String },
    buttonText: { required: false, type: String },
    buttonTheme: { required: false, type: String, default: 'gray' },
    color: { required: false, type: String, default: 'black' },
    headline: { required: true, type: String, default: 'Headline' },
    tagline: { required: false, type: String },
    text: { required: true, type: String, default: 'Text' },
    videoTitle: { required: true, type: String },
    videoUrl: { required: true, type: String },
  },
  components: {
    ThemedButton,
  },
  setup(props) {
    return {
      textColor: computed(() => TWColorsText[props.color]),
      bgColor: computed(() => TWColorsBackground[props.backgroundColor]),
    };
  },
});
export const HeroSectionWithVideoRegistration: CmsRegisteredComponent = {
  component: HeroSectionWithVideo,
  name: 'Hero Section With Video',
  canHaveChildren: true,
  inputs: [
    {
      name: 'headline',
      type: 'string',
    },
    {
      name: 'tagline',
      type: 'string',
    },
    {
      name: 'text',
      type: 'string',
    },
    {
      name: 'buttonLink',
      type: 'string',
    },
    {
      name: 'buttonTheme',
      type: 'string',
      defaultValue: 'gray',
      enum: ['green', 'gray', 'red', 'white', 'yellow'],
    },
    {
      name: 'buttonText',
      type: 'string',
    },
    {
      name: 'videoUrl',
      type: 'string',
    },
    {
      name: 'videoTitle',
      type: 'string ',
    },
    {
      name: 'backgroundColor',
      type: 'string',
      enum: Object.keys(TWColorsBackground),
      defaultValue: 'background (secondary)',
    },
    {
      name: 'color',
      type: 'string',
      enum: Object.keys(TWColorsText),
      defaultValue: 'black',
    },
  ],
};
export default HeroSectionWithVideo;
</script>

<template>
  <div class="flex flex-col items-center w-full grow" :class="bgColor">
    <div
      class="box-border flex flex-col w-full py-0 mx-auto hero-banner lg:py-24 lg:pt-16 lg:flex-row-reverse"
    >
      <iframe
        :src="videoUrl"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
        class="w-full lg:w-3/5 custom-height"
        :title="videoTitle"
      />
      <div class="flex flex-col items-center py-16 pl-0 pr-6 foreground lg:py-0">
        <div
          v-if="tagline"
          class="w-full mb-1 text-xs font-semibold font-sofia-pro"
          :class="[textColor]"
        >
          {{ tagline }}
        </div>
        <h1
          class="mb-2 text-2xl font-semibold font-sofia-pro lg:mb-4 lg:text-5xl"
          :class="[textColor]"
        >
          {{ headline }}
        </h1>
        <div class="mt-2 mb-4 text-lg lg:text-xl lg:mb-8" :class="[textColor]">
          {{ text }}
        </div>
        <RouterLink v-if="buttonText && buttonLink" :to="buttonLink">
          <ThemedButton :theme="buttonTheme">{{ buttonText }}</ThemedButton>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hero-banner {
  max-width: 1040px;
  width: 100%;
}
.headline-hero {
  font-size: 32px;
  line-height: 36px;
}

.custom-height {
  aspect-ratio: 16 / 9;
}

.foreground {
  width: 100%;
  grid-area: 1/-1;
  justify-content: center;
  text-align: center;
}

@media (min-width: 1024px) {
  .headline-hero {
    font-size: 48px;
    line-height: 52px;
  }
  .foreground {
    width: 41%;
    grid-area: 1/-1;
    justify-content: center;
    text-align: left;
    align-items: flex-start;
  }
}
</style>

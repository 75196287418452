import { Money } from '@commercetools/platform-sdk';
import { ProposedDelivery } from '@nuts/auto-delivery-sdk';
import dayjs from 'dayjs';

import { ShippingCalculatorRequest, ShippingOffer } from '@/api/shippingCalculator';
import { TaxCalculatorRequest } from '@/api/taxCalculator';
import { DateString } from '@/utils/dateTime';

export type Prices =
  | {
      listPrice: Money;
      channelPrice: Money;
      impulsePrice?: Money;
    }
  | {
      listPrice: Money;
      channelPrice?: Money;
      impulsePrice: Money;
    };

export type BuiltProposedDelivery = ProposedDelivery & {
  id: number;
};

export const getAutoDeliveryOrderId = (shippingDate: string, shippingAddressId: string) =>
  Number(`${shippingDate.replace(/-/g, '')}${shippingAddressId}`);

export const buildDeliveriesObject = (delivery: ProposedDelivery) => {
  const builtDelivery = delivery as BuiltProposedDelivery;
  builtDelivery.id = getAutoDeliveryOrderId(delivery.shipOn, delivery.shippingAddressId);

  return builtDelivery;
};

export const buildTaxCalculatorRequest = (
  order: ProposedDelivery,
  shippingOffer: ShippingOffer,
): TaxCalculatorRequest | null => {
  if (!order.shippingAddress) return null;

  return {
    addressKey: order.shippingAddressId,
    address: {
      city: order.shippingAddress.city,
      country: order.shippingAddress.countryCode,
      postalCode: order.shippingAddress.postalCode,
      residential: Boolean(order.shippingAddress.residential),
      state: order.shippingAddress.stateProv,
    },
    estimatedCarrierCharge: shippingOffer.shipmentPickup.estimatedCarrierCharge,
    shippingMethod: {
      carrier: shippingOffer.carrier,
      carrierCode: shippingOffer.carrierCode,
    },
    warehouse:
      shippingOffer.shipmentPickup.warehouse.toUpperCase() as TaxCalculatorRequest['warehouse'],
    shippingCharge: shippingOffer.price,
    lines: order.subscriptions.map((s) => ({
      id: s.id,
      quantity: s.quantity,
      sku: s.sku,
      subtotal: s.discountedExtendedPrice,
    })),
  };
};

export const buildShippingCalculatorRequest = (
  order: ProposedDelivery,
  { regionalCarriersAllowed = true } = {},
): ShippingCalculatorRequest | null => {
  if (!order.shippingAddress?.countryCode) return null;
  return {
    shipments: [
      {
        address: {
          city: order.shippingAddress.city,
          company: order.shippingAddress.company,
          country: order.shippingAddress.countryCode,
          postalCode: order.shippingAddress.postalCode,
          state: order.shippingAddress.stateProv,
          street1: order.shippingAddress.street1,
          street2: order.shippingAddress.street2,
        },
        key: order.shippingAddressId,
        lines: order.subscriptions.map((s) => ({
          quantity: s.quantity,
          sku: s.sku,
          weight: s.variant.weight,
        })),
        shipmentValue: order.totals.subtotal,
      },
    ],
    allowBlueStreak: Boolean(regionalCarriersAllowed),
    allowCdl: Boolean(regionalCarriersAllowed),
    allowGrandHusky: Boolean(regionalCarriersAllowed),
    allowLasership: Boolean(regionalCarriersAllowed),
    allowOntrac: Boolean(regionalCarriersAllowed),
    allowTforce: Boolean(regionalCarriersAllowed),
    allowUds: Boolean(regionalCarriersAllowed),
    requestedShipDate: dayjs(order.shipOn).format('ddd, MMM D') as DateString,
  };
};

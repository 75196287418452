import { Image } from '@commercetools/platform-sdk';

type ImgSize = 'thumb' | 'small' | 'medium' | 'large' | 'zoom';
export type ImageBySize = Record<ImgSize | 'url', string>;
type PartialImage = Pick<Image, 'url'>;

const imageUtil = {
  getVariants(images: PartialImage[]) {
    return images.map((image) => {
      const imgExt = image.url.substr(image.url.lastIndexOf('.'));
      const getVariant = (size: ImgSize) => image.url.replace(imgExt, `-${size}${imgExt}`);

      const expandedImage: ImageBySize = {
        ...image,
        thumb: getVariant('thumb'),
        small: getVariant('small'),
        medium: getVariant('medium'),
        large: getVariant('large'),
        zoom: getVariant('zoom'),
      };

      return expandedImage;
    });
  },
};

export default imageUtil;

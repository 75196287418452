import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';
import pick from 'lodash/pick';

import { productListViewed, ProductListViewedProductsItem } from '@/rudder-typer';
import { gtag } from '@/utils/analytics';
import { Money } from '@/utils/money';
import { reportError } from '@/utils/reportError';

export interface NutsViewItemListItem
  extends Omit<ProductListViewedProductsItem, 'cost' | 'price'> {
  cost?: Money;
  discount?: Money;
  indexName?: string;
  price: Money;
  price_before_discount?: Money;
  searchQueryID?: string;
  weight?: string;
}

interface GTagViewItemListPayload {
  autodelivery_interval: undefined;
  cost?: string;
  coupon?: string;
  discount?: number;
  index: number;
  indexName?: string;
  item_id: string;
  item_list_name: string;
  item_name: string;
  item_variant: string;
  item_variant_name?: string;
  merchandising_category?: string;
  price: number;
  price_before_discount?: number;
  quantity: number;
  reporting_category?: string;
  searchQueryID?: string;
  weight?: string;
}

export function formatGTagViewItemListPayload(
  itemListName: string,
  item: NutsViewItemListItem,
): GTagViewItemListPayload {
  return {
    autodelivery_interval: undefined,
    cost: String(dollars(item.cost)),
    coupon: item.coupon,
    discount: dollars(item.discount),
    index: item.position,
    indexName: item.indexName,
    item_id: item.product_id,
    item_list_name: itemListName,
    item_name: item.name,
    item_variant: item.sku,
    item_variant_name: item.variant,
    merchandising_category: item.category,
    price: dollars(item.price),
    price_before_discount: dollars(item.price_before_discount),
    quantity: item.quantity,
    reporting_category: item.reporting_category,
    searchQueryID: item.searchQueryID,
    weight: item.weight,
  };
}
function pickProductListViewedProductsItem(
  nutsAddToCartItem: NutsViewItemListItem,
): ProductListViewedProductsItem {
  const properties = pick(nutsAddToCartItem, [
    'brand',
    'category',
    'coupon',
    'image_url',
    'name',
    'position',
    'price',
    'product_id',
    'quantity',
    'reporting_category',
    'sku',
    'url',
    'variant',
  ]);
  return {
    ...properties,
    price: dollars(nutsAddToCartItem.price),
  };
}

function sendGtagEvent(listName: string, listItems: NutsViewItemListItem[]) {
  const items = listItems.map((item) => formatGTagViewItemListPayload(listName, item));
  gtag('event', 'view_item_list', {
    item_list_name: listName,
    items,
  });
}

function sendProductListViewed(listName: string, listItems: NutsViewItemListItem[]) {
  try {
    const products = listItems.map(pickProductListViewedProductsItem);
    productListViewed({
      list_id: listName,
      products,
    });
  } catch (e) {
    reportError(e);
  }
}

const queueByItemListName = new Map<string, NutsViewItemListItem[]>();
let viewItemListTimeout: ReturnType<typeof setTimeout> | undefined;

export function sendProductListViewedEvent() {
  if (viewItemListTimeout) {
    clearTimeout(viewItemListTimeout);
    viewItemListTimeout = undefined;
  }

  queueByItemListName.forEach((listItems, listName) => {
    sendGtagEvent(listName, listItems);
    sendProductListViewed(listName, listItems);
  });
  queueByItemListName.clear();
}

if (typeof window !== 'undefined') {
  window.addEventListener('beforeunload', sendProductListViewedEvent, { once: true });
}

export const pushProductListViewed = (listName: string, item: NutsViewItemListItem) => {
  if (!queueByItemListName.has(listName)) {
    queueByItemListName.set(listName, []);
  }
  queueByItemListName.get(listName)?.push(item);

  if (viewItemListTimeout) {
    clearTimeout(viewItemListTimeout);
  }
  viewItemListTimeout = setTimeout(sendProductListViewedEvent, 2000);
};

import {
  Attribute,
  AttributeLocalizedEnumValue,
  LocalizedString,
  Price,
  ProductVariant,
  TypedMoney,
} from '@commercetools/platform-sdk';
import {
  INutsVariantAttributes as NutsVariantAttributes,
  pivotAttributeValues,
} from '@nuts/auto-delivery-sdk/dist/utils/helpers';
import { from } from '@nuts/auto-delivery-sdk/dist/utils/money';

import { TagReference } from '@/api/productTagReferences';
import { ShippingCalculatorRequest } from '@/api/shippingCalculator';
import singularize from '@/filters/singularize';
import { PriceFragment } from '@/graphql/fragments/price';
import { Product, ProductData, RelatedProduct, Variant } from '@/graphql/productQuery';
import { Channel } from '@/stores/autoDelivery';
import { GIFT_VARIANTS, isGiftCertificate as isGiftCertificateUtil } from '@/utils/gift';
import { ImageBySize } from '@/utils/image';
import proxiedImageUtil from '@/utils/imageProxied';
import { computeSavings, Money } from '@/utils/money';
import { VariantPrice } from '@/utils/productCard';

export const MAX_QUANTITY = 4999;

export interface Ancestor {
  id: string;
  name: string;
  url: string;
}

export type VariationKey = 'grindSize' | 'tinColor';

type DiscountedPricesByChannel = Record<
  string,
  | {
      readonly description?: LocalizedString;
      readonly percent: number;
      readonly value: TypedMoney;
    }
  | undefined
>;

export interface NutsProductVariant extends Variant, NutsVariantAttributes {
  readonly attributesRaw: Attribute[];
  readonly countPerPound?: number;
  readonly countryOfOrigin?: AttributeLocalizedEnumValue;
  readonly customProduct?: string;
  readonly images: ImageBySize[];
  readonly ingredients?: string;
  readonly maximumPiecesPerOrder: number;
  readonly merchandisingCategory?: string;
  readonly otherVariations?: {
    key: VariationKey;
    label: string;
    variations: Omit<NutsProductVariant, 'otherVariations'>[];
  }[];
  readonly pieceCost?: TypedMoney;
  readonly productDiscountApplied?: DiscountedPricesByChannel;
  readonly reportingCategory?: string;
  readonly sku: string;
  readonly storageRequirements?: AttributeLocalizedEnumValue;
  readonly titleImage: ImageBySize | null;
  readonly unitName: string | null;
  readonly urlName: string | null;
}

export interface NutsProduct extends Pick<ProductData, 'metaDescription' | 'name'> {
  readonly ancestors: Ancestor[];
  readonly contentList?: string;
  readonly customPageTitle?: LocalizedString;
  readonly descriptionHtml: string;
  readonly healthTipsHtml?: string;
  readonly hiddenFromSearch?: boolean;
  readonly images: ImageBySize[];
  readonly key: string;
  readonly oneLinerDescription?: string;
  readonly offerGiftAddOns?: boolean;
  readonly productDiscountApplied: boolean;
  readonly testDescriptionHtml: string;
  readonly titleImage: ImageBySize | null;
  readonly upsellingProducts?: RelatedProduct[];
  readonly variantGroups: NutsProductVariant[];
  readonly variants: NutsProductVariant[];
}

export interface PurchaseOptions {
  autoDelivery?: Channel & {
    frequency: number;
  };
  markedAsGift: boolean;
  quantity: number;
  sku: string;
  variation: number;
}

export interface PredictedShipping {
  date: string;
  expiresAt: number;
  expiresInSeconds?: number;
  friendly?: 'today' | 'tomorrow';
}

const getAncestors = ({
  categories,
  masterVariant: { attributesRaw },
}: ProductData): Ancestor[] => {
  const primaryCategoryId = attributesRaw.find((a) => a.name === 'primaryCategory')?.value?.id;
  const primaryCategory = categories.find((c) => c.id === primaryCategoryId);
  if (!primaryCategory) return [];

  const { id, name, ancestors, custom } = primaryCategory;
  let url = '/';
  return [...ancestors, { id, name, custom }]
    .filter((ancestor) => ancestor.name !== 'Nuts.com Categories')
    .map((ancestor) => {
      const categoryUrl = ancestor.custom?.customFieldsRaw?.find(
        (field) => field.name === 'urlName',
      )?.value;
      if (categoryUrl) url += `${categoryUrl}/`;
      return { id: ancestor.id, name: ancestor.name, url };
    });
};

export const getPrice = (variant: NutsProductVariant, distributionChannelKey?: string) => {
  const { prices } = variant;

  const listPrice = prices.find((p) => !p.channel)!;

  if (!distributionChannelKey || variant.wholesale || !variant.autoDeliveryEligible)
    return listPrice;

  return prices.find((p) => p.channel?.key === distributionChannelKey) ?? listPrice;
};

const groupBy = <T, K>(list: T[], keyGetter: (value: T) => K) => {
  const map = new Map<K, T[]>();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

export const groupVariants = (variants: NutsProductVariant[]): NutsProductVariant[] => {
  const variantGroups: NutsProductVariant[] = [];
  const groupedByWeight = groupBy(variants, (variant) => variant.weight);

  groupedByWeight.forEach((list) => {
    const grindSizeVariation: Omit<NutsProductVariant, 'otherVariations'>[] = [];
    const tinColorVariation: Omit<NutsProductVariant, 'otherVariations'>[] = [];
    const otherVariations: NutsProductVariant['otherVariations'] = [];

    const groupedByGrindSize = groupBy(list, ({ grindSize }) => grindSize?.key);
    groupedByGrindSize.forEach((gridSizeItems, key) => {
      if (key && key !== 'undefined') {
        grindSizeVariation.push(gridSizeItems[0]);
      }
    });
    if (grindSizeVariation.length > 0) {
      otherVariations.push({
        key: 'grindSize',
        label: 'Grind Size',
        variations: grindSizeVariation,
      });
    }

    const groupedByTinColor = groupBy(list, ({ tinColor }) => tinColor?.key);
    groupedByTinColor.forEach((tinColorItems, key) => {
      if (key && key !== 'undefined') {
        tinColorVariation.push(tinColorItems[0]);
      }
    });
    if (tinColorVariation.length > 0) {
      otherVariations.push({
        key: 'tinColor',
        label: 'Tin Color',
        variations: tinColorVariation,
      });
    }

    variantGroups.push({
      ...list[0],
      backordered: list.every(({ backordered }) => backordered),
      otherVariations,
    });
  });

  return variantGroups;
};

const findAttributeByName = (attributes: Attribute[] | undefined, name: string) =>
  attributes ? attributes.find((attribute) => attribute.name === name) : undefined;

/**
 * Gets the unit name shortened and singularized.
 */
export const getUnitName = (variantName?: LocalizedString, locale = 'en') => {
  if (!variantName) return null;
  let replacementCount = 0;

  const name = variantName[locale]
    .replace(/ ?\([^(]+\)$/, '')
    .toLocaleLowerCase()
    // first try "cases of 24 cans" => "case of 24 cans"
    .replace(/^(jars|boxes|cases|containers|packs|packages|tins)(?= of )/, (match) => {
      replacementCount += 1;
      return singularize(match);
    });
  if (replacementCount) return name;
  // if it looks like "12 x 3 ounce bags", it works as singular or plural so leave it
  if (/^\d+ *x /.test(name)) return name;

  // if that didn't find anything, then try to singularize a trailing noun
  const unitName = name.replace(
    /(?:^| )(bags|blocks|boxes|cases|containers|jars|mixes|packages|packs|pies|samples|tins|trays|bars)$/,
    (match) => singularize(match),
  );

  return unitName.replace(' ounce', 'oz').replace(' pound', 'lb');
};

export const NutsProduct = {
  fromCT: (product: Product): NutsProduct => {
    const {
      key,
      masterData: {
        current: {
          contentList,
          description,
          healthTips,
          masterVariant,
          metaDescription,
          testDescription,
          variants,
        },
      },
    } = product;

    const variantList = [masterVariant, ...variants]
      .map((v): NutsProductVariant => {
        const attributes = pivotAttributeValues(v.attributesRaw).variant;
        const containsProductDiscounts = v.prices.some((p) => p.discounted);
        const variantImages = proxiedImageUtil.getVariants(v.images) ?? [];
        return {
          ...v,
          countPerPound: findAttributeByName(v.attributesRaw, 'countPerPound')?.value,
          countryOfOrigin: findAttributeByName(v.attributesRaw, 'countryOfOrigin')?.value,
          customProduct: findAttributeByName(v.attributesRaw, 'customProduct')?.value.key,
          images: variantImages,
          ingredients: findAttributeByName(v.attributesRaw, 'ingredients')?.value?.en,
          maximumPiecesPerOrder:
            findAttributeByName(v.attributesRaw, 'maximumPiecesPerOrder')?.value ?? MAX_QUANTITY,
          merchandisingCategory: v.attributesRaw?.find((a) => a.name === 'merchandisingCategory')
            ?.value.key,
          otherVariations: [],
          pieceCost: findAttributeByName(v.attributesRaw, 'pieceCost')?.value,
          productDiscountApplied: containsProductDiscounts
            ? v.prices.reduce<DiscountedPricesByChannel>((discountsByChannel, price) => {
                if (!price.discounted) return discountsByChannel;
                const listPrice = v.prices.find((p) => !p.channel)!;
                const { description: discountDescription } = price.discounted.discount;
                const productDiscountApplied = {
                  ...computeSavings(listPrice.value, price.discounted.value),
                  description: discountDescription ? { en: discountDescription } : undefined,
                };
                return {
                  ...discountsByChannel,
                  [price.channel?.key ?? 'default']: productDiscountApplied,
                };
              }, {})
            : undefined,
          reportingCategory: v.attributesRaw?.find((a) => a.name === 'reportingCategory')?.value
            .key,
          sku: v.sku ?? '',
          storageRequirements: findAttributeByName(v.attributesRaw, 'storageRequirements')?.value,
          titleImage: variantImages[0] ?? null,
          unitName: getUnitName(attributes.variantName),
          urlName: findAttributeByName(v.attributesRaw, 'urlName')?.value,
          ...attributes,
        };
      })
      .filter((v) => v.active && v.variantName?.en !== 'Mini Boxes');

    const variantGroups = groupVariants(variantList);
    const isGiftCertificate = isGiftCertificateUtil(variantGroups);
    const giftVariants: NutsProductVariant[] = GIFT_VARIANTS.map((item) => ({
      ...variantGroups[0],
      prices: [{ id: `${item.variation}`, value: from(item.amount) }],
      id: item.variation,
    }));

    const productImages = proxiedImageUtil.getVariants(masterVariant.images) ?? [];

    return {
      ancestors: getAncestors(product.masterData.current),
      contentList: contentList ?? '',
      customPageTitle: masterVariant.attributesRaw?.find((a) => a.name === 'customPageTitle')
        ?.value,
      descriptionHtml: description ?? '',
      healthTipsHtml: healthTips,
      hiddenFromSearch:
        findAttributeByName(masterVariant.attributesRaw, 'hiddenFromSearch')?.value ?? false,
      images: productImages,
      key: key ?? '',
      metaDescription,
      name: product.masterData.current.name,
      testDescriptionHtml: testDescription ?? '',
      offerGiftAddOns: masterVariant.attributesRaw?.find((a) => a.name === 'offerGiftAddOns')
        ?.value,
      oneLinerDescription: masterVariant.attributesRaw?.find(
        (a) => a.name === 'oneLinerDescription',
      )?.value.en,
      productDiscountApplied: (isGiftCertificate ? giftVariants : variantList).some(
        (v) => !!v.productDiscountApplied,
      ),
      titleImage: productImages[0] ?? null,
      upsellingProducts: masterVariant.attributesRawRelatedProducts?.find(
        (a) => a.name === 'upsellingProducts',
      )?.referencedResourceSet,
      variantGroups: isGiftCertificate ? giftVariants : variantGroups,
      variants: isGiftCertificate ? giftVariants : variantList,
    };
  },
};

export const isCustomizableProduct = ({ variantGroups }: NutsProduct) => {
  const variant = variantGroups[0];
  return Boolean(variant && 'otherVariations' in variant && variant.otherVariations?.length);
};

export const isGlutenFree = (productTagReferences?: TagReference[]) =>
  !!productTagReferences?.find(({ name }) => name.includes('gluten-free'));

export const isPhysical = (variant?: NutsProductVariant) => (variant ? variant.weight > 0 : false);

export const isActive = (variant: ProductVariant) => {
  const attribute = findAttributeByName(variant.attributes, 'active');
  return attribute?.value === true;
};

export const isInStock = (variant: ProductVariant) => {
  const attribute = findAttributeByName(variant.attributes, 'backordered');
  return attribute?.value.key === 'no';
};

interface GetMoneyValueOptions {
  /**
   * If true, product discounts will be ignored. Use with caution: it will check for price tier value.
   */
  ignoreDiscounted?: boolean;
}
export const getMoneyValue = (
  price: Price | NutsProductVariant['prices'][number] | PriceFragment | VariantPrice,
  quantity: number,
  options?: GetMoneyValueOptions,
) => {
  const absoluteQuantity = Math.max(quantity, 1);
  let moneyValue = price.value;

  if (price.discounted && !options?.ignoreDiscounted) {
    moneyValue = price.discounted.value;
  } else if (price.tiers) {
    const tiers = [{ minimumQuantity: 1, value: price.value }].concat(price.tiers);
    const tier = tiers.filter(({ minimumQuantity }) => minimumQuantity <= absoluteQuantity).pop()!;
    moneyValue = tier.value;
  }

  return moneyValue;
};

export const priceForQuantity = (
  variant: NutsProductVariant,
  selections: { quantity: number; autoDelivery?: { key: string } },
  perPound = false,
) => {
  const price = getPrice(variant, selections.autoDelivery?.key);
  const moneyValue = getMoneyValue(price, selections.quantity);

  return perPound ? Money.multiply(moneyValue, 1 / variant.weight) : moneyValue;
};

export const isIndividuallyWrapped = (
  variant: Pick<NutsProductVariant, 'bulk' | 'variantName' | 'wholesale'>,
) => {
  if (!(variant.bulk && variant.wholesale)) return true;
  return variant.variantName?.en?.toLowerCase().includes('individually wrapped') || false;
};

export const buildPostalCodeShippingCalculatorRequest = (
  items: { quantity: number; sku: string; weight: number }[],
  address: { country?: string; postalCode: string },
  totalPrice: TypedMoney,
  { regionalCarriersAllowed = true } = {},
): ShippingCalculatorRequest => ({
  shipments: [
    {
      address: {
        country: address.country ?? 'US',
        residential: true,
        postalCode: address.postalCode,
      },
      key: 'Address 1',
      lines: items,
      shipmentValue: totalPrice,
    },
  ],
  allowBlueStreak: Boolean(regionalCarriersAllowed),
  allowCdl: Boolean(regionalCarriersAllowed),
  allowGrandHusky: Boolean(regionalCarriersAllowed),
  allowLasership: Boolean(regionalCarriersAllowed),
  allowOntrac: Boolean(regionalCarriersAllowed),
  allowTforce: Boolean(regionalCarriersAllowed),
  allowUds: Boolean(regionalCarriersAllowed),
});

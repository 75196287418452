/**
 * This client was automatically generated by RudderTyper. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 *
 * In order to support run-time validation of draft-04 JSON Schema we are using `ajv-draft-04`.
 * You can install it with: `npm install --save-dev ajv-draft-04`.
 *
 */
import Ajv, { ErrorObject } from 'ajv'
import AjvDraft4 from 'ajv-draft-04'
import * as Rudder from './rudder'
type apiCallback = (data?: any) => void

export interface ProductAdded {
  /**
   * Cart ID the product was added to (a UUID)
   */
  cart_id?: string
  /**
   * Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
   */
  category?: string
  /**
   * Coupon code associated with the variant
   */
  coupon?: string
  /**
   * Full URL of image (variant-specific if possible)
   */
  image_url?: string
  /**
   * Name of the product
   */
  name: string
  position?: number
  /**
   * Single-piece price of variant
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Number of pieces added in this operation
   */
  quantity: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Full URL of PDP (variant-specific if possible)
   */
  url?: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}
export interface ProductPurchased {
  /**
   * Brand of the product
   */
  brand?: string
  /**
   * Cart ID associated with the product displayed
   */
  cart_id?: string
  /**
   * Product category being viewed
   */
  category?: string
  /**
   * Coupon code associated with the product
   */
  coupon?: string
  /**
   * Image url of the product
   */
  image_url?: string
  /**
   * Name of the product
   */
  name?: string
  /**
   * Position of product in the list
   */
  position?: number
  /**
   * Price of the product
   */
  price: number
  /**
   * Database ID of the product
   */
  product_id: string
  /**
   * Quantity of the product
   */
  quantity?: number
  /**
   * Sku of the product
   */
  sku?: string
  /**
   * URL of the product page for product
   */
  url?: string
  /**
   * Variant of the product
   */
  variant?: string
}
export interface ProductViewed {
  cart_id?: string
  /**
   * Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
   */
  category?: string
  coupon?: string
  currency?: string
  /**
   * Full URL of image (variant-specific if possible)
   */
  image_url?: string
  /**
   * Name of the product
   */
  name: string
  /**
   * Single-piece price of variant
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  quantity?: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Full URL of PDP (variant-specific if possible)
   */
  url?: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}
export interface ProductListViewedProductsItem {
  /**
   * Name of the brand associated with the product
   */
  brand?: string
  /**
   * Category of the product being viewed
   */
  category?: string
  /**
   * Coupon code associated with a product
   */
  coupon?: string
  /**
   * Full URL of image (variant-specific if possible)
   */
  image_url?: string
  /**
   * Name of the product being viewed
   */
  name: string
  /**
   * Position of the product in the product list
   */
  position: number
  /**
   * Price of the product being viewed (in USD)
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Quantity of the product
   */
  quantity: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Full URL of PDP (variant-specific if possible)
   */
  url?: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}
export interface ProductListViewed {
  /**
   * Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
   */
  category?: string
  /**
   * Name of the product list being viewed
   */
  list_id?: string
  /**
   * Array of products displayed in the product list
   */
  products?: ProductListViewedProductsItem[]
}
export interface CheckoutStepViewed {
  /**
   * Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)
   */
  checkout_id?: string
  /**
   * Payment method
   */
  payment_method?: string
  /**
   * Chosen shipping method
   */
  shipping_method?: string
  /**
   * Checkout process step number
   */
  step?: number
}
export interface PaymentInfoEntered {
  /**
   * Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)
   */
  checkout_id?: string
  /**
   * Order ID, optionsal
   */
  order_id?: string
  /**
   * Payment method
   */
  payment_method?: string
  /**
   * Chosen shipping method
   */
  shipping_method?: string
  /**
   * Checkout process step number
   */
  step?: number
}
export interface CheckoutStepCompleted {
  /**
   * Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)
   */
  checkout_id?: string
  /**
   * Payment method
   */
  payment_method?: string
  /**
   * Chosen shipping method
   */
  shipping_method?: string
  /**
   * Chosen shipping option as presented to the user (e.g. "Ground", "Free Shipping", or "Two Business Days")
   */
  shipping_option?: string
  /**
   * Checkout process step number
   */
  step?: number
  /**
   * Gross Total
   */
  total: number
}
export interface ProductAddedToWishlist {
  /**
   * Name of the brand associated with the product
   */
  brand?: string
  /**
   * Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
   */
  category?: string
  /**
   * Coupon code associated with the product
   */
  coupon?: string
  /**
   * Full URL of image (variant-specific if possible)
   */
  image_url?: string
  /**
   * Name of the product
   */
  name?: string
  /**
   * Position of the product in the product list
   */
  position?: number
  /**
   * Price of the product (in USD)
   */
  price?: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id?: string
  /**
   * Quantity of the product
   */
  quantity?: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku?: string
  /**
   * Full URL of PDP (variant-specific if possible)
   */
  url?: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
  /**
   * Wishlist ID
   */
  wishlist_id?: string
  /**
   * Name of the wishlist the product was added to
   */
  wishlist_name?: string
}
export interface ProductClicked {
  /**
   * Name of the brand associated with the product
   */
  brand?: string
  /**
   * Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
   */
  category?: string
  /**
   * Coupon code associated with a product
   */
  coupon?: string
  /**
   * Full URL of image (variant-specific if possible)
   */
  image_url?: string
  /**
   * Name of the product being viewed
   */
  name: string
  /**
   * Position of the product in the product list
   */
  position?: number
  /**
   * Price of the product being viewed
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Quantity of the product
   */
  quantity?: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Full URL of PDP (variant-specific if possible)
   */
  url?: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}
export interface ProductsSearched {
  /**
   * Query searched by the user
   */
  query?: string
}
export interface ProductListFilteredFiltersItem {
  /**
   * ID of the filter type that the customer is using
   */
  type?: string
  /**
   * ID of the selection chosen by the customer
   */
  value?: string
}
export interface ProductListFilteredProductsItem {
  /**
   * Brand name associated with the product
   */
  brand?: string
  /**
   * Product category viewed by the customer
   */
  category?: string
  /**
   * Coupon code associated with a product
   */
  coupon?: string
  /**
   * Full URL of image (variant-specific if possible)
   */
  image_url?: string
  /**
   * Name of the product being viewed by the user
   */
  name: string
  /**
   * Position of the product in the product list
   */
  position: number
  /**
   * Price of the product being viewed (in USD)
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Quantity of a product
   */
  quantity: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Full URL of PDP (variant-specific if possible)
   */
  url?: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}
export interface ProductListFilteredSortsItem {
  /**
   * ID of the sort type used by the customer
   */
  type?: string
  /**
   * ID of the selection-type the customer is using
   */
  value?: string
}
export interface ProductListFiltered {
  /**
   * Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
   */
  category?: string
  /**
   * Product filters that the customer has applied
   */
  filters?: ProductListFilteredFiltersItem[]
  /**
   * Name of the product list being viewed
   */
  list_id?: string
  /**
   * Products displayed in the product list
   */
  products?: ProductListFilteredProductsItem[]
  /**
   * Product sorting used by the customer
   */
  sorts?: ProductListFilteredSortsItem[]
}
export interface CartViewedProductsItem {
  /**
   * Name of the brand associated with the product
   */
  brand?: string
  /**
   * Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
   */
  category?: string
  /**
   * Coupon code associated with a product
   */
  coupon?: string
  /**
   * Full URL of image (variant-specific if possible)
   */
  image_url?: string
  /**
   * Name of the product being viewed
   */
  name: string
  /**
   * Position of the product in the product list
   */
  position?: number
  /**
   * Price of the product being viewed
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Quantity of the product
   */
  quantity: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Full URL of PDP (variant-specific if possible)
   */
  url?: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}
export interface CartViewed {
  /**
   * Cart ID of the cart being viewed (a UUID)
   */
  cart_id?: string
  /**
   * List of products displayed in the product list
   */
  products?: CartViewedProductsItem[]
  /**
   * Gross Total
   */
  total: number
}
export interface CheckoutStartedProductsItem {
  /**
   * Name of the brand associated with the product
   */
  brand?: string
  /**
   * Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
   */
  category?: string
  /**
   * Coupon code associated with a product
   */
  coupon?: string
  /**
   * Full URL of image (variant-specific if possible)
   */
  image_url?: string
  /**
   * Mame of the product being viewed
   */
  name: string
  /**
   * Position of the product in the product list
   */
  position?: number
  /**
   * Price of the product being viewed
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Quantity of the product
   */
  quantity: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Full URL of PDP (variant-specific if possible)
   */
  url?: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}
export interface CheckoutStarted {
  /**
   * Store or affiliation details from where the transaction occured
   */
  affiliation?: string
  /**
   * Coupon redeemed with the transaction
   */
  coupon?: string
  /**
   * Currency code associated with an order or transaction
   */
  currency?: string
  /**
   * Total discount associated with the transaction
   */
  discount?: number
  /**
   * Order ID or transaction ID, whichever is applicable
   */
  order_id?: string
  /**
   * List of products in the order or transaction
   */
  products?: CheckoutStartedProductsItem[]
  /**
   * Revenue associated with the transaction, excluding the shipping and tax details
   */
  revenue?: number
  /**
   * Shipping cost associated with the order or transaction
   */
  shipping?: number
  /**
   * Total tax associated with the order or the transaction
   */
  tax?: number
  /**
   * Revenue with discount and coupons factored in
   */
  value?: number
}
export interface ProductRemoved {
  /**
   * Name of the brand associated with the product
   */
  brand?: string
  /**
   * Cart ID the product was removed from (a UUID)
   */
  cart_id?: string
  /**
   * Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
   */
  category?: string
  /**
   * Coupon code associated with a product
   */
  coupon?: string
  /**
   * Full URL of image (variant-specific if possible)
   */
  image_url?: string
  /**
   * Name of the product being viewed
   */
  name: string
  /**
   * Position of the product in the product list
   */
  position?: number
  /**
   * Price of the product being viewed
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Quantity of the product
   */
  quantity: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Full URL of PDP (variant-specific if possible)
   */
  url?: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}
export interface OrderCompletedProductsItem {
  /**
   * Name of the brand associated with the product
   */
  brand?: string
  /**
   * Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
   */
  category?: string
  /**
   * Coupon code associated with a product
   */
  coupon?: string
  /**
   * Full URL of image (variant-specific if possible)
   */
  image_url?: string
  /**
   * Name of the product being viewed
   */
  name: string
  /**
   * Position of the product in the product list
   */
  position?: number
  /**
   * Price of the product being viewed
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Quantity of the product
   */
  quantity: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Full URL of PDP (variant-specific if possible)
   */
  url?: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}
export interface OrderCompleted {
  /**
   * Store or affiliation details from where the transaction was started
   */
  affiliation?: string
  /**
   * Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)
   */
  checkout_id?: string
  /**
   * Coupon redeemed with the transaction
   */
  coupon?: string
  /**
   * Currency code associated with an order or transaction
   */
  currency?: string
  /**
   * Total discount associated with the transaction
   */
  discount?: number
  /**
   * Order ID or transaction ID
   */
  order_id: string
  /**
   * List of products in the order or transaction
   */
  products?: OrderCompletedProductsItem[]
  /**
   * Revenue associated with the transaction
   */
  revenue?: number
  /**
   * Shipping cost associated with the order or transaction
   */
  shipping?: number
  /**
   * Order total after discounts but not including the taxes and shipping charges
   */
  subtotal?: number
  /**
   * Total tax associated with the order or the transaction
   */
  tax?: number
  /**
   * Revenue with the discount and coupons factored in
   */
  total?: number
}
export interface OrderCancelledProductsItem {
  /**
   * Name of the brand associated with the product
   */
  brand?: string
  /**
   * Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
   */
  category?: string
  /**
   * Coupon code associated with a product
   */
  coupon?: string
  /**
   * Full URL of image (variant-specific if possible)
   */
  image_url?: string
  /**
   * Name of the product being viewed
   */
  name: string
  /**
   * Position of the product in the product list
   */
  position?: number
  /**
   * Price of the product being viewed
   */
  price: number
  /**
   * Product key (4 digits, e.g. 4003)
   */
  product_id: string
  /**
   * Quantity of the product
   */
  quantity: number
  /**
   * Name of reporting category for a product
   */
  reporting_category?: string
  /**
   * Variant sku (6 digits, e.g. 4003-05)
   */
  sku: string
  /**
   * Full URL of PDP (variant-specific if possible)
   */
  url?: string
  /**
   * Short variant name (e.g. 5lb bag)
   */
  variant?: string
}
export interface OrderCancelled {
  /**
   * Store or affiliation details from where the transaction was started
   */
  affiliation?: string
  /**
   * Coupon which can be redeemed with the transaction
   */
  coupon?: string
  /**
   * Currency code associated with an order or transaction
   */
  currency?: string
  /**
   * Total discount associated with the transaction
   */
  discount?: number
  /**
   * Order ID or transaction ID, whichever is applicable
   */
  order_id: string
  /**
   * List of products in the order or transaction
   */
  products?: OrderCancelledProductsItem[]
  /**
   * Revenue associated with the transaction
   */
  revenue?: number
  /**
   * Shipping cost associated with the order or transaction
   */
  shipping?: number
  /**
   * Total tax associated with the order or the transaction
   */
  tax?: number
  /**
   * Revenue with the discount and coupons factored in
   */
  total?: number
}
export interface ExperimentViewed {
  /**
   * Identifier of the testing platform top-level grouping; optional because the CMS and search engine do not use such a grouping and just serve a single experiment for the requested content.
   */
  campaign_id?: string
  /**
   * Name of the testing platform top-level grouping.
   */
  campaign_name?: string
  /**
   * Identifier of the direct test being run. For DY this would be the group that contains variations and declares general targeting criteria; in the CMS it will reflect the ID of the content entry; and search engine will reflect the ID of the test applied to results.
   */
  experiment_id: string
  /**
   * Name of the direct test being run. For the CMS it will reflect the name of the content entry; while the search engine will reflect the name of the test as displayed in the dashboard.
   */
  experiment_name?: string
  /**
   * Identifier of the variation served. May point to the control; for the CMS the control would match the experiment_id value.
   */
  variation_id: string
  /**
   * Name of the variation served. May point to the control; for the CMS the control may either match the experiment_name value or state Default.
   */
  variation_name?: string
}

export type ViolationHandler = (
  message: Record<string, any>,
  violations: ErrorObject[]
) => void

/**
 * The default handler that is fired if none is supplied with setRudderTyperOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
  message,
  violations
) => {
  const msg = JSON.stringify(
    {
      type: 'RudderTyper JSON Schema Validation Error',
      description:
        `You made an analytics call (${message.event}) using RudderTyper that doesn't match the ` +
        'Tracking Plan spec.',
      errors: violations,
    },
    undefined,
    2
  )

  console.warn(msg)
}

let onViolation = defaultValidationErrorHandler

let analytics: () => any | undefined = () => {
  return window.rudderanalytics
}

/** Options to customize the runtime behavior of a RudderTyper client. */
export interface RudderTyperOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.rudderanalytics.
   */
  analytics?: any
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this RudderTyper client.
 *
 * @param {RudderTyperOptions} options - the options to upsert
 *
 * @typedef {Object} RudderTyperOptions
 * @property {Rudder.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setRudderTyperOptions(options: RudderTyperOptions) {
  analytics = options.analytics
    ? () => options.analytics || window.rudderanalytics
    : analytics
  onViolation = options.onViolation || onViolation
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
async function validateAgainstSchema(
  message: Record<string, any>,
  schema: Rudder.Schema
) {
  let ajv
  if (schema['$schema'] && schema['$schema'].includes('draft-04')) {
    ajv = new AjvDraft4({
      allErrors: true,
      verbose: true,
    })
  } else {
    ajv = new Ajv({
      allErrors: true,
      verbose: true,
    })
    const schemaDraft06 = await import('ajv/lib/refs/json-schema-draft-06.json')
    ajv.addMetaSchema(schemaDraft06)
  }
  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors)
  }
}

/**
 * Helper to attach metadata on RudderTyper to outbound requests.
 * This is used for attribution and debugging by the RudderStack team.
 */
function withRudderTyperContext(message: Rudder.Options = {}): Rudder.Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      ruddertyper: {
        sdk: 'analytics.js',
        language: 'typescript',
        rudderTyperVersion: '1.0.0-beta.6',
        trackingPlanId: 'tp_2dxlF3tndjHVEe4qIgY3S0t6DNO',
        trackingPlanVersion: '47',
      },
    },
  }
}

/**
 * @typedef ProductAdded
 * @property {string} [cart_id] - Cart ID the product was added to (a UUID)
 * @property {string} [category] - Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
 * @property {string} [coupon] - Coupon code associated with the variant
 * @property {string} [image_url] - Full URL of image (variant-specific if possible)
 * @property {string} name - Name of the product
 * @property {number} [position] -
 * @property {number} price - Single-piece price of variant
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} quantity - Number of pieces added in this operation
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [url] - Full URL of PDP (variant-specific if possible)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef ProductPurchased
 * @property {string} [brand] - Brand of the product
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef ProductViewed
 * @property {string} [cart_id] -
 * @property {string} [category] - Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
 * @property {string} [coupon] -
 * @property {string} [currency] -
 * @property {string} [image_url] - Full URL of image (variant-specific if possible)
 * @property {string} name - Name of the product
 * @property {number} price - Single-piece price of variant
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} [quantity] -
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [url] - Full URL of PDP (variant-specific if possible)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef ProductListViewedProductsItem
 * @property {string} [brand] - Name of the brand associated with the product
 * @property {string} [category] - Category of the product being viewed
 * @property {string} [coupon] - Coupon code associated with a product
 * @property {string} [image_url] - Full URL of image (variant-specific if possible)
 * @property {string} name - Name of the product being viewed
 * @property {number} position - Position of the product in the product list
 * @property {number} price - Price of the product being viewed (in USD)
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} quantity - Quantity of the product
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [url] - Full URL of PDP (variant-specific if possible)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef ProductListViewed
 * @property {string} [category] - Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
 * @property {string} [list_id] - Name of the product list being viewed
 * @property {ProductListViewedProductsItem[]} [products] - Array of products displayed in the product list
 */
/**
 * @typedef CheckoutStepViewed
 * @property {string} [checkout_id] - Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)
 * @property {string} [payment_method] - Payment method
 * @property {string} [shipping_method] - Chosen shipping method
 * @property {number} [step] - Checkout process step number
 */
/**
 * @typedef PaymentInfoEntered
 * @property {string} [checkout_id] - Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)
 * @property {string} [order_id] - Order ID, optionsal
 * @property {string} [payment_method] - Payment method
 * @property {string} [shipping_method] - Chosen shipping method
 * @property {number} [step] - Checkout process step number
 */
/**
 * @typedef CheckoutStepCompleted
 * @property {string} [checkout_id] - Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)
 * @property {string} [payment_method] - Payment method
 * @property {string} [shipping_method] - Chosen shipping method
 * @property {string} [shipping_option] - Chosen shipping option as presented to the user (e.g. "Ground", "Free Shipping", or "Two Business Days")
 * @property {number} [step] - Checkout process step number
 * @property {number} total - Gross Total
 */
/**
 * @typedef ProductAddedToWishlist
 * @property {string} [brand] - Name of the brand associated with the product
 * @property {string} [category] - Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Full URL of image (variant-specific if possible)
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of the product in the product list
 * @property {number} [price] - Price of the product (in USD)
 * @property {string} [product_id] - Product key (4 digits, e.g. 4003)
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} [sku] - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [url] - Full URL of PDP (variant-specific if possible)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 * @property {string} [wishlist_id] - Wishlist ID
 * @property {string} [wishlist_name] - Name of the wishlist the product was added to
 */
/**
 * @typedef ProductClicked
 * @property {string} [brand] - Name of the brand associated with the product
 * @property {string} [category] - Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
 * @property {string} [coupon] - Coupon code associated with a product
 * @property {string} [image_url] - Full URL of image (variant-specific if possible)
 * @property {string} name - Name of the product being viewed
 * @property {number} [position] - Position of the product in the product list
 * @property {number} price - Price of the product being viewed
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [url] - Full URL of PDP (variant-specific if possible)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef ProductsSearched
 * @property {string} [query] - Query searched by the user
 */
/**
 * @typedef ProductListFilteredFiltersItem
 * @property {string} [type] - ID of the filter type that the customer is using
 * @property {string} [value] - ID of the selection chosen by the customer
 */
/**
 * @typedef ProductListFilteredProductsItem
 * @property {string} [brand] - Brand name associated with the product
 * @property {string} [category] - Product category viewed by the customer
 * @property {string} [coupon] - Coupon code associated with a product
 * @property {string} [image_url] - Full URL of image (variant-specific if possible)
 * @property {string} name - Name of the product being viewed by the user
 * @property {number} position - Position of the product in the product list
 * @property {number} price - Price of the product being viewed (in USD)
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} quantity - Quantity of a product
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [url] - Full URL of PDP (variant-specific if possible)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef ProductListFilteredSortsItem
 * @property {string} [type] - ID of the sort type used by the customer
 * @property {string} [value] - ID of the selection-type the customer is using
 */
/**
 * @typedef ProductListFiltered
 * @property {string} [category] - Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
 * @property {ProductListFilteredFiltersItem[]} [filters] - Product filters that the customer has applied
 * @property {string} [list_id] - Name of the product list being viewed
 * @property {ProductListFilteredProductsItem[]} [products] - Products displayed in the product list
 * @property {ProductListFilteredSortsItem[]} [sorts] - Product sorting used by the customer
 */
/**
 * @typedef CartViewedProductsItem
 * @property {string} [brand] - Name of the brand associated with the product
 * @property {string} [category] - Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
 * @property {string} [coupon] - Coupon code associated with a product
 * @property {string} [image_url] - Full URL of image (variant-specific if possible)
 * @property {string} name - Name of the product being viewed
 * @property {number} [position] - Position of the product in the product list
 * @property {number} price - Price of the product being viewed
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} quantity - Quantity of the product
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [url] - Full URL of PDP (variant-specific if possible)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef CartViewed
 * @property {string} [cart_id] - Cart ID of the cart being viewed (a UUID)
 * @property {CartViewedProductsItem[]} [products] - List of products displayed in the product list
 * @property {number} total - Gross Total
 */
/**
 * @typedef CheckoutStartedProductsItem
 * @property {string} [brand] - Name of the brand associated with the product
 * @property {string} [category] - Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
 * @property {string} [coupon] - Coupon code associated with a product
 * @property {string} [image_url] - Full URL of image (variant-specific if possible)
 * @property {string} name - Mame of the product being viewed
 * @property {number} [position] - Position of the product in the product list
 * @property {number} price - Price of the product being viewed
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} quantity - Quantity of the product
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [url] - Full URL of PDP (variant-specific if possible)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef CheckoutStarted
 * @property {string} [affiliation] - Store or affiliation details from where the transaction occured
 * @property {string} [coupon] - Coupon redeemed with the transaction
 * @property {string} [currency] - Currency code associated with an order or transaction
 * @property {number} [discount] - Total discount associated with the transaction
 * @property {string} [order_id] - Order ID or transaction ID, whichever is applicable
 * @property {CheckoutStartedProductsItem[]} [products] - List of products in the order or transaction
 * @property {number} [revenue] - Revenue associated with the transaction, excluding the shipping and tax details
 * @property {number} [shipping] - Shipping cost associated with the order or transaction
 * @property {number} [tax] - Total tax associated with the order or the transaction
 * @property {number} [value] - Revenue with discount and coupons factored in
 */
/**
 * @typedef ProductRemoved
 * @property {string} [brand] - Name of the brand associated with the product
 * @property {string} [cart_id] - Cart ID the product was removed from (a UUID)
 * @property {string} [category] - Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
 * @property {string} [coupon] - Coupon code associated with a product
 * @property {string} [image_url] - Full URL of image (variant-specific if possible)
 * @property {string} name - Name of the product being viewed
 * @property {number} [position] - Position of the product in the product list
 * @property {number} price - Price of the product being viewed
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} quantity - Quantity of the product
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [url] - Full URL of PDP (variant-specific if possible)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef OrderCompletedProductsItem
 * @property {string} [brand] - Name of the brand associated with the product
 * @property {string} [category] - Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
 * @property {string} [coupon] - Coupon code associated with a product
 * @property {string} [image_url] - Full URL of image (variant-specific if possible)
 * @property {string} name - Name of the product being viewed
 * @property {number} [position] - Position of the product in the product list
 * @property {number} price - Price of the product being viewed
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} quantity - Quantity of the product
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [url] - Full URL of PDP (variant-specific if possible)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef OrderCompleted
 * @property {string} [affiliation] - Store or affiliation details from where the transaction was started
 * @property {string} [checkout_id] - Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)
 * @property {string} [coupon] - Coupon redeemed with the transaction
 * @property {string} [currency] - Currency code associated with an order or transaction
 * @property {number} [discount] - Total discount associated with the transaction
 * @property {string} order_id - Order ID or transaction ID
 * @property {OrderCompletedProductsItem[]} [products] - List of products in the order or transaction
 * @property {number} [revenue] - Revenue associated with the transaction
 * @property {number} [shipping] - Shipping cost associated with the order or transaction
 * @property {number} [subtotal] - Order total after discounts but not including the taxes and shipping charges
 * @property {number} [tax] - Total tax associated with the order or the transaction
 * @property {number} [total] - Revenue with the discount and coupons factored in
 */
/**
 * @typedef OrderCancelledProductsItem
 * @property {string} [brand] - Name of the brand associated with the product
 * @property {string} [category] - Primary Category, rendered hierarchically (e.g. Nuts > Almonds)
 * @property {string} [coupon] - Coupon code associated with a product
 * @property {string} [image_url] - Full URL of image (variant-specific if possible)
 * @property {string} name - Name of the product being viewed
 * @property {number} [position] - Position of the product in the product list
 * @property {number} price - Price of the product being viewed
 * @property {string} product_id - Product key (4 digits, e.g. 4003)
 * @property {number} quantity - Quantity of the product
 * @property {string} [reporting_category] - Name of reporting category for a product
 * @property {string} sku - Variant sku (6 digits, e.g. 4003-05)
 * @property {string} [url] - Full URL of PDP (variant-specific if possible)
 * @property {string} [variant] - Short variant name (e.g. 5lb bag)
 */
/**
 * @typedef OrderCancelled
 * @property {string} [affiliation] - Store or affiliation details from where the transaction was started
 * @property {string} [coupon] - Coupon which can be redeemed with the transaction
 * @property {string} [currency] - Currency code associated with an order or transaction
 * @property {number} [discount] - Total discount associated with the transaction
 * @property {string} order_id - Order ID or transaction ID, whichever is applicable
 * @property {OrderCancelledProductsItem[]} [products] - List of products in the order or transaction
 * @property {number} [revenue] - Revenue associated with the transaction
 * @property {number} [shipping] - Shipping cost associated with the order or transaction
 * @property {number} [tax] - Total tax associated with the order or the transaction
 * @property {number} [total] - Revenue with the discount and coupons factored in
 */
/**
 * @typedef ExperimentViewed
 * @property {string} [campaign_id] - Identifier of the testing platform top-level grouping; optional because the CMS and search engine do not use such a grouping and just serve a single experiment for the requested content.
 * @property {string} [campaign_name] - Name of the testing platform top-level grouping.
 * @property {string} experiment_id - Identifier of the direct test being run. For DY this would be the group that contains variations and declares general targeting criteria; in the CMS it will reflect the ID of the content entry; and search engine will reflect the ID of the test applied to results.
 * @property {string} [experiment_name] - Name of the direct test being run. For the CMS it will reflect the name of the content entry; while the search engine will reflect the name of the test as displayed in the dashboard.
 * @property {string} variation_id - Identifier of the variation served. May point to the control; for the CMS the control would match the experiment_id value.
 * @property {string} [variation_name] - Name of the variation served. May point to the control; for the CMS the control may either match the experiment_name value or state Default.
 */

/**
 * Not yet provided
 *
 * @param {ProductAdded} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productAdded(
  props: ProductAdded,
  options?: Rudder.Options,
  callback?: apiCallback
): void {
  const a = analytics()
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Not yet provided',
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          cart_id: {
            description: 'Cart ID the product was added to (a UUID)',
            type: ['string'],
          },
          category: {
            description:
              'Primary Category, rendered hierarchically (e.g. Nuts > Almonds)',
            type: ['string'],
          },
          coupon: {
            description: 'Coupon code associated with the variant',
            type: ['string'],
          },
          image_url: {
            description: 'Full URL of image (variant-specific if possible)',
            type: ['string'],
          },
          name: {
            description: 'Name of the product',
            type: ['string'],
          },
          position: {
            type: ['number'],
          },
          price: {
            description: 'Single-piece price of variant',
            type: ['number'],
          },
          product_id: {
            description: 'Product key (4 digits, e.g. 4003)',
            type: ['string'],
          },
          quantity: {
            description: 'Number of pieces added in this operation',
            type: ['number'],
          },
          reporting_category: {
            description: 'Name of reporting category for a product',
            type: ['string'],
          },
          sku: {
            description: 'Variant sku (6 digits, e.g. 4003-05)',
            type: ['string'],
          },
          url: {
            description: 'Full URL of PDP (variant-specific if possible)',
            type: ['string'],
          },
          variant: {
            description: 'Short variant name (e.g. 5lb bag)',
            type: ['string'],
          },
        },
        required: ['name', 'price', 'product_id', 'quantity', 'sku'],
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Product Added',
    type: 'object',
  }
  const message = {
    event: 'Product Added',
    properties: props || {},
    options,
  }
  validateAgainstSchema(message, schema).then(() => {
    if (a) {
      a.track(
        'Product Added',
        props || {},
        withRudderTyperContext(options),
        callback
      )
    }
  })
}
/**
 * User added a product to their shopping cart
 *
 * @param {ProductPurchased} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productPurchased(
  props: ProductPurchased,
  options?: Rudder.Options,
  callback?: apiCallback
): void {
  const a = analytics()
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User added a product to their shopping cart',
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          brand: {
            description: 'Brand of the product',
            type: ['string'],
          },
          cart_id: {
            description: 'Cart ID associated with the product displayed',
            type: ['string'],
          },
          category: {
            description: 'Product category being viewed',
            type: ['string'],
          },
          coupon: {
            description: 'Coupon code associated with the product',
            type: ['string'],
          },
          image_url: {
            description: 'Image url of the product',
            type: ['string'],
          },
          name: {
            description: 'Name of the product',
            type: ['string'],
          },
          position: {
            description: 'Position of product in the list',
            type: ['integer'],
          },
          price: {
            description: 'Price of the product',
            type: ['number'],
          },
          product_id: {
            description: 'Database ID of the product',
            type: ['string'],
          },
          quantity: {
            description: 'Quantity of the product',
            type: ['integer'],
          },
          sku: {
            description: 'Sku of the product',
            type: ['string'],
          },
          url: {
            description: 'URL of the product page for product',
            type: ['string'],
          },
          variant: {
            description: 'Variant of the product',
            type: ['string'],
          },
        },
        required: ['product_id', 'price'],
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Product Purchased',
    type: 'object',
  }
  const message = {
    event: 'Product Purchased',
    properties: props || {},
    options,
  }
  validateAgainstSchema(message, schema).then(() => {
    if (a) {
      a.track(
        'Product Purchased',
        props || {},
        withRudderTyperContext(options),
        callback
      )
    }
  })
}
/**
 * Not yet provided
 *
 * @param {ProductViewed} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productViewed(
  props: ProductViewed,
  options?: Rudder.Options,
  callback?: apiCallback
): void {
  const a = analytics()
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Not yet provided',
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          cart_id: {
            type: ['string'],
          },
          category: {
            description:
              'Primary Category, rendered hierarchically (e.g. Nuts > Almonds)',
            type: ['string'],
          },
          coupon: {
            type: ['string'],
          },
          currency: {
            type: ['string'],
          },
          image_url: {
            description: 'Full URL of image (variant-specific if possible)',
            type: ['string'],
          },
          name: {
            description: 'Name of the product',
            type: ['string'],
          },
          price: {
            description: 'Single-piece price of variant',
            type: ['number'],
          },
          product_id: {
            description: 'Product key (4 digits, e.g. 4003)',
            type: ['string'],
          },
          quantity: {
            type: ['number'],
          },
          reporting_category: {
            description: 'Name of reporting category for a product',
            type: ['string'],
          },
          sku: {
            description: 'Variant sku (6 digits, e.g. 4003-05)',
            type: ['string'],
          },
          url: {
            description: 'Full URL of PDP (variant-specific if possible)',
            type: ['string'],
          },
          variant: {
            description: 'Short variant name (e.g. 5lb bag)',
            type: ['string'],
          },
        },
        required: ['name', 'price', 'product_id', 'sku'],
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Product Viewed',
    type: 'object',
  }
  const message = {
    event: 'Product Viewed',
    properties: props || {},
    options,
  }
  validateAgainstSchema(message, schema).then(() => {
    if (a) {
      a.track(
        'Product Viewed',
        props || {},
        withRudderTyperContext(options),
        callback
      )
    }
  })
}
/**
 * This event is triggered when a visitor views a list or category of products on your website or app.
 *
 * @param {ProductListViewed} [props] - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productListViewed(
  props?: ProductListViewed,
  options?: Rudder.Options,
  callback?: apiCallback
): void {
  const a = analytics()
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is triggered when a visitor views a list or category of products on your website or app.',
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          category: {
            description:
              'Primary Category, rendered hierarchically (e.g. Nuts > Almonds)',
            type: ['string'],
          },
          list_id: {
            description: 'Name of the product list being viewed',
            type: ['string'],
          },
          products: {
            description: 'Array of products displayed in the product list',
            items: {
              additionalProperties: false,
              properties: {
                brand: {
                  description: 'Name of the brand associated with the product',
                  type: ['string'],
                },
                category: {
                  description: 'Category of the product being viewed',
                  type: ['string'],
                },
                coupon: {
                  description: 'Coupon code associated with a product',
                  type: ['string'],
                },
                image_url: {
                  description:
                    'Full URL of image (variant-specific if possible)',
                  type: ['string'],
                },
                name: {
                  description: 'Name of the product being viewed',
                  type: ['string'],
                },
                position: {
                  description: 'Position of the product in the product list',
                  type: ['number'],
                },
                price: {
                  description: 'Price of the product being viewed (in USD)',
                  type: ['number'],
                },
                product_id: {
                  description: 'Product key (4 digits, e.g. 4003)',
                  type: ['string'],
                },
                quantity: {
                  description: 'Quantity of the product',
                  type: ['number'],
                },
                reporting_category: {
                  description: 'Name of reporting category for a product',
                  type: ['string'],
                },
                sku: {
                  description: 'Variant sku (6 digits, e.g. 4003-05)',
                  type: ['string'],
                },
                url: {
                  description: 'Full URL of PDP (variant-specific if possible)',
                  type: ['string'],
                },
                variant: {
                  description: 'Short variant name (e.g. 5lb bag)',
                  type: ['string'],
                },
              },
              required: [
                'name',
                'position',
                'price',
                'product_id',
                'quantity',
                'sku',
              ],
              type: ['object'],
            },
            type: ['array'],
          },
        },
        required: [],
        type: 'object',
      },
    },
    title: 'Product List Viewed',
    type: 'object',
  }
  const message = {
    event: 'Product List Viewed',
    properties: props || {},
    options,
  }
  validateAgainstSchema(message, schema).then(() => {
    if (a) {
      a.track(
        'Product List Viewed',
        props || {},
        withRudderTyperContext(options),
        callback
      )
    }
  })
}
/**
 * This event is triggered when a checkout step is completed.
 *
 * @param {CheckoutStepViewed} [props] - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStepViewed(
  props?: CheckoutStepViewed,
  options?: Rudder.Options,
  callback?: apiCallback
): void {
  const a = analytics()
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'This event is triggered when a checkout step is completed.',
    properties: {
      properties: {
        additionalProperties: true,
        properties: {
          checkout_id: {
            description:
              'Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)',
            type: ['string'],
          },
          payment_method: {
            description: 'Payment method',
            type: ['string'],
          },
          shipping_method: {
            description: 'Chosen shipping method',
            type: ['string'],
          },
          step: {
            description: 'Checkout process step number',
            type: ['number'],
          },
        },
        required: [],
        type: 'object',
      },
    },
    title: 'Checkout Step Viewed',
    type: 'object',
  }
  const message = {
    event: 'Checkout Step Viewed',
    properties: props || {},
    options,
  }
  validateAgainstSchema(message, schema).then(() => {
    if (a) {
      a.track(
        'Checkout Step Viewed',
        props || {},
        withRudderTyperContext(options),
        callback
      )
    }
  })
}
/**
 * This event is triggered when payment information is successfully entered.
 *
 * @param {PaymentInfoEntered} [props] - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentInfoEntered(
  props?: PaymentInfoEntered,
  options?: Rudder.Options,
  callback?: apiCallback
): void {
  const a = analytics()
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is triggered when payment information is successfully entered.',
    properties: {
      properties: {
        additionalProperties: true,
        properties: {
          checkout_id: {
            description:
              'Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)',
            type: ['string'],
          },
          order_id: {
            description: 'Order ID, optionsal',
            type: ['string'],
          },
          payment_method: {
            description: 'Payment method',
            type: ['string'],
          },
          shipping_method: {
            description: 'Chosen shipping method',
            type: ['string'],
          },
          step: {
            description: 'Checkout process step number',
            type: ['number'],
          },
        },
        required: [],
        type: 'object',
      },
    },
    title: 'Payment Info Entered',
    type: 'object',
  }
  const message = {
    event: 'Payment Info Entered',
    properties: props || {},
    options,
  }
  validateAgainstSchema(message, schema).then(() => {
    if (a) {
      a.track(
        'Payment Info Entered',
        props || {},
        withRudderTyperContext(options),
        callback
      )
    }
  })
}
/**
 * This event is triggered when a checkout step is completed.
 *
 * @param {CheckoutStepCompleted} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStepCompleted(
  props: CheckoutStepCompleted,
  options?: Rudder.Options,
  callback?: apiCallback
): void {
  const a = analytics()
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'This event is triggered when a checkout step is completed.',
    properties: {
      properties: {
        additionalProperties: true,
        properties: {
          checkout_id: {
            description:
              'Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)',
            type: ['string'],
          },
          payment_method: {
            description: 'Payment method',
            type: ['string'],
          },
          shipping_method: {
            description: 'Chosen shipping method',
            type: ['string'],
          },
          shipping_option: {
            description:
              'Chosen shipping option as presented to the user (e.g. "Ground", "Free Shipping", or "Two Business Days")',
            type: ['string'],
          },
          step: {
            description: 'Checkout process step number',
            type: ['number'],
          },
          total: {
            description: 'Gross Total',
            type: ['number'],
          },
        },
        required: ['total'],
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Checkout Step Completed',
    type: 'object',
  }
  const message = {
    event: 'Checkout Step Completed',
    properties: props || {},
    options,
  }
  validateAgainstSchema(message, schema).then(() => {
    if (a) {
      a.track(
        'Checkout Step Completed',
        props || {},
        withRudderTyperContext(options),
        callback
      )
    }
  })
}
/**
 * This event is triggered when a customer adds a product to their shopping wishlist.
 *
 * @param {ProductAddedToWishlist} [props] - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productAddedToWishlist(
  props?: ProductAddedToWishlist,
  options?: Rudder.Options,
  callback?: apiCallback
): void {
  const a = analytics()
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is triggered when a customer adds a product to their shopping wishlist.',
    properties: {
      properties: {
        additionalProperties: true,
        properties: {
          brand: {
            description: 'Name of the brand associated with the product',
            type: ['string'],
          },
          category: {
            description:
              'Primary Category, rendered hierarchically (e.g. Nuts > Almonds)',
            type: ['string'],
          },
          coupon: {
            description: 'Coupon code associated with the product',
            type: ['string'],
          },
          image_url: {
            description: 'Full URL of image (variant-specific if possible)',
            type: ['string'],
          },
          name: {
            description: 'Name of the product',
            type: ['string'],
          },
          position: {
            description: 'Position of the product in the product list',
            type: ['number'],
          },
          price: {
            description: 'Price of the product (in USD)',
            type: ['number'],
          },
          product_id: {
            description: 'Product key (4 digits, e.g. 4003)',
            type: ['string'],
          },
          quantity: {
            description: 'Quantity of the product',
            type: ['number'],
          },
          reporting_category: {
            description: 'Name of reporting category for a product',
            type: ['string'],
          },
          sku: {
            description: 'Variant sku (6 digits, e.g. 4003-05)',
            type: ['string'],
          },
          url: {
            description: 'Full URL of PDP (variant-specific if possible)',
            type: ['string'],
          },
          variant: {
            description: 'Short variant name (e.g. 5lb bag)',
            type: ['string'],
          },
          wishlist_id: {
            description: 'Wishlist ID',
            type: ['string'],
          },
          wishlist_name: {
            description: 'Name of the wishlist the product was added to',
            type: ['string'],
          },
        },
        required: [],
        type: 'object',
      },
    },
    title: 'Product Added to Wishlist',
    type: 'object',
  }
  const message = {
    event: 'Product Added to Wishlist',
    properties: props || {},
    options,
  }
  validateAgainstSchema(message, schema).then(() => {
    if (a) {
      a.track(
        'Product Added to Wishlist',
        props || {},
        withRudderTyperContext(options),
        callback
      )
    }
  })
}
/**
 * This event is triggered when a visitor clicks on a product.
 *
 * @param {ProductClicked} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productClicked(
  props: ProductClicked,
  options?: Rudder.Options,
  callback?: apiCallback
): void {
  const a = analytics()
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'This event is triggered when a visitor clicks on a product.',
    properties: {
      properties: {
        additionalProperties: true,
        properties: {
          brand: {
            description: 'Name of the brand associated with the product',
            type: ['string'],
          },
          category: {
            description:
              'Primary Category, rendered hierarchically (e.g. Nuts > Almonds)',
            type: ['string'],
          },
          coupon: {
            description: 'Coupon code associated with a product',
            type: ['string'],
          },
          image_url: {
            description: 'Full URL of image (variant-specific if possible)',
            type: ['string'],
          },
          name: {
            description: 'Name of the product being viewed',
            type: ['string'],
          },
          position: {
            description: 'Position of the product in the product list',
            type: ['number'],
          },
          price: {
            description: 'Price of the product being viewed',
            type: ['number'],
          },
          product_id: {
            description: 'Product key (4 digits, e.g. 4003)',
            type: ['string'],
          },
          quantity: {
            description: 'Quantity of the product',
            type: ['number'],
          },
          reporting_category: {
            description: 'Name of reporting category for a product',
            type: ['string'],
          },
          sku: {
            description: 'Variant sku (6 digits, e.g. 4003-05)',
            type: ['string'],
          },
          url: {
            description: 'Full URL of PDP (variant-specific if possible)',
            type: ['string'],
          },
          variant: {
            description: 'Short variant name (e.g. 5lb bag)',
            type: ['string'],
          },
        },
        required: ['name', 'price', 'product_id', 'sku'],
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Product Clicked',
    type: 'object',
  }
  const message = {
    event: 'Product Clicked',
    properties: props || {},
    options,
  }
  validateAgainstSchema(message, schema).then(() => {
    if (a) {
      a.track(
        'Product Clicked',
        props || {},
        withRudderTyperContext(options),
        callback
      )
    }
  })
}
/**
 * This event is triggered when a visitor searches for a product on your app/website.
 *
 * @param {ProductsSearched} [props] - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productsSearched(
  props?: ProductsSearched,
  options?: Rudder.Options,
  callback?: apiCallback
): void {
  const a = analytics()
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is triggered when a visitor searches for a product on your app/website.',
    properties: {
      properties: {
        additionalProperties: true,
        properties: {
          query: {
            description: 'Query searched by the user',
            type: ['string'],
          },
        },
        required: [],
        type: 'object',
      },
    },
    title: 'Products Searched',
    type: 'object',
  }
  const message = {
    event: 'Products Searched',
    properties: props || {},
    options,
  }
  validateAgainstSchema(message, schema).then(() => {
    if (a) {
      a.track(
        'Products Searched',
        props || {},
        withRudderTyperContext(options),
        callback
      )
    }
  })
}
/**
 * This event is triggered when a visitor filters a list or category of products on your website or app.
 *
 * @param {ProductListFiltered} [props] - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productListFiltered(
  props?: ProductListFiltered,
  options?: Rudder.Options,
  callback?: apiCallback
): void {
  const a = analytics()
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is triggered when a visitor filters a list or category of products on your website or app.',
    properties: {
      properties: {
        additionalProperties: true,
        properties: {
          category: {
            description:
              'Primary Category, rendered hierarchically (e.g. Nuts > Almonds)',
            type: ['string'],
          },
          filters: {
            description: 'Product filters that the customer has applied',
            items: {
              additionalProperties: false,
              properties: {
                type: {
                  description:
                    'ID of the filter type that the customer is using',
                  type: ['string'],
                },
                value: {
                  description: 'ID of the selection chosen by the customer',
                  type: ['string'],
                },
              },
              required: [],
              type: ['object'],
            },
            type: ['array'],
          },
          list_id: {
            description: 'Name of the product list being viewed',
            type: ['string'],
          },
          products: {
            description: 'Products displayed in the product list',
            items: {
              additionalProperties: false,
              properties: {
                brand: {
                  description: 'Brand name associated with the product',
                  type: ['string'],
                },
                category: {
                  description: 'Product category viewed by the customer',
                  type: ['string'],
                },
                coupon: {
                  description: 'Coupon code associated with a product',
                  type: ['string'],
                },
                image_url: {
                  description:
                    'Full URL of image (variant-specific if possible)',
                  type: ['string'],
                },
                name: {
                  description: 'Name of the product being viewed by the user',
                  type: ['string'],
                },
                position: {
                  description: 'Position of the product in the product list',
                  type: ['number'],
                },
                price: {
                  description: 'Price of the product being viewed (in USD)',
                  type: ['number'],
                },
                product_id: {
                  description: 'Product key (4 digits, e.g. 4003)',
                  type: ['string'],
                },
                quantity: {
                  description: 'Quantity of a product',
                  type: ['number'],
                },
                reporting_category: {
                  description: 'Name of reporting category for a product',
                  type: ['string'],
                },
                sku: {
                  description: 'Variant sku (6 digits, e.g. 4003-05)',
                  type: ['string'],
                },
                url: {
                  description: 'Full URL of PDP (variant-specific if possible)',
                  type: ['string'],
                },
                variant: {
                  description: 'Short variant name (e.g. 5lb bag)',
                  type: ['string'],
                },
              },
              required: [
                'name',
                'position',
                'price',
                'product_id',
                'quantity',
                'sku',
              ],
              type: ['object'],
            },
            type: ['array'],
          },
          sorts: {
            description: 'Product sorting used by the customer',
            items: {
              additionalProperties: false,
              properties: {
                type: {
                  description: 'ID of the sort type used by the customer',
                  type: ['string'],
                },
                value: {
                  description: 'ID of the selection-type the customer is using',
                  type: ['string'],
                },
              },
              required: [],
              type: ['object'],
            },
            type: ['array'],
          },
        },
        required: [],
        type: 'object',
      },
    },
    title: 'Product List Filtered',
    type: 'object',
  }
  const message = {
    event: 'Product List Filtered',
    properties: props || {},
    options,
  }
  validateAgainstSchema(message, schema).then(() => {
    if (a) {
      a.track(
        'Product List Filtered',
        props || {},
        withRudderTyperContext(options),
        callback
      )
    }
  })
}
/**
 * This event is triggered whenever a visitor or customer views their shopping cart.
 *
 * @param {CartViewed} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cartViewed(
  props: CartViewed,
  options?: Rudder.Options,
  callback?: apiCallback
): void {
  const a = analytics()
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is triggered whenever a visitor or customer views their shopping cart.',
    properties: {
      properties: {
        additionalProperties: true,
        properties: {
          cart_id: {
            description: 'Cart ID of the cart being viewed (a UUID)',
            type: ['string'],
          },
          products: {
            description: 'List of products displayed in the product list',
            items: {
              additionalProperties: false,
              properties: {
                brand: {
                  description: 'Name of the brand associated with the product',
                  type: ['string'],
                },
                category: {
                  description:
                    'Primary Category, rendered hierarchically (e.g. Nuts > Almonds)',
                  type: ['string'],
                },
                coupon: {
                  description: 'Coupon code associated with a product',
                  type: ['string'],
                },
                image_url: {
                  description:
                    'Full URL of image (variant-specific if possible)',
                  type: ['string'],
                },
                name: {
                  description: 'Name of the product being viewed',
                  type: ['string'],
                },
                position: {
                  description: 'Position of the product in the product list',
                  type: ['number'],
                },
                price: {
                  description: 'Price of the product being viewed',
                  type: ['number'],
                },
                product_id: {
                  description: 'Product key (4 digits, e.g. 4003)',
                  type: ['string'],
                },
                quantity: {
                  description: 'Quantity of the product',
                  type: ['number'],
                },
                reporting_category: {
                  description: 'Name of reporting category for a product',
                  type: ['string'],
                },
                sku: {
                  description: 'Variant sku (6 digits, e.g. 4003-05)',
                  type: ['string'],
                },
                url: {
                  description: 'Full URL of PDP (variant-specific if possible)',
                  type: ['string'],
                },
                variant: {
                  description: 'Short variant name (e.g. 5lb bag)',
                  type: ['string'],
                },
              },
              required: ['name', 'price', 'product_id', 'quantity', 'sku'],
              type: ['object'],
            },
            type: ['array'],
          },
          total: {
            description: 'Gross Total',
            type: ['number'],
          },
        },
        required: ['total'],
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Cart Viewed',
    type: 'object',
  }
  const message = {
    event: 'Cart Viewed',
    properties: props || {},
    options,
  }
  validateAgainstSchema(message, schema).then(() => {
    if (a) {
      a.track(
        'Cart Viewed',
        props || {},
        withRudderTyperContext(options),
        callback
      )
    }
  })
}
/**
 * This event is triggered when an order or transaction is initiated.
 *
 * @param {CheckoutStarted} [props] - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStarted(
  props?: CheckoutStarted,
  options?: Rudder.Options,
  callback?: apiCallback
): void {
  const a = analytics()
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is triggered when an order or transaction is initiated.',
    properties: {
      properties: {
        additionalProperties: true,
        properties: {
          affiliation: {
            description:
              'Store or affiliation details from where the transaction occured',
            type: ['string'],
          },
          coupon: {
            description: 'Coupon redeemed with the transaction',
            type: ['string'],
          },
          currency: {
            description:
              'Currency code associated with an order or transaction',
            type: ['string'],
          },
          discount: {
            description: 'Total discount associated with the transaction',
            type: ['number'],
          },
          order_id: {
            description: 'Order ID or transaction ID, whichever is applicable',
            type: ['string'],
          },
          products: {
            description: 'List of products in the order or transaction',
            items: {
              additionalProperties: false,
              properties: {
                brand: {
                  description: 'Name of the brand associated with the product',
                  type: ['string'],
                },
                category: {
                  description:
                    'Primary Category, rendered hierarchically (e.g. Nuts > Almonds)',
                  type: ['string'],
                },
                coupon: {
                  description: 'Coupon code associated with a product',
                  type: ['string'],
                },
                image_url: {
                  description:
                    'Full URL of image (variant-specific if possible)',
                  type: ['string'],
                },
                name: {
                  description: 'Mame of the product being viewed',
                  type: ['string'],
                },
                position: {
                  description: 'Position of the product in the product list',
                  type: ['number'],
                },
                price: {
                  description: 'Price of the product being viewed',
                  type: ['number'],
                },
                product_id: {
                  description: 'Product key (4 digits, e.g. 4003)',
                  type: ['string'],
                },
                quantity: {
                  description: 'Quantity of the product',
                  type: ['number'],
                },
                reporting_category: {
                  description: 'Name of reporting category for a product',
                  type: ['string'],
                },
                sku: {
                  description: 'Variant sku (6 digits, e.g. 4003-05)',
                  type: ['string'],
                },
                url: {
                  description: 'Full URL of PDP (variant-specific if possible)',
                  type: ['string'],
                },
                variant: {
                  description: 'Short variant name (e.g. 5lb bag)',
                  type: ['string'],
                },
              },
              required: ['name', 'price', 'product_id', 'quantity', 'sku'],
              type: ['object'],
            },
            type: ['array'],
          },
          revenue: {
            description:
              'Revenue associated with the transaction, excluding the shipping and tax details',
            type: ['number'],
          },
          shipping: {
            description:
              'Shipping cost associated with the order or transaction',
            type: ['number'],
          },
          tax: {
            description:
              'Total tax associated with the order or the transaction',
            type: ['number'],
          },
          value: {
            description: 'Revenue with discount and coupons factored in',
            type: ['number'],
          },
        },
        required: [],
        type: 'object',
      },
    },
    title: 'Checkout Started',
    type: 'object',
  }
  const message = {
    event: 'Checkout Started',
    properties: props || {},
    options,
  }
  validateAgainstSchema(message, schema).then(() => {
    if (a) {
      a.track(
        'Checkout Started',
        props || {},
        withRudderTyperContext(options),
        callback
      )
    }
  })
}
/**
 * This event is triggered when a product is removed from the shopping cart by the customer.
 *
 * @param {ProductRemoved} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productRemoved(
  props: ProductRemoved,
  options?: Rudder.Options,
  callback?: apiCallback
): void {
  const a = analytics()
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is triggered when a product is removed from the shopping cart by the customer.',
    properties: {
      properties: {
        additionalProperties: true,
        properties: {
          brand: {
            description: 'Name of the brand associated with the product',
            type: ['string'],
          },
          cart_id: {
            description: 'Cart ID the product was removed from (a UUID)',
            type: ['string'],
          },
          category: {
            description:
              'Primary Category, rendered hierarchically (e.g. Nuts > Almonds)',
            type: ['string'],
          },
          coupon: {
            description: 'Coupon code associated with a product',
            type: ['string'],
          },
          image_url: {
            description: 'Full URL of image (variant-specific if possible)',
            type: ['string'],
          },
          name: {
            description: 'Name of the product being viewed',
            type: ['string'],
          },
          position: {
            description: 'Position of the product in the product list',
            type: ['number'],
          },
          price: {
            description: 'Price of the product being viewed',
            type: ['number'],
          },
          product_id: {
            description: 'Product key (4 digits, e.g. 4003)',
            type: ['string'],
          },
          quantity: {
            description: 'Quantity of the product',
            type: ['number'],
          },
          reporting_category: {
            description: 'Name of reporting category for a product',
            type: ['string'],
          },
          sku: {
            description: 'Variant sku (6 digits, e.g. 4003-05)',
            type: ['string'],
          },
          url: {
            description: 'Full URL of PDP (variant-specific if possible)',
            type: ['string'],
          },
          variant: {
            description: 'Short variant name (e.g. 5lb bag)',
            type: ['string'],
          },
        },
        required: ['name', 'price', 'product_id', 'quantity', 'sku'],
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Product Removed',
    type: 'object',
  }
  const message = {
    event: 'Product Removed',
    properties: props || {},
    options,
  }
  validateAgainstSchema(message, schema).then(() => {
    if (a) {
      a.track(
        'Product Removed',
        props || {},
        withRudderTyperContext(options),
        callback
      )
    }
  })
}
/**
 * This event is triggered when an order is completed successfully.
 *
 * @param {OrderCompleted} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCompleted(
  props: OrderCompleted,
  options?: Rudder.Options,
  callback?: apiCallback
): void {
  const a = analytics()
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is triggered when an order is completed successfully.',
    properties: {
      properties: {
        additionalProperties: true,
        properties: {
          affiliation: {
            description:
              'Store or affiliation details from where the transaction was started',
            type: ['string'],
          },
          checkout_id: {
            description:
              'Cart ID associated to the checkout (a UUID). (There is no checkout transaction ID available)',
            type: ['string'],
          },
          coupon: {
            description: 'Coupon redeemed with the transaction',
            type: ['string'],
          },
          currency: {
            description:
              'Currency code associated with an order or transaction',
            type: ['string'],
          },
          discount: {
            description: 'Total discount associated with the transaction',
            type: ['number'],
          },
          order_id: {
            description: 'Order ID or transaction ID',
            type: ['string'],
          },
          products: {
            description: 'List of products in the order or transaction',
            items: {
              additionalProperties: false,
              properties: {
                brand: {
                  description: 'Name of the brand associated with the product',
                  type: ['string'],
                },
                category: {
                  description:
                    'Primary Category, rendered hierarchically (e.g. Nuts > Almonds)',
                  type: ['string'],
                },
                coupon: {
                  description: 'Coupon code associated with a product',
                  type: ['string'],
                },
                image_url: {
                  description:
                    'Full URL of image (variant-specific if possible)',
                  type: ['string'],
                },
                name: {
                  description: 'Name of the product being viewed',
                  type: ['string'],
                },
                position: {
                  description: 'Position of the product in the product list',
                  type: ['number'],
                },
                price: {
                  description: 'Price of the product being viewed',
                  type: ['number'],
                },
                product_id: {
                  description: 'Product key (4 digits, e.g. 4003)',
                  type: ['string'],
                },
                quantity: {
                  description: 'Quantity of the product',
                  type: ['number'],
                },
                reporting_category: {
                  description: 'Name of reporting category for a product',
                  type: ['string'],
                },
                sku: {
                  description: 'Variant sku (6 digits, e.g. 4003-05)',
                  type: ['string'],
                },
                url: {
                  description: 'Full URL of PDP (variant-specific if possible)',
                  type: ['string'],
                },
                variant: {
                  description: 'Short variant name (e.g. 5lb bag)',
                  type: ['string'],
                },
              },
              required: ['name', 'price', 'product_id', 'quantity', 'sku'],
              type: ['object'],
            },
            type: ['array'],
          },
          revenue: {
            description: 'Revenue associated with the transaction',
            type: ['number'],
          },
          shipping: {
            description:
              'Shipping cost associated with the order or transaction',
            type: ['number'],
          },
          subtotal: {
            description:
              'Order total after discounts but not including the taxes and shipping charges',
            type: ['number'],
          },
          tax: {
            description:
              'Total tax associated with the order or the transaction',
            type: ['number'],
          },
          total: {
            description: 'Revenue with the discount and coupons factored in',
            type: ['number'],
          },
        },
        required: ['order_id'],
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Order Completed',
    type: 'object',
  }
  const message = {
    event: 'Order Completed',
    properties: props || {},
    options,
  }
  validateAgainstSchema(message, schema).then(() => {
    if (a) {
      a.track(
        'Order Completed',
        props || {},
        withRudderTyperContext(options),
        callback
      )
    }
  })
}
/**
 * This event is triggered when an order is canceled.
 *
 * @param {OrderCancelled} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCancelled(
  props: OrderCancelled,
  options?: Rudder.Options,
  callback?: apiCallback
): void {
  const a = analytics()
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'This event is triggered when an order is canceled.',
    properties: {
      properties: {
        additionalProperties: true,
        properties: {
          affiliation: {
            description:
              'Store or affiliation details from where the transaction was started',
            type: ['string'],
          },
          coupon: {
            description: 'Coupon which can be redeemed with the transaction',
            type: ['string'],
          },
          currency: {
            description:
              'Currency code associated with an order or transaction',
            type: ['string'],
          },
          discount: {
            description: 'Total discount associated with the transaction',
            type: ['number'],
          },
          order_id: {
            description: 'Order ID or transaction ID, whichever is applicable',
            type: ['string'],
          },
          products: {
            description: 'List of products in the order or transaction',
            items: {
              additionalProperties: false,
              properties: {
                brand: {
                  description: 'Name of the brand associated with the product',
                  type: ['string'],
                },
                category: {
                  description:
                    'Primary Category, rendered hierarchically (e.g. Nuts > Almonds)',
                  type: ['string'],
                },
                coupon: {
                  description: 'Coupon code associated with a product',
                  type: ['string'],
                },
                image_url: {
                  description:
                    'Full URL of image (variant-specific if possible)',
                  type: ['string'],
                },
                name: {
                  description: 'Name of the product being viewed',
                  type: ['string'],
                },
                position: {
                  description: 'Position of the product in the product list',
                  type: ['number'],
                },
                price: {
                  description: 'Price of the product being viewed',
                  type: ['number'],
                },
                product_id: {
                  description: 'Product key (4 digits, e.g. 4003)',
                  type: ['string'],
                },
                quantity: {
                  description: 'Quantity of the product',
                  type: ['number'],
                },
                reporting_category: {
                  description: 'Name of reporting category for a product',
                  type: ['string'],
                },
                sku: {
                  description: 'Variant sku (6 digits, e.g. 4003-05)',
                  type: ['string'],
                },
                url: {
                  description: 'Full URL of PDP (variant-specific if possible)',
                  type: ['string'],
                },
                variant: {
                  description: 'Short variant name (e.g. 5lb bag)',
                  type: ['string'],
                },
              },
              required: ['name', 'price', 'product_id', 'quantity', 'sku'],
              type: ['object'],
            },
            type: ['array'],
          },
          revenue: {
            description: 'Revenue associated with the transaction',
            type: ['number'],
          },
          shipping: {
            description:
              'Shipping cost associated with the order or transaction',
            type: ['number'],
          },
          tax: {
            description:
              'Total tax associated with the order or the transaction',
            type: ['number'],
          },
          total: {
            description: 'Revenue with the discount and coupons factored in',
            type: ['number'],
          },
        },
        required: ['order_id'],
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Order Cancelled',
    type: 'object',
  }
  const message = {
    event: 'Order Cancelled',
    properties: props || {},
    options,
  }
  validateAgainstSchema(message, schema).then(() => {
    if (a) {
      a.track(
        'Order Cancelled',
        props || {},
        withRudderTyperContext(options),
        callback
      )
    }
  })
}
/**
 * This event is triggered when there is a content decision, usually from systems including Dynamic Yield, Algolia, or Builder.io
 *
 * @param {ExperimentViewed} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function experimentViewed(
  props: ExperimentViewed,
  options?: Rudder.Options,
  callback?: apiCallback
): void {
  const a = analytics()
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is triggered when there is a content decision, usually from systems including Dynamic Yield, Algolia, or Builder.io',
    properties: {
      properties: {
        additionalProperties: true,
        properties: {
          campaign_id: {
            description:
              'Identifier of the testing platform top-level grouping; optional because the CMS and search engine do not use such a grouping and just serve a single experiment for the requested content.',
            type: ['string'],
          },
          campaign_name: {
            description: 'Name of the testing platform top-level grouping.',
            type: ['string'],
          },
          experiment_id: {
            description:
              'Identifier of the direct test being run. For DY this would be the group that contains variations and declares general targeting criteria; in the CMS it will reflect the ID of the content entry; and search engine will reflect the ID of the test applied to results.',
            type: ['string'],
          },
          experiment_name: {
            description:
              'Name of the direct test being run. For the CMS it will reflect the name of the content entry; while the search engine will reflect the name of the test as displayed in the dashboard.',
            type: ['string'],
          },
          variation_id: {
            description:
              'Identifier of the variation served. May point to the control; for the CMS the control would match the experiment_id value.',
            type: ['string'],
          },
          variation_name: {
            description:
              'Name of the variation served. May point to the control; for the CMS the control may either match the experiment_name value or state Default.',
            type: ['string'],
          },
        },
        required: ['experiment_id', 'variation_id'],
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Experiment Viewed',
    type: 'object',
  }
  const message = {
    event: 'Experiment Viewed',
    properties: props || {},
    options,
  }
  validateAgainstSchema(message, schema).then(() => {
    if (a) {
      a.track(
        'Experiment Viewed',
        props || {},
        withRudderTyperContext(options),
        callback
      )
    }
  })
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this RudderTyper client.
   *
   * @param {RudderTyperOptions} options - the options to upsert
   *
   * @typedef {Object} RudderTyperOptions
   * @property {Rudder.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setRudderTyperOptions,
  /**
   * Not yet provided
   *
   * @param {ProductAdded} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productAdded,
  /**
   * User added a product to their shopping cart
   *
   * @param {ProductPurchased} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productPurchased,
  /**
   * Not yet provided
   *
   * @param {ProductViewed} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productViewed,
  /**
   * This event is triggered when a visitor views a list or category of products on your website or app.
   *
   * @param {ProductListViewed} [props] - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productListViewed,
  /**
   * This event is triggered when a checkout step is completed.
   *
   * @param {CheckoutStepViewed} [props] - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutStepViewed,
  /**
   * This event is triggered when payment information is successfully entered.
   *
   * @param {PaymentInfoEntered} [props] - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentInfoEntered,
  /**
   * This event is triggered when a checkout step is completed.
   *
   * @param {CheckoutStepCompleted} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutStepCompleted,
  /**
   * This event is triggered when a customer adds a product to their shopping wishlist.
   *
   * @param {ProductAddedToWishlist} [props] - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productAddedToWishlist,
  /**
   * This event is triggered when a visitor clicks on a product.
   *
   * @param {ProductClicked} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productClicked,
  /**
   * This event is triggered when a visitor searches for a product on your app/website.
   *
   * @param {ProductsSearched} [props] - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productsSearched,
  /**
   * This event is triggered when a visitor filters a list or category of products on your website or app.
   *
   * @param {ProductListFiltered} [props] - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productListFiltered,
  /**
   * This event is triggered whenever a visitor or customer views their shopping cart.
   *
   * @param {CartViewed} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  cartViewed,
  /**
   * This event is triggered when an order or transaction is initiated.
   *
   * @param {CheckoutStarted} [props] - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutStarted,
  /**
   * This event is triggered when a product is removed from the shopping cart by the customer.
   *
   * @param {ProductRemoved} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productRemoved,
  /**
   * This event is triggered when an order is completed successfully.
   *
   * @param {OrderCompleted} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  orderCompleted,
  /**
   * This event is triggered when an order is canceled.
   *
   * @param {OrderCancelled} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  orderCancelled,
  /**
   * This event is triggered when there is a content decision, usually from systems including Dynamic Yield, Algolia, or Builder.io
   *
   * @param {ExperimentViewed} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  experimentViewed,
}

export const RudderTyperAnalytics = new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === 'string' && Object.keys(target).includes(method)) {
      return target[method as keyof typeof clientAPI]
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(
        method
      )}) that can't be found. Either:
    a) Re-generate your ruddertyper client: \`npx rudder-typer\`
    b) Add it to your Tracking Plan: https://api.rudderstack.com/trackingplans/tp_2dxlF3tndjHVEe4qIgY3S0t6DNO`)
      const a = analytics()
      if (a) {
        a.track(
          'Unknown Analytics Call Fired',
          {
            method,
          },
          withRudderTyperContext()
        )
      }
    }
  },
})

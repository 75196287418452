import { createClient } from '@nuts/auto-delivery-sdk';

import { getCtConfig } from '@/api/config';
import { getSessionId } from '@/utils/isomorphic/cookie';

export const init = () => {
  const accessToken = getSessionId();
  const client = createClient({ accessToken, auth: getCtConfig() });
  return client;
};

export const orders = async (client) => {
  if (!client || !('getNextDeliveries' in client)) {
    return { orders: [] };
  }
  const nextDeliveries = await client.getNextDeliveries({
    preloadImagesAndPaths: true,
    preloadPaymentMethod: true,
    preloadShippingAddresses: true,
  });

  return {
    orders: nextDeliveries,
  };
};

export default {};

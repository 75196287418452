export interface SecondaryNavLink {
  name: string;
  url: string;
}

export const staticSecondaryNavLinks: SecondaryNavLink[] = [
  { url: '/organic/', name: 'Organic' },
  { url: '/tag/raw', name: 'Raw' },
  { url: '/gluten-free', name: 'Gluten-free' },
  { url: '/tag/dark+chocolate', name: 'Dark Chocolate' },
  { url: '/cms/pantry-staples', name: 'Pantry' },
  { url: '/tag/superfoods', name: 'Superfoods' },
  { url: '/nuts/single-serve/', name: 'Single Serves' },
  { url: '/wholesale/', name: 'Wholesale' },
  { url: 'https://officesnacks.nuts.com/', name: 'Office Snacks' },
  { url: '/tag/new+products', name: 'New Products' },
  { url: '/top_sellers', name: 'Top Sellers' },
];

import { context, trace } from '@opentelemetry/api';
import { readonly, shallowReactive, unref } from 'vue';
import { Store } from 'vuex';

import { useDyChooseResults } from '@/composables/dynamic-yield/experiences';
import { useState } from '@/composables/useState';
import { FeatureFlagsChoice } from '@/lib/personalization/dynamicYield';
import { dyEvent, gaEvent } from '@/utils/analytics';

interface FeatureFlags extends Record<string, boolean | undefined> {
  adSurvey?: boolean;
  autoDeliverySignUp?: boolean;
  autoDeliveryUpsellOneClick?: boolean;
  cartGreetingCardStep?: boolean;
  disableReferral?: boolean;
  discountCodeForm?: boolean;
  expressCheckout?: boolean;
  giftContentList?: boolean;
  googleOneTapHomepage?: boolean;
  googleOneTapPDP?: boolean;
  headerSearchChangesV1?: boolean;
  layoutRebrandingChangesV1?: boolean;
  leaveAReview?: boolean;
  pdpOneLinerFlavor?: boolean;
  redesignedPLPCards?: boolean;
  searchPageLayoutChanges?: boolean;
  showAddOn?: boolean;
  showMoreFiltersPLP?: boolean;
  showMoreFiltersSearchPage?: boolean;
  singleLineVariantSelection?: boolean;
  sizeGuide?: boolean;
  strikethroughPricing?: boolean;
}

const layers = ['local', 'session', 'site', 'visitor'] as const;
type Layer = (typeof layers)[number];

export type FlagsByLayer = Record<Layer, FeatureFlags>;

export function fromFlagSpec(flagSpec: string): FeatureFlags {
  const entries = flagSpec.split(',').map((spec) => {
    const [_, setting, name] = /^(-?)(.+)$/.exec(spec)!;
    return [name, setting !== '-'];
  });
  return Object.fromEntries(entries);
}

export function toFlagSpec(flags: FeatureFlags, flagNames?: (keyof FeatureFlags)[]): string {
  return (flagNames ?? Object.keys(flags).sort())
    .map((name) => `${flags[name] ? '' : '-'}${name}`)
    .join();
}

export function useFeatureFlags(store: Store<any>) {
  const { choicesByName, loadExperiences } = useDyChooseResults<FeatureFlagsChoice>([]);

  const state = useState('featureFlags', () => {
    const initial = unref(store.state.featureFlagsModule);

    const flagsByLayer: FlagsByLayer = {
      local: initial.flagsByLayer?.local ?? {},
      session: initial.flagsByLayer?.session ?? {},
      site: initial.flagsByLayer?.site ?? initial.flags ?? {},
      visitor: initial.flagsByLayer?.visitor ?? {},
    };

    const flattenedFlags = layers.reduceRight(
      (acc, layer) => ({ ...acc, ...flagsByLayer[layer] }),
      {} as FeatureFlags,
    );

    const flagProxy = new Proxy(flattenedFlags, {
      get: (target, property) => {
        const flagName = property.toString();
        const flagValue = target[property.toString()];
        if (flagName.substring(0, 2) !== '__' && flagName.substring(0, 7) !== 'Symbol(') {
          trace.getSpan(context.active())?.addEvent('feature_flag', {
            'feature_flag.key': flagName,
            'feature_flag.provider_name': 'nuts.com',
            'feature_flag.variant': (flagValue ?? 'null').toString(),
          });
        }
        return target[property.toString()];
      },
    });

    const flags = shallowReactive(flagProxy);

    return { flagsByLayer, flags };
  });

  const {
    flags: { value: flags },
    flagsByLayer,
  } = state;

  /**
   * Update the manually-maintained reactive `flags` object to match the current
   * state of the `flagsByLayer` object. Called by replaceFlags() and setFlags().
   */
  function updateReactiveFlags(updatedKeys: string[]) {
    updatedKeys.forEach((key) => {
      const newValue = layers.flatMap((l) => flagsByLayer.value[l][key] ?? [])[0];
      if (newValue === undefined) {
        delete flags[key];
      } else if (flags[key] !== newValue) {
        flags[key] = newValue;
      }
    });
  }

  /**
   * Replace all flags in all layers with the given flags. Meant for use client
   * side when a whole new set is received from the server, such as after
   * sign-in or other identification change.
   */
  function replaceFlagsByLayer(newFlagsByLayer: FlagsByLayer) {
    // update the canonical layered flag sets
    layers.forEach((layer) => {
      flagsByLayer.value[layer] = newFlagsByLayer[layer] ?? {};
    });

    // update the reactive object to match
    // (examine all flag keys, old and new, to make sure none are accidentally left around)
    const allKeys = [flags, ...Object.values(flagsByLayer.value)].flatMap(Object.keys);
    const uniqueKeys = [...new Set(allKeys)];
    updateReactiveFlags(uniqueKeys);
  }

  /**
   * Set one or more specific flags in the given layer (merging with existing
   * flags) and propagate changes to the server be persisted. Used by
   * loadDyFlags().
   */
  function setFlags(updates: FeatureFlags, layer: Layer = 'local') {
    // update the canonical layered flag sets
    const updatedLayer = { ...flagsByLayer.value[layer], ...updates };
    Object.keys(updates).forEach((key) => updates[key] === undefined && delete updatedLayer[key]);
    flagsByLayer.value[layer] = updatedLayer;

    // update the reactive object to match
    updateReactiveFlags(Object.keys(updates));
  }

  /**
   * Load visitor-level flags from a DY campaign
   *
   * DY will be queried if and only if we don't already have a value for at
   * least one of the listed flags. Flags are expected to be in a `flags` field
   * of the DY API campaign variation. After awaiting this promise, look at the
   * `flags` reactive object as usual for the latest flags.
   *
   * @param selector  API Selector of the DY campaign that will (hopefully)
   * provide at least one of the flags in question
   * @param flagNames One or more flags that we hope DY can tell us about
   */
  async function loadDyFlags(selector: string, flagNames: (keyof FeatureFlags)[]) {
    if (flagNames.some((name) => name in flags)) {
      return;
    }

    let variation = choicesByName[selector]?.variations[0];
    if (!variation) {
      await loadExperiences({ newExperiences: [selector] });
      variation = choicesByName[selector]?.variations[0];
    }

    const data = variation?.payload.data;
    if (data?.flags && typeof data.flags === 'string') {
      setFlags(fromFlagSpec(data.flags), 'visitor');
    }

    if (data?.dyEvent && typeof data.dyEvent === 'object') {
      dyEvent(data.dyEvent);
    }

    if (data?.gaEvent && typeof data.gaEvent === 'object') {
      gaEvent(data.gaEvent);
    }
  }

  return {
    flags: readonly(flags),
    loadDyFlags,
    setFlags,
    replaceFlagsByLayer,
  };
}

export default {};

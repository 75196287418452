<script setup lang="ts">
import { AlgoliaSuggestedSearch } from '@/api/algolia';
import ArrowIcon from '@/components/base/assets/ArrowIcon.vue';
import SearchIcon from '@/components/base/assets/SearchIcon.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import SearchItem from '@/components/layout/header/search/SearchItem.vue';

defineProps<{ suggestions: AlgoliaSuggestedSearch[] }>();
const emit = defineEmits<{ click: [string] }>();
</script>

<template>
  <div>
    <SmallBodyText class="px-4 text-neutral-500">Trending Searches</SmallBodyText>
    <ul class="mt-1">
      <SearchItem
        v-for="entry in suggestions"
        :key="entry.objectID"
        @click="emit('click', entry.query)"
      >
        <template #left><SearchIcon :size="20" /></template>
        {{ entry.query }}
        <template #right>
          <ArrowIcon direction="top-left" class="text-nuts-neutral-400 m-0.5" :size="20" />
        </template>
      </SearchItem>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { proxiedReportEngagement } from '@/api/dynamic-yield/engagement';
import DyServerSideRenderer from '@/components/dynamic-yield/DyServerSideRenderer.vue';
import { ContentChoice } from '@/lib/personalization/dynamicYield';
import { isDefined } from '@/utils/isDefined';

const props = defineProps<{
  banner?: ContentChoice;
  containerId?: string;
}>();

const validVariation = (choice?: ContentChoice): choice is ContentChoice =>
  isDefined(choice) &&
  isDefined(choice.variations[0]) &&
  Object.keys(choice.variations[0].payload.data).length > 0;

const engagement = () =>
  validVariation(props.banner) && proxiedReportEngagement(props.banner.decisionId, props.banner.id);
</script>

<template>
  <div v-if="validVariation(banner)" :id="containerId" :data-test="containerId" @click="engagement">
    <DyServerSideRenderer :choice="banner" />
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints, useVModel, whenever } from '@vueuse/core';
import { SearchResponse } from 'instantsearch.js';
import { inject, onMounted, ref } from 'vue';

import {
  AlgoliaCategory,
  AlgoliaSuggestedSearch,
  AlgoliaVariant,
  init as initAlgoliaClient,
} from '@/api/algolia';
import FeaturedCategories from '@/components/base/FeaturedCategories.vue';
import PopularProducts from '@/components/layout/header/search/PopularProducts.vue';
import RecentSearches from '@/components/layout/header/search/RecentSearches.vue';
import SearchSuggestions from '@/components/layout/header/search/SearchSuggestions.vue';
import TrendingSearches from '@/components/layout/header/search/TrendingSearches.vue';
import { sendExperimentViewedEvent } from '@/utils/analytics/experimentViewedEvent';

const props = defineProps<{
  // Remove once header search A/B test is done
  ignoreQueryChanges?: boolean;
  recentSearches: string[];
  query: string;
}>();
const emit = defineEmits<{
  search: [query: string];
  'update:recentSearches': [string[]];
}>();

const algoliaClient = inject('algoliaClient', () => initAlgoliaClient(), true);

const featuredCategories = ref<SearchResponse<AlgoliaCategory>>();
const productResults = ref<SearchResponse<AlgoliaVariant>>();
const suggestedSearches = ref<SearchResponse<AlgoliaSuggestedSearch>>();
const trendingSearches = ref<SearchResponse<AlgoliaSuggestedSearch>>();

const isMobile = useBreakpoints(breakpointsTailwind).smaller('lg');
const recentSearches = useVModel(props, 'recentSearches', emit);

const handleClick = (search: string) => {
  emit('search', search);
};

onMounted(async () => {
  const departmentsIndex = algoliaClient.initIndex('Departments');
  const productsIndex = algoliaClient.initIndex('Products');
  const suggestionsIndex = algoliaClient.initIndex('Products_query_suggestions');

  const [searches, categories] = await Promise.all([
    suggestionsIndex.search<AlgoliaSuggestedSearch>('', {
      hitsPerPage: isMobile.value ? 4 : 5,
      ruleContexts: ['featuredSuggestions'],
    }),
    departmentsIndex.search<AlgoliaCategory>('', {
      distinct: true,
      hitsPerPage: 9,
      filters: 'idealForSuggestions:true',
      ruleContexts: ['featuredCategories'],
    }),
  ]);

  trendingSearches.value = searches;
  featuredCategories.value = categories;

  whenever(
    () => props.query,
    async (value) => {
      if (props.ignoreQueryChanges) return;

      const [suggestions, products] = await Promise.all([
        suggestionsIndex.search<AlgoliaSuggestedSearch>(value, {
          hitsPerPage: isMobile.value ? 6 : 9,
        }),
        productsIndex.search<AlgoliaVariant>(value, {
          analytics: true,
          distinct: true,
          filters: 'traits:searchable',
          getRankingInfo: true,
          hitsPerPage: 9,
        }),
      ]);

      suggestedSearches.value = suggestions;
      productResults.value = products;

      if (productResults.value?.abTestID) {
        sendExperimentViewedEvent(
          {
            experiment_id: `${productResults.value.abTestID}`,
            variation_id: `${productResults.value.abTestVariantID!}`,
          },
          'Algolia',
        );
      }
    },
  );
});
</script>

<template>
  <div class="flex flex-col gap-4 py-4">
    <RecentSearches
      v-show="!query && recentSearches.length"
      v-model="recentSearches"
      @click="handleClick"
    />
    <SearchSuggestions
      v-if="suggestedSearches"
      v-show="query"
      v-model:recentSearches="recentSearches"
      :query="query"
      :suggestions="suggestedSearches.hits"
      @click="handleClick"
    />
    <TrendingSearches
      v-if="trendingSearches?.hits.length"
      v-show="!query || !suggestedSearches?.hits.length"
      :suggestions="trendingSearches.hits"
      @click="handleClick"
    />
    <FeaturedCategories
      v-if="featuredCategories?.hits.length"
      v-show="!query"
      :categories="featuredCategories.hits"
    />
    <PopularProducts
      v-if="productResults?.hits.length"
      v-show="query"
      :products="productResults.hits"
    />
  </div>
</template>

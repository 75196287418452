<script setup lang="ts">
import { onBeforeMount } from 'vue';
import { CallbackTypes } from 'vue3-google-login';
import { useStore } from 'vuex';

import { getConfigEntry } from '@/api/config';
import ThemedButton from '@/components/base/ThemedButton.vue';
import { useAuth } from '@/composables/useAuth';
import { useCallback } from '@/composables/useCallback';
import { useCart } from '@/composables/useCart';

const props = defineProps<{
  fullWidth?: boolean;
  isCheckout?: boolean;
}>();

const emit = defineEmits<{ 'signed-in': [] }>();

const store = useStore();
const { loadCart, loadLineItemExpansions, removeGreetingCards } = useCart(store);

const { facebookCodeSignIn } = useAuth(store);

const initFacebook = async () => {
  window.fbAsyncInit = () => {
    window.FB.init({
      appId: getConfigEntry('facebook').appId,
      xfbml: true,
      version: 'v16.0',
    });
  };
};
const loadFacebookSDK = async (d: Document, s: string, id: string) => {
  const fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  const js = d.createElement(s);
  js.id = id;
  js.src = 'https://connect.facebook.net/en_US/sdk.js';
  if (fjs && fjs.parentNode) {
    fjs.parentNode.insertBefore(js, fjs);
  } else {
    document.head.appendChild(js);
  }
};
const signIn = useCallback(async (response: CallbackTypes.CodePopupResponse) => {
  const signInResponse = await facebookCodeSignIn(response, { preserveCart: props.isCheckout });
  if (signInResponse) {
    emit('signed-in');
    await loadCart(true);
    await Promise.all([loadLineItemExpansions(), removeGreetingCards()]);
  }
});
const logInWithFacebook = async () => {
  window.FB.login(
    (response) => {
      if (response.authResponse) {
        signIn.execute({ ...response.authResponse });
      } else {
        console.error('User cancelled login or did not fully authorize.');
      }
    },
    { scope: 'email' },
  );
  return false;
};
onBeforeMount(async () => {
  await loadFacebookSDK(document, 'script', 'facebook-jssdk');
  await initFacebook();
});
</script>

<template>
  <div>
    <ThemedButton
      class="sm:h-12 h-11"
      :fullWidth="fullWidth"
      :isLoading="signIn.isPending"
      theme="white"
      @click="logInWithFacebook"
    >
      <img
        v-if="!signIn.isPending"
        aria-hidden="true"
        alt="facebook"
        class="flex-shrink-0 object-contain w-6 h-6 mr-2"
        src="@/assets/social/facebook.svg"
      />
      Continue with Facebook
    </ThemedButton>
  </div>
</template>

import mascotCharacter1 from '@/assets/mascots/mascot_character_1.png';
import mascotCharacter2 from '@/assets/mascots/mascot_character_2.png';
import mascotCharacter3 from '@/assets/mascots/mascot_character_3.png';
import mascotCharacter4 from '@/assets/mascots/mascot_character_4.png';
import mascotCharacter5 from '@/assets/mascots/mascot_character_5.png';
import mascotCharacter6 from '@/assets/mascots/mascot_character_6.png';
import mascotCharacter7 from '@/assets/mascots/mascot_character_7.png';
import mascotCharacter8 from '@/assets/mascots/mascot_character_8.png';
import mascotCharacter9 from '@/assets/mascots/mascot_character_9.png';
import mascotCharacter10 from '@/assets/mascots/mascot_character_10.png';
import mascotCharacter11 from '@/assets/mascots/mascot_character_11.png';
import mascotCharacter12 from '@/assets/mascots/mascot_character_12.png';
import mascotCharacter13 from '@/assets/mascots/mascot_character_13.png';
import mascotCharacter14 from '@/assets/mascots/mascot_character_14.png';
import mascotCharacter15 from '@/assets/mascots/mascot_character_15.png';
import mascotCharacter16 from '@/assets/mascots/mascot_character_16.png';
import mascotCharacter17 from '@/assets/mascots/mascot_character_17.png';

const mascots = [
  mascotCharacter1,
  mascotCharacter2,
  mascotCharacter3,
  mascotCharacter4,
  mascotCharacter5,
  mascotCharacter6,
  mascotCharacter7,
  mascotCharacter8,
  mascotCharacter9,
  mascotCharacter10,
  mascotCharacter11,
  mascotCharacter12,
  mascotCharacter13,
  mascotCharacter14,
  mascotCharacter15,
  mascotCharacter16,
  mascotCharacter17,
];

export default mascots;

<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { AlgoliaVariant } from '@/api/algolia';
import { reportEngagement } from '@/api/dynamic-yield/engagement';
import Checkbox from '@/components/base/form/Checkbox.vue';
import Slider from '@/components/base/Slider.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import ProductCardWithOptions from '@/components/product-card/ProductCardWithOptions.vue';
import { Theme as ButtonTheme } from '@/composables/useButtonStyles';
import { useCart } from '@/composables/useCart';
import { SpacingDirection, SpacingLevel, SpacingProps, useSpacing } from '@/composables/useSpacing';
import money from '@/filters/money';
import { BaseChoice, RecommendationsSlot } from '@/lib/personalization/dynamicYield';
import { WebstoreRecommendation } from '@/lib/personalization/webstore';
import { isDefined } from '@/utils/isDefined';
import { displayPrice, ProductCard, ProductCardData } from '@/utils/productCard';

dayjs.extend(relativeTime);

type Recommendation = AlgoliaVariant | RecommendationsSlot | WebstoreRecommendation;

interface Props {
  addedSkus?: Set<string>;
  analyticsList?: string;
  buttonTheme?: ButtonTheme;
  clickTrackable?: boolean;
  controlsColor?: string;
  decisionId?: BaseChoice['decisionId'];
  enableAddToCart?: boolean;
  flushRightOnMobile?: boolean;
  impressionTrackable?: boolean;
  layout?: string;
  link?: string;
  list?: Recommendation[];
  spacingDirection?: SpacingDirection;
  spacingLevel?: SpacingLevel;
  subtitle?: string;
  title?: string;
  trackingEventLocation?: string;
}
const props = withDefaults(defineProps<Props>(), {
  analyticsList: '',
  buttonTheme: 'green',
  clickTrackable: true,
  impressionTrackable: true,
  layout: 'row',
  trackingEventLocation: '',
});

const router = useRouter();
const store = useStore();
const { addToCart } = useCart(store);
const { spacing } = useSpacing(props);

const algoliaVariant = (recommendation: Recommendation): recommendation is AlgoliaVariant =>
  'objectID' in recommendation;
const webstoreRecommendation = (
  product: RecommendationsSlot | WebstoreRecommendation,
): product is WebstoreRecommendation => 'purchased_at' in product;
const recommendations = computed(() =>
  (props.list ?? []).map((recommendation) => {
    let productCard: ProductCardData;
    let purchasedAt: string | undefined;
    if (algoliaVariant(recommendation)) {
      productCard = ProductCard.fromAlgolia(recommendation);
    } else if (webstoreRecommendation(recommendation)) {
      productCard = ProductCard.fromWebstore(recommendation);
      purchasedAt = dayjs().to(dayjs(recommendation.purchased_at));
    } else {
      productCard = ProductCard.fromDY(recommendation);
    }
    return {
      displayPrice: displayPrice(productCard),
      productCard,
      purchasedAt,
      recommendation,
    };
  }),
);

const bulkCheckout = ref<ProductCardData[]>([]);
const easyReorder = computed(
  () =>
    !!recommendations.value.length && recommendations.value.every((r) => isDefined(r.purchasedAt)),
);

const addAllToCart = async () => {
  await Promise.all(
    bulkCheckout.value.map((product) =>
      addToCart(
        {
          cart_sku: {
            sku_external_id: product.sku,
            quantity: 1,
          },
        },
        {
          postAddToCartCallback: () => null,
        },
      ),
    ),
  );
};

const addToBulkCheckout = (product: ProductCardData) => {
  bulkCheckout.value.push(product);
};

const removeFromBulkOrder = (product: ProductCardData) => {
  bulkCheckout.value = bulkCheckout.value.filter((item) => item.sku !== product.sku);
};

const handleCheckbox = (checked: Boolean, product: ProductCardData) => {
  if (checked) {
    addToBulkCheckout(product);
  } else {
    removeFromBulkOrder(product);
  }
};

const emit = defineEmits<{ (e: 'update:addedSkus', value: string): void }>();

const addedItems = useVModel(props, 'addedSkus', emit);
const isAddedToCart = (sku: string) => addedItems.value?.has(sku);

const handleAddToCartClick = async (product: ProductCardData) => {
  addedItems.value?.add(product.sku);
  if (props.decisionId && product.slotId) reportEngagement(props.decisionId, product.slotId);
};

// TODO: consume prices collection
const totalPrice = computed(() =>
  bulkCheckout.value.map((x) => x.price).reduce((prev, curr) => Number(prev) + Number(curr), 0),
);
</script>

<template>
  <div
    v-if="recommendations.length"
    class="flex items-end"
    :class="spacing"
    data-test="recommendations"
  >
    <Slider
      :controlsColor="controlsColor"
      :flushRightOnMobile="flushRightOnMobile"
      justifyContent="start"
      :layout="layout"
      :link="link"
      :list="recommendations"
      :responsiveGaps="{ mobile: 8, tablet: 12 }"
      :subtitle="subtitle"
      :title="title"
      useResponsiveGaps
    >
      <ProductCardWithOptions
        v-for="({ productCard, purchasedAt }, idx) in recommendations"
        :analyticsList="analyticsList"
        :clickTrackable="clickTrackable"
        :decisionId="decisionId"
        :disabledAddToCartButton="isAddedToCart(productCard.sku)"
        :impressionTrackable="impressionTrackable"
        :key="productCard.productKey"
        layout="recommendations"
        :position="idx"
        :product="productCard"
        :slotId="productCard.slotId"
        :trackingEventLocation="trackingEventLocation || title"
        :showVariants="false"
        @added="handleAddToCartClick(productCard)"
      >
        <template #top v-if="easyReorder">
          <Checkbox
            class="absolute p-2 right-1 top-1 easy-reorder"
            @update:modelValue="handleCheckbox($event, productCard)"
          />
        </template>
        <template #subhead v-if="easyReorder">
          <div class="mb-2 text-sm">
            {{ `Bought ${purchasedAt}` }}
          </div>
        </template>
        <template v-if="isAddedToCart(productCard.sku)" #add-to-cart-text>Added</template>
      </ProductCardWithOptions>
    </Slider>
    <div
      v-if="easyReorder"
      class="flex flex-col justify-between w-full p-3 ml-2 bg-white slide-card easy-reorder"
    >
      <div class="flex w-full text-xl font-bold">Easy Reorder</div>
      <div class="flex justify-center w-full">
        <div class="w-20 h-20 rounded-full circle bg-sky-500" />
      </div>
      <div class="flex self-center justify-between pt-3 border-t border-black border-solid w-36">
        <div class="flex items-center text-sm font-semibold">Est. Price</div>
        <div class="text-lg font-semibold">
          {{ money(Math.abs(totalPrice)) }}
        </div>
      </div>
      <ThemedButton
        class="w-full mb-2"
        :disabled="bulkCheckout.length === 0"
        id="buyItAgainAddAllToCart"
        size="small"
        theme="gray"
        @click="addAllToCart"
      >
        Add All to Cart
      </ThemedButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.slide-card {
  scroll-snap-align: start;
  min-width: 154px;
  max-width: 173px;
  border: 1px solid #e8e8e8;
}
.easy-reorder {
  display: none;
}
.circle {
  background: #6fcee4 url('https://nuts.com/images/pdp/2022/shop-bag.e53f4a9a.png') no-repeat
    center/50px 50px;
}

@media screen and (min-width: 1100px) {
  .slide-card {
    max-width: 198px;
    height: 348px;
  }
  .easy-reorder {
    display: flex;
  }
}
</style>

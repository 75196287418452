<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import SearchBarStaticContent from '@/components/layout/header/SearchBarStaticContent.vue';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { CmsRegisteredComponent } from '@/utils/cms';

const SearchMobile = defineComponent({
  name: 'SearchMobile',
  components: {
    SearchBarStaticContent,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { loadDyFlags } = useFeatureFlags(store);

    const searchQuery = ref('');
    const search = async () => {
      const query = searchQuery.value || '';
      let href = '/search/instant';
      if (query) {
        href += `?query=${query}`;
      }

      await loadDyFlags('[A/B Test] Search Page Visual Changes', ['searchPageLayoutChanges']);
      router.push(href);
    };
    return {
      search,
      searchQuery,
    };
  },
});

export const SearchMobileRegistration: CmsRegisteredComponent = {
  component: SearchMobile,
  name: 'Mobile Search Bar',
};

export default SearchMobile;
</script>

<template>
  <div class="visible-xs section masthead-alternative">
    <div class="ptn-search-box">
      <form class="relative ws-sm:static" @submit.prevent="search">
        <input
          type="search"
          class="search-field"
          data-test="search-field-mobile"
          placeholder="Search for a product"
          name="query"
          title="Search"
          aria-label="Search"
          v-model="searchQuery"
        />
        <SearchBarStaticContent />
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-field {
  border-radius: 4px;
  padding: 12px 100px 12px 12px;
  height: 39px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid $eastern-blue;
}
</style>

type FbContentType = 'product_group';

export interface FbPurchaseEvent {
  custom_data: {
    content_type: FbContentType;
    content_ids: string[];
    value: number;
    currency: string;
  };
  user_data?: {
    em: string;
  };
}

export function sendConversionEvent(eventName: 'Purchase', data: FbPurchaseEvent) {
  navigator.sendBeacon('/api/events/fb', JSON.stringify({ ...data, event_name: eventName }));
}

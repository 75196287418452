import { Customer, DiscountCode } from '@commercetools/platform-sdk';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { CarrierOptions, CreditAccount, Issue } from '@/api/customer';
import { NutsAddress } from '@/utils/address';

interface Contact {
  email: string;
  firstName?: string;
  id: number;
}

export interface CustomerState {
  addresses: NutsAddress[];
  adminUrl?: string;
  allowSplitShipments?: boolean;
  businessIndustry?: string;
  businessName?: string;
  carrierOptions?: CarrierOptions;
  contact?: Contact;
  creditAccount?: CreditAccount;
  customer?: Customer;
  customerDiscountCodes: DiscountCode[];
  hasBusinessAccount: boolean;
  issues: Issue[];
  notes?: string;
  signedUpAt?: string;
  storeCredit?: CreditAccount;
  subscribed?: boolean;
}

export const useCustomer = defineStore('customer', () => ({
  businessIndustry: ref<CustomerState['businessIndustry']>(),
  businessName: ref<CustomerState['businessName']>(),
  contact: ref<CustomerState['contact']>(),
  hasBusinessAccount: ref<CustomerState['hasBusinessAccount']>(false),
}));

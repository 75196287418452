<script setup lang="ts">
import { money } from '@nuts/auto-delivery-sdk/dist/utils/format';
import { useIntersectionObserver } from '@vueuse/core';
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { getPredictedShippingCutoff, ShippingCutoff } from '@/api/predictedShipping';
import CheckmarkIcon from '@/components/base/assets/CheckmarkIcon.vue';
import Modal from '@/components/base/layout/Modal.vue';
import PredictedShippingCutoffText from '@/components/base/PredictedShippingCutoffText.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import Header4 from '@/components/base/typography/Header4.vue';
import Header5 from '@/components/base/typography/Header5.vue';
import LargeBodyText from '@/components/base/typography/LargeBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import ActionBlock from '@/components/cart/added-modal/ActionBlock.vue';
import LineItem from '@/components/cart/added-modal/LineItem.vue';
import RecommendationsSection from '@/components/cart/added-modal/RecommendationsSection.vue';
import ShippingMessage from '@/components/cart/ShippingMessage.vue';
import { useCurrentRoute } from '@/composables/navigation/useCurrentRoute';
import { useCallback } from '@/composables/useCallback';
import { useCart } from '@/composables/useCart';
import { useDelivery } from '@/composables/useDelivery';
import { usePayment } from '@/composables/usePayment';
import { NutsLineItem } from '@/lib/cart/lineItem';
import { useInitialRequest } from '@/stores/initialRequest';
import { formatPurchaseItem, gtag } from '@/utils/analytics';

const props = defineProps<{
  addOns?: NutsLineItem[];
  isOpen: boolean;
  lineItem: NutsLineItem;
}>();

const emit = defineEmits(['handle-close']);

const route = useRoute();
const store = useStore();

const { loadLineItemExpansions, lineItems } = useCart(store);
const { path } = useCurrentRoute(useInitialRequest().url, route);

const { estimatedAmountToCharge, estimatedTotal } = usePayment(store, ref(false));
const { freeShippingThreshold } = useDelivery(store);

const loadExpansions = useCallback(async () => {
  await loadLineItemExpansions();
});

const shippingCutoff = ref<ShippingCutoff>();

const refreshContent = async () => {
  await loadExpansions.execute();
  shippingCutoff.value = await getPredictedShippingCutoff();
  if (typeof window !== 'undefined') {
    window.globalShippingCutoff = shippingCutoff.value;
  }
};

onMounted(() => {
  refreshContent();
  gtag('event', 'view_cart', {
    location: path.value,
    currency: 'USD',
    value: money(estimatedAmountToCharge?.value),
    items: lineItems.value.map(formatPurchaseItem),
  });
});
watch(() => props.lineItem.id, refreshContent);

const handleClose = () => {
  emit('handle-close');
};

const closeIcon = nutshell['img/close.svg'];

const modalHeader = ref<HTMLElement>();
const modalHeaderIsVisible = ref(true);

useIntersectionObserver(modalHeader, ([{ isIntersecting }]) => {
  modalHeaderIsVisible.value = isIntersecting;
});
</script>

<template>
  <Modal
    anchor="right"
    aria-label="cart added modal"
    data-test="cart-added-modal"
    borderRadius="rounded-none"
    :hasControls="false"
    :hasDefaultPadding="false"
    :isOpen
    width="w-full md:max-w-xl"
    @handle-close="handleClose"
  >
    <template #close-button>
      <UnstyledButton
        class="absolute flex items-center justify-center w-6 h-6 p-1 overflow-hidden rounded right-5 bg-white/50"
        :class="shippingCutoff ? 'top-14' : 'top-5'"
        data-promo="1"
        data-promo-creative="Add to cart modal"
        data-promo-name="Closed by X"
        @click="handleClose"
      >
        <img alt="Close" class="w-4 h-4 shrink-0" data-test="close-modal" :src="closeIcon" />
      </UnstyledButton>
    </template>
    <template #body>
      <div v-if="lineItem" class="flex flex-col overflow-auto h-80vh" data-test="modal-content">
        <BaseBodyText
          v-if="shippingCutoff"
          class="py-1 text-center bg-nuts-amber-200"
          data-test="predicted-shipping-message"
        >
          <PredictedShippingCutoffText :shippingCutoff />
        </BaseBodyText>

        <!-- Header -->
        <div
          class="flex items-center px-4 mt-5 mb-4 md:mb-0"
          data-test="cart-modal-header"
          ref="modalHeader"
        >
          <CheckmarkIcon
            aria-hidden="true"
            class="text-nuts-lime-800"
            data-test="check-mark"
            :size="[24, 32]"
          />
          <Header4 class="ml-2">Added to Cart</Header4>
        </div>

        <div class="flex-1 overflow-auto md:overflow-visible md:contents">
          <!-- Image, quantity and price -->
          <LineItem
            :lineItemWithQuantitySubset="lineItem"
            :loadExpansionsPending="loadExpansions.isPending"
            :showProductName="!!addOns?.length"
            class="mt-4"
            @handle-close="handleClose"
          />

          <template v-if="addOns?.length">
            <LineItem
              v-for="addOn in addOns"
              :key="addOn.id"
              :lineItemWithQuantitySubset="addOn"
              :loadExpansionsPending="loadExpansions.isPending"
              showProductName
              class="mt-4"
              @handle-close="handleClose"
            />
          </template>

          <ShippingMessage
            class="mt-4"
            :freeShippingThreshold
            stackVertically
            :totalPrice="estimatedTotal"
          />

          <div class="relative p-4 bg-white md:hidden">
            <ActionBlock @handle-close="handleClose" />
          </div>

          <RecommendationsSection :sku="lineItem.variant.sku" @handle-close="handleClose" />
        </div>

        <!-- Footer & CTAs -->
        <div class="fixed bottom-0 hidden w-full p-4 bg-white md:max-w-xl md:visible md:block">
          <div class="absolute inset-0 pointer-events-none footer-shadow" />
          <div class="flex items-center justify-between" data-test="estimated-total">
            <div class="flex items-center">
              <Header5>Estimated total</Header5>
              <BaseBodyText class="ml-1.5">
                ({{ lineItems.length }} item{{ lineItems.length > 1 ? 's' : '' }})
              </BaseBodyText>
            </div>
            <LargeBodyText>{{ money(estimatedTotal) }}</LargeBodyText>
          </div>
          <ActionBlock class="mt-4" @handle-close="handleClose" />
        </div>
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.footer-shadow {
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1), 0px -8px 15px -3px rgba(0, 0, 0, 0.1);
}

.h-80vh {
  max-height: 80vh;
  @media screen and (min-width: 768px) {
    max-height: 100vh;
  }
}

:deep(.right-anchor-height) {
  overflow: hidden;
}
</style>

import fromPairs from 'lodash/fromPairs';
import truncate from 'lodash/truncate';
import { computed } from 'vue';
import { Store } from 'vuex';

import { NutsAddress } from '@/utils/address';

export function useAddressBook(store: Store<any>) {
  const addresses = computed<NutsAddress[]>(() => store.state.customerModule.addresses);

  const addressSummaries = computed(() =>
    fromPairs<string>(
      addresses.value.map((address) => {
        const { name, street1, street2 } = address;
        return [
          address.id!,
          truncate(
            [[name, street1].filter(Boolean).join(' - '), street2].filter(Boolean).join(' '),
            { length: 40 },
          ),
        ];
      }),
    ),
  );

  return {
    addresses,
    addressSummaries,

    async addAddress(address: NutsAddress): Promise<NutsAddress> {
      return store.dispatch('customerModule/addAddress', address);
    },

    async fetchAddresses() {
      await store.dispatch('customerModule/getAvailableUniqueAddresses');
    },

    async updateAddress(address: NutsAddress) {
      return store.dispatch('customerModule/updateAddress', address);
    },
  };
}

export default {};

<script setup lang="ts">
import { BuilderContent, Content, isEditing, isPreviewing } from '@builder.io/sdk-vue';
import { computed, ref, UnwrapRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { getIndex, init as initAlgolia, searchProducts } from '@/api/algolia';
import { getConfigEntry } from '@/api/config';
import B2BRegistrationButton, {
  B2BRegistrationButtonRegistration,
} from '@/components/b2b/B2BRegistrationButton.vue';
import BaseFAQ, { BaseFAQRegistration } from '@/components/base/BaseFAQ.vue';
import ButtonOpeningModal, {
  ButtonOpeningModalRegistration,
} from '@/components/base/ButtonOpeningModal.vue';
import Carousel, { CarouselRegistration } from '@/components/base/carousel/Carousel.vue';
import ChatNow, { ChatNowRegistration } from '@/components/base/ChatNow.vue';
import Checkbox, { CheckboxRegistration } from '@/components/base/form/Checkbox.vue';
import FormInputWrapper, {
  FormInputWrapperRegistration,
} from '@/components/base/form/FormInputWrapper.vue';
import FormWrapper, { FormWrapperRegistration } from '@/components/base/form/FormWrapper.vue';
import OutlinedSelectWrapper, {
  OutlinedSelectWrapperRegistration,
} from '@/components/base/form/OutlinedSelectWrapper.vue';
import BannerSection, {
  BannerSectionRegistration,
} from '@/components/base/layout/BannerSection.vue';
import BulletedList, { BulletedListRegistration } from '@/components/base/layout/BulletedList.vue';
import CardGrid, { CardGridRegistration } from '@/components/base/layout/CardGrid.vue';
import CarouselContainer, {
  CarouselContainerRegistration,
} from '@/components/base/layout/CarouselContainer.vue';
import HeroSection, { HeroSectionRegistration } from '@/components/base/layout/HeroSection.vue';
import HeroSectionWithVideo, {
  HeroSectionWithVideoRegistration,
} from '@/components/base/layout/HeroSectionWithVideo.vue';
import MarqueeBanner, {
  MarqueeBannerRegistration,
} from '@/components/base/layout/MarqueeBanner.vue';
import SearchFooter, { SearchFooterRegistration } from '@/components/base/layout/SearchFooter.vue';
import ShelfBannerSection, {
  ShelfBannerRegistration,
} from '@/components/base/layout/ShelfBannerSection.vue';
import TopCategoryCard, {
  TopCategoryCardRegistration,
} from '@/components/base/layout/TopCategoryCard.vue';
import SearchMobile, { SearchMobileRegistration } from '@/components/base/SearchMobile.vue';
import ShopableIngredients, {
  ShopableIngredientsRegistration,
} from '@/components/base/ShopableIngredients.vue';
import Slider, { SliderRegistration } from '@/components/base/Slider.vue';
import ThemedButton, { ButtonRegistration } from '@/components/base/ThemedButton.vue';
import BaseBodyText, {
  BaseBodyTextRegistration,
} from '@/components/base/typography/BaseBodyText.vue';
import Caption, { CaptionRegistration } from '@/components/base/typography/Caption.vue';
import Header1, { Header1Registration } from '@/components/base/typography/Header1.vue';
import Header2, { Header2Registration } from '@/components/base/typography/Header2.vue';
import Header3, { Header3Registration } from '@/components/base/typography/Header3.vue';
import Header4, { Header4Registration } from '@/components/base/typography/Header4.vue';
import Header5, { Header5Registration } from '@/components/base/typography/Header5.vue';
import Header6, { Header6Registration } from '@/components/base/typography/Header6.vue';
import LargeBodyText, {
  LargeBodyTextRegistration,
} from '@/components/base/typography/LargeBodyText.vue';
import SmallBodyText, {
  SmallBodyTextRegistration,
} from '@/components/base/typography/SmallBodyText.vue';
import SuperTitle, { SuperTitleRegistration } from '@/components/base/typography/SuperTitle.vue';
import UnstyledButton, { UnstyledButtonRegistration } from '@/components/base/UnstyledButton.vue';
import CorporateGiftsInquiryForm, {
  CorporateGiftsInquiryFormRegistration,
} from '@/components/corporate-gifts/CorporateGiftsInquiryForm.vue';
import OurGiftOptions, {
  OurGiftOptionsRegistration,
} from '@/components/digital-gift/OurGiftOptions.vue';
import SimplifiedFooter from '@/components/layout/footer/SimplifiedFooter.vue';
import SimplifiedHeader, {
  SimplifiedHeaderRegistration,
} from '@/components/layout/header/SimplifiedHeader.vue';
import DyProductRecommendationsRow, {
  DyProductRecommendationsRowRegistration,
} from '@/components/recommendations/DyProductRecommendationsRow.vue';
import ProductRecommendationsWithOptions, {
  ProductRecommendationsWithOptionsRegistration,
} from '@/components/recommendations/ProductRecommendationsWithOptions.vue';
import RecommendationsWithFilters, {
  RecommendationsWithFiltersRegistration,
} from '@/components/recommendations/RecommendationsWithFilters.vue';
import ReorderRecommendations, {
  ReorderRecommendationsRegistration,
} from '@/components/recommendations/ReorderRecommendations.vue';
import ReferralCard, { ReferralCardRegistration } from '@/components/referral/ReferralCard.vue';
import ResultCard, { ResultCardRegistration } from '@/components/search/products/ResultCard.vue';
import { useCurrentRoute } from '@/composables/navigation/useCurrentRoute';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { ContentModel } from '@/composables/useCms';
import { useCustomer } from '@/composables/useCustomer';
import { useInitialRequest } from '@/stores/initialRequest';
import { DataPromo } from '@/utils/analytics';
import { CmsRegisteredComponent } from '@/utils/cms';

const ScriptSetupAdapters: CmsRegisteredComponent[] = [
  { ...BulletedListRegistration, component: BulletedList },
  { ...ButtonOpeningModalRegistration, component: ButtonOpeningModal },
  { ...CarouselRegistration, component: Carousel },
  { ...CheckboxRegistration, component: Checkbox },
  { ...DyProductRecommendationsRowRegistration, component: DyProductRecommendationsRow },
  { ...FormWrapperRegistration, component: FormWrapper },
  { ...FormInputWrapperRegistration, component: FormInputWrapper },
  { ...MarqueeBannerRegistration, component: MarqueeBanner },
  { ...OutlinedSelectWrapperRegistration, component: OutlinedSelectWrapper },
  {
    ...ProductRecommendationsWithOptionsRegistration,
    component: ProductRecommendationsWithOptions,
  },
  { ...RecommendationsWithFiltersRegistration, component: RecommendationsWithFilters },
  { ...ReferralCardRegistration, component: ReferralCard },
  { ...ReorderRecommendationsRegistration, component: ReorderRecommendations },
  { ...ResultCardRegistration, component: ResultCard },
  { component: SimplifiedFooter, name: 'Simplified Footer' },
  { ...ShopableIngredientsRegistration, component: ShopableIngredients },
  { ...SimplifiedHeaderRegistration, component: SimplifiedHeader },
  { ...SliderRegistration, component: Slider },
  { ...UnstyledButtonRegistration, component: UnstyledButton },
];

const REGISTERED_COMPONENTS: CmsRegisteredComponent[] = [
  B2BRegistrationButtonRegistration,
  BaseBodyTextRegistration,
  BaseFAQRegistration,
  ButtonRegistration,
  BannerSectionRegistration,
  CaptionRegistration,
  CardGridRegistration,
  CarouselContainerRegistration,
  ChatNowRegistration,
  CorporateGiftsInquiryFormRegistration,
  Header1Registration,
  Header2Registration,
  Header3Registration,
  Header4Registration,
  Header5Registration,
  Header6Registration,
  HeroSectionRegistration,
  HeroSectionWithVideoRegistration,
  LargeBodyTextRegistration,
  OurGiftOptionsRegistration,
  SearchFooterRegistration,
  SearchMobileRegistration,
  ShelfBannerRegistration,
  SmallBodyTextRegistration,
  SuperTitleRegistration,
  TopCategoryCardRegistration,
  ...ScriptSetupAdapters,
];

interface CmsContext {
  error?: Error;
  loading: boolean;
  result?: BuilderContent | UnwrapRef<BuilderContent> | null;
}

const props = defineProps<{
  content?: CmsContext;
  dataPromo?: DataPromo;
  model: ContentModel;
}>();

const canShowContentHistory: any[] = [];
let everFailedToShowContent = false;
const algoliaClient = initAlgolia();
const productsIndex = getIndex(algoliaClient, 'Products');
const logFallback = (refName: string, value: boolean) => {
  const message = `CmsContent ${refName} ${value}`;
  const attributes = {
    model: props.model,
    content: typeof props.content,
    result: props.content?.result === null ? 'null' : typeof props.content?.result,
    loading: `${props.content?.loading}`,
    error: typeof props.content?.error,
    history: JSON.stringify(canShowContentHistory),
  };
  if (typeof window === 'undefined') {
    console.error(message, attributes);
  }
  return value;
};

const apiKey = getConfigEntry('builderIo').key;
const { query } = useCurrentRoute(useInitialRequest().url, useRoute());
const canShowContent = computed<boolean>(() => {
  // Builder's `Search` conforms to the object from VueRouter's `query` property but TS doesn't know that
  const search = query.value as URLSearchParams;
  const value = !!props.content?.result || isEditing(search) || isPreviewing(search);
  everFailedToShowContent ||= !value;
  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
  canShowContentHistory.push([Math.round(performance.now()), value, props.content?.loading]);
  if (props.model === 'page' && everFailedToShowContent) {
    logFallback('canShowContent', value);
  }
  return value;
});
const showFallback = computed<boolean>(() => logFallback('showFallback', !props.content?.loading));
const registeredComponents = () => REGISTERED_COMPONENTS;

const { hasBusinessAccount } = useCustomer(useStore());
const context = {
  algoliaSearch: searchProducts,
  hasBusinessAccount: hasBusinessAccount.value,
  productsIndex,
};

const contentRoot = ref<HTMLElement>();
useRouterLinks(contentRoot, useRouter());

defineOptions({
  components: {
    B2BRegistrationButton,
    BannerSection,
    BaseBodyText,
    BaseFAQ,
    BulletedList,
    ButtonOpeningModal,
    Caption,
    CardGrid,
    Carousel,
    CarouselContainer,
    ChatNow,
    Checkbox,
    CorporateGiftsInquiryForm,
    DyProductRecommendationsRow,
    FormInputWrapper,
    FormWrapper,
    Header1,
    Header2,
    Header3,
    Header4,
    Header5,
    Header6,
    HeroSection,
    HeroSectionWithVideo,
    LargeBodyText,
    MarqueeBanner,
    OurGiftOptions,
    OutlinedSelectWrapper,
    ProductRecommendationsWithOptions,
    RecommendationsWithFilters,
    ReferralCard,
    ReorderRecommendations,
    ResultCard,
    SearchFooter,
    SearchMobile,
    ShelfBannerSection,
    ShopableIngredients,
    SimplifiedFooter,
    SimplifiedHeader,
    Slider,
    SmallBodyText,
    SuperTitle,
    ThemedButton,
    TopCategoryCard,
    UnstyledButton,
  },
});
</script>

<template>
  <div class="cms-content">
    <div
      v-if="canShowContent"
      :data-promo="dataPromo?.type"
      :data-promo-creative="
        dataPromo ? `${dataPromo?.creative} ${content?.result?.name}` : undefined
      "
      :data-promo-name="dataPromo?.name"
      :class="{ 'animate-pulse': content?.loading }"
      ref="contentRoot"
    >
      <Content
        :apiKey="apiKey"
        :content="content?.result"
        :customComponents="registeredComponents()"
        :key="content?.result?.id"
        :model="model"
        :context="context"
      />
    </div>
    <slot v-else-if="!content?.loading" :error="content?.error" />
  </div>
</template>

<style lang="scss" scoped>
:deep(a) {
  color: inherit;
  text-decoration: none;
}
:deep(p) {
  margin-bottom: 0;
}
:deep(em) {
  font-style: italic;
}
:deep(h1) {
  margin-bottom: 0;
  text-transform: none;
}
:deep(h2) {
  margin-bottom: 0;
  text-transform: none;
}
</style>

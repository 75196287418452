<script>
import { mapActions } from 'vuex';

export default {
  name: 'KeywordFilter',
  methods: {
    ...mapActions('customTraysModule', ['setActiveKeyword']),
    keywordChanged(e) {
      this.setActiveKeyword(e.target.value.toLowerCase());
      this.$emit('filters:changed');
    },
  },
};
</script>

<template>
  <form data-test="keyword-filter" @submit.prevent>
    <label for="keyword" aria-label="Search Products" />
    <input
      class="w-full px-5 py-2 border border-solid focus:outline-none font-proxima-nova rounded-3xl"
      type="text"
      id="keyword"
      placeholder="Search Products"
      @keyup="keywordChanged"
    />
  </form>
</template>

/* eslint-disable import/prefer-default-export */

import { gtag } from '@/utils/analytics';
/**
 * Helper to (a) log error to console, (b) return a string form of error for
 * easy use without type guards.
 *
 * @param error Error object or message
 * @param message Optional message
 * @param otherAttributes to send
 * @returns string form of `error`, prefixed if `message` provided
 */

export function reportError(
  error: any,
  message?: string,
  otherAttributes?: Record<string, string | number>,
): string {
  const customAttributes = { ...otherAttributes };
  if (message) {
    customAttributes.message = message;
    console.error(`${message}:`, error, otherAttributes);
  } else {
    console.error(error, otherAttributes);
  }
  if (typeof error === 'string' || error instanceof Error) {
    const errorMessage = typeof error === 'string' ? error : error.message;
    if (typeof window !== 'undefined') {
      gtag('event', 'exception', { description: errorMessage, fatal: false });
      window.nutsServerLog?.('report-error', {
        error: typeof error === 'string' ? error : error.stack ?? error.message,
        ...customAttributes,
      });
    }
    return errorMessage;
  }
  return 'Unknown error';
}

<script setup lang="ts">
import { computedWithControl, useScroll, useWindowSize } from '@vueuse/core';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { AlgoliaCategory } from '@/api/algolia';
import ChevronIcon from '@/components/base/assets/ChevronIcon.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { isHTMLElement } from '@/utils/browser';

withDefaults(
  defineProps<{
    categories: AlgoliaCategory[];
    removeListGutters?: boolean;
    biggerImages?: boolean;
    variant?: 'button' | 'link';
  }>(),
  {
    variant: 'link',
  },
);

defineEmits<{ click: [string] }>();

const { width: windowWidth } = useWindowSize();

const rootContainer = ref<HTMLElement>();
const scrollContainer = ref<HTMLElement>();

const { arrivedState } = useScroll(scrollContainer);

const suggestionsOverflowing = computedWithControl(
  () => [windowWidth.value, scrollContainer.value],
  () => {
    if (!scrollContainer.value) return false;
    const { childElementCount, firstElementChild, offsetWidth } = scrollContainer.value;
    if (childElementCount && firstElementChild && isHTMLElement(firstElementChild)) {
      return childElementCount * (firstElementChild.offsetWidth + 16) > offsetWidth;
    }
    return false;
  },
);

const scrollByDirection = (direction: 'next' | 'prev') => {
  if (!scrollContainer.value) return;
  const scrollFactor = direction === 'prev' ? -1 : 1;
  scrollContainer.value.scrollBy({
    left: scrollContainer.value.offsetWidth * scrollFactor,
    behavior: 'smooth',
  });
};

useRouterLinks(rootContainer, useRouter());
</script>

<template>
  <div class="flex flex-col gap-2" ref="rootContainer">
    <slot name="title">
      <SmallBodyText class="px-4 text-neutral-500">Featured Categories</SmallBodyText>
    </slot>
    <div class="relative">
      <transition name="fade" :duration="100">
        <UnstyledButton
          v-show="!arrivedState.left && suggestionsOverflowing"
          aria-label="previous slide"
          class="absolute hidden overflow-hidden -translate-y-1/2 bg-white rounded-full shadow-md lg:visible lg:block active:bg-neutral-200 hover:bg-neutral-100 left-4 top-12"
          data-test="previous-categories-slide-button"
          @click="scrollByDirection('prev')"
        >
          <span class="flex p-3 border border-solid rounded-full border-neutral-300">
            <ChevronIcon class="text-neutral-500" direction="left" />
          </span>
        </UnstyledButton>
      </transition>
      <ul
        class="grid gap-4 lg:px-0 lg:flex grid-cols-[repeat(auto-fill,minmax(6rem,1fr))] overflow-x-auto scrollbar-hide items-start"
        :class="{ 'px-4': !removeListGutters }"
        ref="scrollContainer"
      >
        <li
          v-for="category in categories"
          class="flex-1 lg:flex-none"
          :class="{ 'first:lg:ml-4 last:lg:mr-4': !removeListGutters }"
          :key="category.objectID"
        >
          <component
            :is="variant === 'button' ? UnstyledButton : 'a'"
            class="flex flex-col justify-center gap-2 hover:underline link-text-black"
            :class="biggerImages ? 'lg:w-32' : 'lg:w-24'"
            data-test="category-item"
            :href="variant === 'link' ? category.path : undefined"
            @click="variant === 'button' ? $emit('click', category.name) : undefined"
          >
            <img
              alt=""
              class="flex object-contain rounded-[100%] aspect-square border border-solid border-neutral-300"
              :class="biggerImages ? 'lg:h-32 lg:w-32' : 'lg:h-24 lg:w-24'"
              :src="category.image_url"
            />
            <SmallBodyText class="self-center font-semibold text-center">
              {{ category.name }}
            </SmallBodyText>
          </component>
        </li>
      </ul>
      <transition name="fade" :duration="100">
        <UnstyledButton
          aria-label="next slide"
          v-show="!arrivedState.right && suggestionsOverflowing"
          class="absolute hidden overflow-hidden -translate-y-1/2 bg-white rounded-full shadow-md top-12 lg:visible lg:block active:bg-neutral-200 hover:bg-neutral-100 right-4"
          data-test="next-categories-slide-button"
          @click="scrollByDirection('next')"
        >
          <span class="flex p-3 border border-solid rounded-full border-neutral-300">
            <ChevronIcon class="text-neutral-500" direction="right" />
          </span>
        </UnstyledButton>
      </transition>
    </div>
  </div>
</template>

<style scoped>
.link-text-black {
  @apply text-black;
}
</style>

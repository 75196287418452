<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import { getConfigEntry } from '@/api/config';
import ThemedButton from '@/components/base/ThemedButton.vue';
import { useAuth } from '@/composables/useAuth';
import { useCallback } from '@/composables/useCallback';
import { useCart } from '@/composables/useCart';

const props = defineProps<{
  fullWidth?: boolean;
  isCheckout?: boolean;
}>();

const emit = defineEmits<{ 'signed-in': [] }>();

const store = useStore();
const { loadCart, loadLineItemExpansions, removeGreetingCards } = useCart(store);

const { appleCodeSignIn } = useAuth(store);

const isAppleLoaded = ref(false);

const initApple = async () => {
  if (!isAppleLoaded.value) {
    window.AppleID.auth.init({
      clientId: getConfigEntry('apple').clientId,
      scope: 'name email',
      redirectURI: `${window.location.origin}/api/sign/apple`,
      state: 'loginModal',
      usePopup: true,
    });
    isAppleLoaded.value = true;
  }
};

const loadAppleSDK = async () => {
  const id = 'apple-js-sdk';
  const scriptString = 'script';

  const fjs = document.getElementsByTagName(scriptString)[0];
  if (document.getElementById(id)) {
    return;
  }
  const js = document.createElement(scriptString);
  js.id = id;
  js.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
  if (fjs && fjs.parentNode) {
    fjs.parentNode.insertBefore(js, fjs);
  } else {
    document.head.appendChild(js);
  }
};

const signIn = useCallback(
  async (response: {
    authorization: { code: string };
    user?: {
      email: string;
      name: { firstName: string; lastName: string };
    };
  }) => {
    const signInResponse = await appleCodeSignIn(response, { preserveCart: props.isCheckout });
    if (signInResponse) {
      emit('signed-in');
      await loadCart(true);
      await Promise.all([loadLineItemExpansions(), removeGreetingCards()]);
    }
  },
);

const login = async () => {
  try {
    await initApple();
    const response = await window.AppleID.auth.signIn();
    signIn.execute({ ...response });
  } catch (error) {
    console.error('User cancelled login or did not fully authorize.');
  }
  return false;
};

onMounted(() => {
  loadAppleSDK();
});
</script>

<template>
  <div>
    <ThemedButton
      class="sm:h-12 h-11"
      :fullWidth="fullWidth"
      :isLoading="signIn.isPending"
      theme="white"
      @click="login"
    >
      <img
        v-if="!signIn.isPending"
        aria-hidden="true"
        alt="apple"
        class="flex-shrink-0 object-contain w-6 h-6 mr-2"
        src="@/assets/social/apple.svg"
      />
      Continue with Apple
    </ThemedButton>
  </div>
</template>

<script setup lang="ts">
import { cents } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { computed, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';

import { browseProducts, getIndex, init as initAlgolia } from '@/api/algolia';
import { catalog, GiftedTrayIngredient } from '@/api/gifted-tray';
import Modal from '@/components/base/layout/Modal.vue';
import CategoryFilterControlPanel from '@/components/custom-trays/CategoryFilterControlPanel.vue';
import KeywordFilter from '@/components/custom-trays/KeywordFilter.vue';
import TagFilterControlPanel from '@/components/custom-trays/TagFilterControlPanel.vue';
import CustomizerCategoryFilterControlPanel from '@/components/customizer/CustomizerCategoryFilterControlPanel.vue';
import CustomizerKeywordFilter from '@/components/customizer/CustomizerKeywordFilter.vue';
import CustomizerTagFilterControlPanel from '@/components/customizer/CustomizerTagFilterControlPanel.vue';
import { Category } from '@/lib/customizers/common';
import { CustomProductType } from '@/lib/customizers/digitalGifting';
import { SkuIngredient } from '@/lib/customizers/ingredients';
import { PopularitySort, PriceSort } from '@/store/modules/customTrays';
import { isDefined } from '@/utils/isDefined';

const props = withDefaults(
  defineProps<{
    isOpen: boolean;
    type: CustomProductType;
  }>(),
  {
    type: CustomProductType.Tray,
  },
);

const emit = defineEmits(['close-modal']);

const store = useStore();
const trayActiveFilters = computed(() => store.state.customTraysModule.activeFilters);
const trayActiveKeyword = computed(() => store.state.customTraysModule.activeKeyword);
const trayActiveIngredientId = computed(() => store.state.customTraysModule.activeIngredientId);
const trayActiveSort = computed(() => store.state.customTraysModule.activeSort);
const fullIngredientsCatalog = ref<GiftedTrayIngredient[]>();
const catalogCategoryTree = ref();

const showBox = computed(() => props.type === CustomProductType.Box);

const ingredients = computed(() => {
  if (fullIngredientsCatalog.value) {
    return fullIngredientsCatalog.value
      .map((ingredient) => {
        let show = trayActiveFilters.value?.every((v: string) => ingredient.filters.includes(v));

        if (trayActiveKeyword.value?.length > 1) {
          const nameContainsKeyword =
            ingredient.name.toLowerCase().indexOf(trayActiveKeyword.value) !== -1;
          const filtersContainKeyword =
            ingredient.filters.filter(
              (val) => val.toLowerCase().indexOf(trayActiveKeyword.value) !== -1,
            ).length > 0;
          show = show && (nameContainsKeyword || filtersContainKeyword);
        }
        return {
          ...ingredient,
          active: trayActiveIngredientId.value === ingredient.id,
          show,
        };
      })
      .sort((ingredientL, ingredientR) => {
        let diff = 0;

        if (trayActiveSort.value === PopularitySort) {
          diff = ingredientR.popularity - ingredientL.popularity;
        } else if (trayActiveSort.value === PriceSort) {
          diff =
            ingredientR.priceRange.length - ingredientL.priceRange.length ||
            ingredientR.priceLo - ingredientL.priceLo;
        }

        if (Math.abs(diff) > 0.0000001) {
          return diff;
        }

        return ingredientL.name.toLowerCase() > ingredientR.name.toLowerCase() ? 1 : -1;
      });
  }
  return [];
});

// BOX LOGIC //
const activeFilters = ref<string[]>([]);
const activeKeyword = ref<string>();
const ingredientsCatalog = ref<SkuIngredient[]>();

const loadBoxIngredients = async () => {
  const algoliaClient = initAlgolia();
  const productsIndex = getIndex(algoliaClient, 'Products');
  const products = await browseProducts(productsIndex, {
    query: 'mini box',
    restrictSearchableAttributes: ['shortVariantName'],
    typoTolerance: false,
  });

  ingredientsCatalog.value = products
    .filter((product) => !product.outOfStock)
    .map((product) => {
      try {
        const ingredient = SkuIngredient.fromAlgoliaVariant(product);
        return ingredient;
      } catch (e) {
        return undefined;
      }
    })
    .filter(isDefined);
};

const categoryTree = computed(() => {
  const parsedCategories = new Map<string, Set<string> | undefined>();
  ingredientsCatalog.value?.forEach((data) => {
    data.hierarchicalFacet.lvl0?.forEach((item) => {
      parsedCategories.set(item, undefined);
    });
  });
  const categories: Category[] = [];
  parsedCategories.forEach((value, key) => {
    categories.push({
      name: key,
      filter: key,
      children: Array.from(value ?? []).map((child) => ({
        name: child,
        filter: `${key} > ${child}`,
        children: [],
      })),
    });
  });
  return categories;
});

const activeIngredients = computed(() => {
  if (activeFilters.value.length === 0 && !activeKeyword.value) {
    return ingredientsCatalog.value;
  }
  const defaultSorted = ingredientsCatalog.value?.filter((ingredient) => {
    const { hierarchicalFacet } = ingredient;
    const categories = [...(hierarchicalFacet.lvl0 ?? []), ...(hierarchicalFacet.lvl1 ?? [])];

    const matchesFilters = activeFilters.value.every(
      (filter) => categories.includes(filter) || ingredient.tags.includes(filter),
    );

    if (activeKeyword.value && activeKeyword.value.length > 1) {
      const nameContainsKeyword = ingredient.name.toLowerCase().indexOf(activeKeyword.value) !== -1;
      const filtersContainKeyword =
        categories.filter((val) => val.toLowerCase().indexOf(activeKeyword.value!) !== -1).length >
        0;
      return matchesFilters && (nameContainsKeyword || filtersContainKeyword);
    }

    return matchesFilters;
  });

  return [...defaultSorted].sort((a, b) => {
    if ('volumePrice' in a && 'volumePrice' in b) {
      return cents(a.volumePrice) - cents(b.volumePrice);
    }
    if ('singlePiecePrice' in a && 'singlePiecePrice' in b) {
      return cents(a.singlePiecePrice) - cents(b.singlePiecePrice);
    }
    return 0;
  });
});

const toggleActiveCategory = (category: string) => {
  if (category === 'all') {
    activeFilters.value = [];
    return;
  }

  const active = activeFilters.value.filter((filter) => filter.includes(category));
  if (active.length) {
    activeFilters.value = activeFilters.value.filter((filter) => !filter.includes(category));
  } else if (activeFilters.value.some((filter) => filter === category)) {
    activeFilters.value.splice(activeFilters.value.indexOf(category), 1);
  } else {
    activeFilters.value = [category];
  }
};

const toggleActiveTag = (filter: string) => {
  if (activeFilters.value.includes(filter)) {
    activeFilters.value.splice(activeFilters.value.indexOf(filter), 1);
  } else {
    activeFilters.value.push(filter);
  }
};

onBeforeMount(async () => {
  if (props.type === CustomProductType.Tray) {
    const { data } = await catalog();
    fullIngredientsCatalog.value = data.ingredients;
    catalogCategoryTree.value = data.categoryTree;
  } else if (props.type === CustomProductType.Box) {
    await loadBoxIngredients();
  }
});

const handleClose = () => emit('close-modal');
</script>

<template>
  <Modal
    data-test="ingredients-modal"
    backgroundColor="bg-neutral-100"
    :isOpen="isOpen"
    :hasControls="false"
    @handle-close="handleClose"
  >
    <template v-slot:header>
      <h3 class="text-left">Ingredient Choices for Your Recipient(s)</h3>
    </template>
    <template v-slot:body>
      <div class="flex flex-col h-screen lg:flex-row sm:max-w-6xl lg:w-screen">
        <template v-if="showBox">
          <div class="mt-8 md:flex-4 md:h-full lg:h-full">
            <div class="h-full md:w-72">
              <CustomizerCategoryFilterControlPanel
                :activeFilters="activeFilters"
                :catalogCategoryTree="categoryTree"
                class="overflow-scroll mb-15"
                @toggle-category="toggleActiveCategory"
              />
              <CustomizerTagFilterControlPanel
                :activeFilters="activeFilters"
                @toggle-filter="toggleActiveTag"
              />
              <div class="flex-xs-12 flex-md-10">
                <CustomizerKeywordFilter v-model="activeKeyword" />
              </div>
            </div>
          </div>

          <div class="flex-1 mt-6">
            <div
              class="grid grid-cols-2 gap-4 pb-10 md:mt-6 md:grid-cols-4"
              data-test="ingredient-catalog"
            >
              <div v-for="ingredient in activeIngredients" :key="ingredient.name">
                <div
                  class="flex flex-col items-center justify-between w-full h-full p-2 bg-white rounded-lg"
                >
                  <div class="flex items-center prop-3x2">
                    <img :src="ingredient.listingImageUrl" class="max-w-full" alt="product-image" />
                  </div>
                  <div class="mt-2 text-sm font-semibold whitespace-normal">
                    {{ ingredient.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="mt-8 md:flex-4 md:h-full lg:h-full">
            <div class="h-full md:w-72">
              <CategoryFilterControlPanel
                :catalogCategoryTree="catalogCategoryTree"
                class="mb-15"
              />
              <TagFilterControlPanel />
              <div class="flex-xs-12 flex-md-10">
                <KeywordFilter />
              </div>
            </div>
          </div>

          <div class="flex-1 mt-6">
            <div class="grid grid-cols-2 gap-4 pb-10 md:mt-6 md:grid-cols-4">
              <div
                v-for="ingredient in ingredients"
                v-show="ingredient.show"
                :key="ingredient.id"
                class="relative flex flex-col items-center w-full h-full p-2 bg-white rounded-lg"
                :data-test="ingredient.name"
              >
                <div
                  v-if="ingredient.premium"
                  class="absolute top-0 right-0 p-1 ml-auto text-xs font-bold rounded-tr-lg rounded-bl-lg bg-amber-300/50 custom-fit"
                >
                  Premium
                </div>
                <div class="flex items-center h-4/5">
                  <img :src="ingredient.assetPath" alt="" class="max-w-full" loading="lazy" />
                </div>
                <div class="mt-2 text-sm font-semibold whitespace-normal">
                  {{ ingredient.name }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
.custom-fit {
  width: fit-content;
}
</style>

import { cents, from, fromCents } from '@nuts/auto-delivery-sdk/dist/utils/money';

import { AlgoliaVariant } from '@/api/algolia';
import { SkuSelection, VolumetricSelection } from '@/lib/customizers/common';
import { Money } from '@/utils/money';

export interface BaseIngredient {
  readonly hierarchicalFacet: AlgoliaVariant['Product']['categoryNames'];
  readonly listingImageUrl: string;
  readonly name: string;
  readonly path: string;
  readonly popularity: number;
  readonly productKey: string;
  readonly tags: string[];
}

interface RelativeTiers {
  readonly level2: Money;
  readonly level3: Money;
}

export interface SkuIngredient extends BaseIngredient {
  readonly singlePiecePrice: Money;
  readonly sku: string;
}

export interface VolumetricIngredient extends BaseIngredient {
  readonly density: number;
  readonly priceRange: '$' | '$$' | '$$$';
  readonly volumePrice: Money;
}

export function deriveRelativeRange(
  variants: AlgoliaVariant[],
  priceMultiplier = 1,
): RelativeTiers {
  let volumePriceMin = from(1000);
  let volumePriceMax = from(0);
  variants.forEach((variant) => {
    const listPrice = variant.prices.find((p) => !p.channel)!;
    const volumePrice = Money.multiply(listPrice.value, priceMultiplier);
    volumePriceMin = Money.min(volumePriceMin, volumePrice);
    volumePriceMax = Money.max(volumePriceMax, volumePrice);
  });

  const priceDiff = Money.subtract(volumePriceMax, volumePriceMin);
  const tierAmount = Money.divide(priceDiff, 3);
  return {
    level2: Money.add(volumePriceMin, tierAmount),
    level3: Money.add(volumePriceMin, Money.multiply(tierAmount, 2)),
  };
}

export const SkuIngredient = {
  fromAlgoliaVariant: (variant: AlgoliaVariant): SkuIngredient => {
    if (!variant.Product.customizerCategoryNames)
      throw new Error('Customizer Category Names is required');

    const filteredTags = ['gluten-free', 'kosher', 'organic', 'raw', 'sugar-free', 'vegan'].filter(
      (tag) => variant.Product.tags.includes(tag),
    );
    if (['unsalted', '50% less salt'].some((tag) => variant.Product.tags.includes(tag))) {
      filteredTags.push('less salt');
    }

    const listPrice = variant.prices.find((p) => !p.channel)!;
    const singlePiecePrice = listPrice.discounted?.value ?? listPrice.value;

    return {
      hierarchicalFacet: variant.Product.customizerCategoryNames,
      listingImageUrl: variant.Product.listingImageUrl,
      name: variant.Product.name,
      path: variant.Product.path,
      popularity: variant.popularity,
      productKey: variant.Product.key,
      singlePiecePrice,
      sku: variant.sku,
      tags: filteredTags,
    };
  },
  toSelection: (ingredient: SkuIngredient): SkuSelection => ({
    imageUrl: ingredient.listingImageUrl,
    name: ingredient.name,
    productKey: ingredient.productKey,
    singlePiecePrice: ingredient.singlePiecePrice,
    sku: ingredient.sku,
  }),
};

export const VolumetricIngredient = {
  fromAlgoliaVariant: (
    variant: AlgoliaVariant,
    tiers: RelativeTiers,
    priceMultiplier = 1,
  ): VolumetricIngredient => {
    if (!variant.Product.density) throw new Error('Density is required');
    if (!variant.Product.customizerCategoryNames)
      throw new Error('Customizer Category Names is required');

    const filteredTags = ['gluten-free', 'kosher', 'organic', 'raw', 'sugar-free', 'vegan'].filter(
      (tag) => variant.Product.tags.includes(tag),
    );
    if (['unsalted', '50% less salt'].some((tag) => variant.Product.tags.includes(tag))) {
      filteredTags.push('less salt');
    }

    const listPrice = variant.prices.find((p) => !p.channel)!;
    const volumePrice = Money.multiply(listPrice.value, priceMultiplier);

    let priceRange: VolumetricIngredient['priceRange'] = '$';
    if (Money.equals(volumePrice, Money.max(tiers.level2, volumePrice))) priceRange = '$$';
    if (Money.equals(volumePrice, Money.max(tiers.level3, volumePrice))) priceRange = '$$$';

    return {
      density: variant.Product.density,
      hierarchicalFacet: variant.Product.customizerCategoryNames,
      listingImageUrl: variant.Product.listingImageUrl,
      name: variant.Product.name,
      path: variant.Product.path,
      popularity: variant.popularity,
      priceRange,
      productKey: variant.Product.key,
      tags: filteredTags,
      volumePrice,
    };
  },
  toSelection: (ingredient: VolumetricIngredient, parts: number): VolumetricSelection => ({
    density: ingredient.density,
    imageUrl: ingredient.listingImageUrl,
    name: ingredient.name,
    productKey: ingredient.productKey,
    parts,
    volumePrice: ingredient.volumePrice,
  }),
};
